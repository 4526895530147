
export function getNotionValueByKey(key, delegate) {
  const { properties } = delegate;
  if (!delegate || !properties) return "";

  switch(key) {
    case "picture":
      return (properties[key]?.files[0]?.file?.url || properties[key]?.files[0]?.external?.url) || null;
    case "eth_address":
      return properties[key]?.title[0]?.plain_text;
    case "Opt In Delegate":
      return properties[key]?.checkbox;
    default:
      return properties[key]?.rich_text[0]?.plain_text;
  }
}

/**
 * Truncates an ethereum address to the format 0x0000…0000
 * @param address Full address to truncate
 * @returns Truncated address
 */
export function truncateAddress(address) {
    const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
    const match = address.match(truncateRegex);
    if (!match) return address;
    return `${match[1]}…${match[2]}`;

  }
