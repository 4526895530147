import { useEffect, useState, Fragment } from "react";
import { useWeb3React } from "@web3-react/core";
import { css, StyleSheet } from "aphrodite";
import Modal from 'react-modal';
import { Colors } from "../../utils/colors.js";
import LightBlueRect from "../../assets/Light_Blue_Rect.png";
import WhiteBlock from "../../assets/white.png";

import HyperLink from "../HyperLink";
import { connectors, WALLET_OPTIONS } from "./connectors";

import { MODAL_STYLES } from "../../utils/constants";
import { toast } from 'react-toastify';
import { Wallet } from "ethers";

const WalletConnectModal = ({ isOpen, onClose }) => {
  const { activate } = useWeb3React();

	const handleConnect = (connectionKey) => {
		const connector = connectors[connectionKey]
    window.localStorage.setItem("provider", connectionKey);
		activate(connector);
		onClose();
	};

	const closeModal = () => {
		onClose();
	}

	return (
		<Modal
			isOpen={isOpen}
			// onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			style={MODAL_STYLES}
		>
			{/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
			<div className={css(styles.closeButton)} onClick={closeModal}>
				<i className={css(styles.closeIcon) + " far fa-times"} />
			</div>
			<div className={css(styles.content)}>
				<h3 className={css(styles.title)}>{"Connect Wallet"}</h3>
					{WALLET_OPTIONS.map(({icon, name, key}, i) => (
							<div className={css(styles.option)} key={`wallet-option-${key}`} onClick={() => handleConnect(key)}>
								<img src={icon} alt={`${name} icon`} className={css(styles.optionIcon)} />
								<p className={css(styles.optionLabel)}>{name}</p>
							</div>
						)
					)}
			</div>
		</Modal>
	)
};

const styles = StyleSheet.create({
	closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer"
  },
  closeIcon: {
    fontSize: 24,
    color: "#FAFAFA"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px 20px 0 20px",
    width: 250,
    "@media only screen and (max-width: 415px)": {
      width: "100%",
      height: "100vh"
    }
  },
  title: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontSize: 22,
    textAlign: "center",
    fontWeight: "bold",
    margin: 0,
	fontStyle: "italic",
	textTransform: "uppercase",
	marginBottom: 30
  },
	option: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: 40,
		border: `1px solid ${Colors.darkNavy()}`,
		background: Colors.lightNavy(),
		boxShadow: `0px 0px 10px ${Colors.darkNavy()}`,
		padding: "0 15px",
		marginBottom: 10,
		cursor: "pointer",
		color: Colors.white(),

		":hover": {
			borderColor: Colors.uiTeal(),
			opacity: 0.9
		}
	},
	optionLabel: {
		fontSize: 16,
		fontWeight: "bold",
		textTransform: "uppercase",
	},
	optionIcon: {
		height: 20,
		width: 20,
		marginRight: 15
	}
})

export default WalletConnectModal;