import { css, StyleSheet } from "aphrodite";

import TitleHeader from "../TitleHeader";
import ContentBlock from "../ContentBlock";
import MeebitContainer from "../MeebitContainer";
import Meebit from "../../assets/Meebit_3.webp";
import cyan from "../../assets/cyan.webp";
import ActionButton from "../ActionButton";

function MBBTAbout() {
  return (
    <div className={css(styles.section)} id="about-section">
      <div className={css(styles.aboutSection)}>
        <TitleHeader title={"about"} alignRight={true} />
        <ContentBlock
          content={
            <div className={css(styles.aboutContentContainer)}>
              <p>
                MBBT is an ERC-20 token that represents proxy ownership in an
                album of Meebits. Learn more about MBBT and our vision for&nbsp;
                <a
                  href="https://medium.com/meebitsdao/how-to-scale-nfts-for-the-metaverse-4cb8ca999462"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#fff" }}
                >
                  scaling NFTs for the Metaverse
                </a>
                .
              </p>
              <ActionButton
                text="View Album"
                href="https://szns.io/album/meebitsdaopool"
                style={styles.button}
              />
            </div>
          }
          blockStyle={styles.blockStyle}
        />
      </div>
      <div className={css(styles.meebContainer)}>
        <MeebitContainer meebit={Meebit} background={cyan} />
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  section: {
    display: "flex",
    position: "relative",
  },
  aboutSection: {
    marginBottom: 152,
    zIndex: 2,

    "@media only screen and (max-width: 767px)": {
      zIndex: 4,
    },

    "@media only screen and (max-width: 1023px)": {
      zIndex: 4,
    },
  },
  blockStyle: {
    marginLeft: 286,
    height: 300,
  },
  aboutContentContainer: {
    paddingTop: 30,

    "@media only screen and (max-width: 1023px)": {
      paddingTop: 0,
    },
  },
  button: {
    width: "100%",
    height: "3em",
    marginTop: 32,
  },
  meebContainer: {
    // position: "relative",
  },
});

export default MBBTAbout;
