import { css, StyleSheet } from "aphrodite";
import NavBarNackground from "../assets/Nav_Bar_Background.png";
import Discord from "../assets/discord-large.webp";
import Twitter from "../assets/twitter-large.webp";
import Opensea from "../assets/opensea-large.webp";
import Medium from "../assets/medium-large.webp";
import MBKicksLogo from "../assets/meekicks/logo-transparent.png";

import { slide as MobileMenu } from "@quantfive/react-burger-menu";

import WhiteBlock from "../assets/white.png";
import MeebitsLogoHome from "../assets/MeebitsDao_Logo_Transparent-v2.png";
import MeebitsLogoSubpage from "../assets/meebits-logo-subpage.webp";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ActionButton from "./ActionButton";
import ConnectButton from "./ConnectButton";
import NavbarUserCard from "./NavbarUserCard";
import { Colors } from "../utils/colors.js";
import LightBlueRect from "../assets/Light_Blue_Rect.png";
import DarkBlueRect from "../assets/Dark_Blue_Rect.png";


import {
  Menu,
  MenuItem,
  MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';


const burgerStyles = {
  bmBurgerBars: {
    background: "#373a47",
    width: 30,
    left: "unset",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "26px",
    width: "26px",
    color: "#FFF",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 3147480000,
    height: "unset",
  },
  bmMenu: {
    background: "rgba(55, 58, 70, 1)",
    fontSize: "1.15em",
    padding: "2.5em .6em 32px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "100%",
    overflow: "auto",
    borderTop: "1px solid rgba(255,255,255,.2)",
    paddingTop: 16,
  },
  bmItem: {
    display: "inline-block",
    margin: "15px 0 15px 0",
    color: "#FFF",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    left: 0,
  },
};

function Navbar(props) {
  const [scrolled, setScrolled] = useState(false);
  const [clicked, setClicked] = useState(false);
  // prop used to close menu on callback
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollToJoin, setScrollToJoin] = useState(false);

  const location = useLocation();
  const isHome = location.pathname === "/";

  useEffect(() => {
    const setMeebitsLogo = () => {
      setScrolled(window.pageYOffset > 10);
      if (window.pageYOffset <= 10) {
        setClicked(false);
      }
    };
    window.addEventListener("scroll", setMeebitsLogo, false);
    return () => {
      window.removeEventListener("scroll", setMeebitsLogo);
    };
  }, [clicked]);

  useEffect(() => {
    if (scrollToJoin) {
      scrollIntoView("join-section");
      setScrollToJoin(false);
    }
  }, [scrollToJoin])

  const scrollIntoView = (id) => {
    const el = document.getElementById(id);
    if (!el) return;
    const y = el.getBoundingClientRect().top + window.scrollY;
    try {
      window.scroll({
        top: y,
        behavior: "smooth",
      });
    } catch (e) {
      el.scrollIntoView({
        behavior: "smooth", // smooth scroll
      });
    }
    setIsMenuOpen(false);
  };

  const renderNav = (mobile) => {
    return (
      <div className={css(styles.innerNav, mobile && styles.innerNavMobile)}>
        <a
          href="https://snapshot.org/#/meebitsdao.eth"
          target="_blank"
          rel="noreferrer"
          className={css(styles.tab)}
        >
          Voting
        </a>
        <Link
          className={css(styles.tab)}
          to="/"
          onClick={() => {
            if (isHome) {
              scrollIntoView("join-section");
            } else {
              setScrollToJoin(true);
            }
          }}
        >
          Join
        </Link>
        <Menu 
          menuButton={(
            <MenuButton>
              Projects
              <i className="fas fa-chevron-down" style={{marginLeft: 8}}/>
            </MenuButton>
          )}
        >
          <MenuItem>
            <Link  
              className={css(styles.tab, styles.menuItem)}
              to="/pixel"
            >
              Pixel Meebits
            </Link>
          </MenuItem>
          <MenuItem>
            <Link  
              className={css(styles.tab, styles.menuItem)}
              to="/meekicks"
            >
              Meekicks
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              className={css(styles.tab, styles.menuItem)}
              to="/claim"
            >
              Claim mVOX
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              className={css(styles.tab, styles.menuItem)}
              to="/mbbt"
            >
              MBBT
            </Link>
          </MenuItem>
          <MenuItem>
            <a
              href="https://meebitsmerch.xyz"
              target="_blank"
              rel="noreferrer"
              className={css(styles.tab, styles.menuItem)}
            >
              Merch
            </a> 
          </MenuItem>
          <MenuItem>
            <a
              href="https://github.com/larvalabs/meebitsapi"
              target="_blank"
              rel="noreferrer"
              className={css(styles.tab, styles.menuItem)}
            >
              Meebits API
            </a> 
          </MenuItem>
        </Menu>
        <div className={css(styles.allSocials)}>
          <a
            href="https://discord.gg/zuJUaTjWBZ"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Discord} className={css(styles.socialIcon)} />
          </a>
          <a
            href="https://twitter.com/MeebitsDAO"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Twitter} className={css(styles.socialIcon)} />
          </a>
          <a
            href="https://medium.com/meebitsdao"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Medium} className={css(styles.socialIcon)} />
          </a>
          <a
            href="https://opensea.io/collection/mdao-members"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Opensea} className={css(styles.socialIcon)} />
          </a>
        </div>
        <ConnectButton
          walletConnected={props.walletConnected}
          walletAddress={props.walletAddress}
          setWallet={props.setWallet}
          userEns={props.userEns}
          setUserEns={props.setUserEns}
          onClick={props.connectWallet}
        />
      </div>
    );
  };

  const renderMobileNav = (mobile = true ) => {
    return (
      <div className={css(styles.innerNav, mobile && styles.innerNavMobile)}>
        <Link
          className={css(styles.tab)}
          to="/claim/delegates"
          onClick={() => setIsMenuOpen(false)}
        >
          Voting
        </Link>
        <Link
          className={css(styles.tab)}
          to="/claim"
          onClick={() => setIsMenuOpen(false)}
        >
          Claim mVOX
        </Link>
        <Link
          className={css(styles.tab)}
          to="/pixel"
          onClick={() => setIsMenuOpen(false)}
        >
          Pixel Meebits
        </Link>
        <Link  
          className={css(styles.tab, styles.menuItem)}
          to="/meekicks"
          onClick={() => setIsMenuOpen(false)}
        >
          Meekicks
        </Link>
        <div
          className={css(styles.tab)}
          to="/mbbt"
          onClick={() => setIsMenuOpen(false)}
        >
          MBBT
        </div>
        <a
          href="https://github.com/larvalabs/meebitsapi"
          target="_blank"
          rel="noreferrer"
          className={css(styles.tab)}
        >
          Meebits API
        </a>
        <span
          className={css(styles.tab)}
          onClick={() => {
            scrollIntoView("about-section");
            setIsMenuOpen(false);
          }}
        >
          About
        </span>
        <span
          className={css(styles.tab)}
          onClick={() => {
            scrollIntoView("join-section");
            setIsMenuOpen(false);
          }}
        >
          Join
        </span>
        <span
          className={css(styles.tab)}
          onClick={() => {
            scrollIntoView("advisors-section");
            setIsMenuOpen(false);
          }}
        >
          Advisors
        </span>
        <div className={css(styles.allSocials)}>
          <a
            href="https://discord.gg/zuJUaTjWBZ"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Discord} className={css(styles.socialIcon)} />
          </a>
          <a
            href="https://twitter.com/MeebitsDAO"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Twitter} className={css(styles.socialIcon)} />
          </a>
          <a
            href="https://medium.com/meebitsdao"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Medium} className={css(styles.socialIcon)} />
          </a>
          <a
            href="https://opensea.io/collection/mdao-members"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Opensea} className={css(styles.socialIcon)} />
          </a>
        </div>
        <ConnectButton
          walletConnected={props.walletConnected}
          walletAddress={props.walletAddress}
          setWallet={props.setWallet}
          userEns={props.userEns}
          setUserEns={props.setUserEns}
          onClick={props.connectWallet}
        />
      </div>
    );
  }

  const showAnimation = scrolled && !clicked;

  return (
    <div className={css(styles.nav)}>
      <Link
        className={css(
          styles.meebitsBlock,
          showAnimation && styles.scrolledMeebitsContainer,
          !isHome && styles.claimPageBlock
        )}
        onClick={() => {
          setClicked(true);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
        to="/"
      >
        <img
          src={WhiteBlock}
          className={css(
            styles.whiteBlock,
            showAnimation && styles.scrolledMeebitsBlock,
            showAnimation && styles.scrolledWhiteBlock,
            !isHome && styles.hidden
          )}
        />
        <img
          src={MeebitsLogoHome}
          className={css(
            styles.meebitsLogoHome,
            showAnimation && styles.scrolledMeebitsBlock,
            showAnimation && styles.scrolledMeebitsLogo,
            !isHome && styles.meebitsLogoSubpage,
          )}
        />
        {/* <Link to="/">
          <img
            src={MeebitsLogoSubpage}
            className={css(styles.meebitsLogoSubpage)}
          />
        </Link> */}
      </Link>

      <div className={css(styles.desktop)}>{renderNav()}</div>
      {/* {props.walletConnected && <NavbarUserCard address={props.walletAddress}/>} */}
      <div className={css(styles.mobile)}>
        <div className={css(styles.menu)}>
          <MobileMenu
            top
            styles={burgerStyles}
            customBurgerIcon={<i className="fas fa-bars fa-2x"></i>}
            isOpen={isMenuOpen}
            onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)}
          >
            {renderMobileNav(true)}
          </MobileMenu>
        </div>
      </div>
      {location.pathname == "/" ? (
        <Link
          className={css(styles.mintButton)}
          to={"/meekicks"}
        >
          Claim Meekicks
        </Link>
      ) : null}
    </div>
  );
}
const styles = StyleSheet.create({
  nav: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    paddingRight: 16,
    boxSizing: "border-box",
    background: `url(${NavBarNackground})`,
    height: 70,
    zIndex: 10,
    color: "#fff",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  innerNav: {
    display: "flex",
    alignItems: "center"
  },
  innerNavMobile: {
    flexDirection: "column",
  },
  desktop: {
    "@media only screen and (max-width: 1023px)": {
      display: "none",
    },
  },
  mobile: {
    "@media only screen and (min-width: 1024px)": {
      display: "none",
    },
  },
  menu: {
    width: 30,
  },
  tab: {
    fontSize: 20,
    // fontSize: 24,
    fontWeight: 800,
    fontStyle: "italic",
    marginRight: 20,
    textTransform: "uppercase",
    cursor: "pointer",
    color: "#fff",
    textDecoration: "none",
  },
  menuItem: {
    padding: "5px 3rem",
    marginRight: 0
  },
  socialIcon: {
    marginRight: 17,
    height: 25,
    objectFit: "contain",
  },
  allSocials: {
    "@media only screen and (max-width: 1023px)": {
      display: "flex",
      marginTop: 16,
      marginBottom: 16
    },
  },

  whiteBlock: {
    position: "absolute",
    top: -270,
    left: 0,
    transform: "rotate(27deg)",
    opacity: 0.95,
    mixBlendMode: "normal",
    zIndex: 5,
    transition: ".5s ease-in-out",

    "@media only screen and (max-width: 1439px)": {
      width: 150,
      top: -50,
      left: -20,
    },
  },
  meebitsLogoHome: {
    position: "absolute",
    top: -20,
    left: 100,
    zIndex: 5,
    transition: ".5s ease-in-out",

    "@media only screen and (max-width: 1439px)": {
      width: 120,
      top: 0,
      left: 0,
    },
  },
  meebitsLogoSubpage: {
    height: 60,
    width: 90,
    top: 0,
    left: 0,
    "@media only screen and (max-width: 10439px)": {
      height: 60,
      width: 90,
      top: 0,
      left: 0,
    },
  },
  meekicksLogo: { 
    paddingTop: 5,
    height: 55,
    width: "unset",
    "@media only screen and (max-width: 10439px)": {
      paddingTop: 5,
      height: 55,
      width: "unset",
      top: 0,
      left: 0,
    },
  },
  meebitsBlock: {
    position: "fixed",
    top: 0,
    left: 0,
    cursor: "pointer",

    "@media only screen and (max-width: 767px)": {
      position: "absolute",
    },
  },
  claimPageBlock: {
    position: "unset",
    // left: 0,
  },
  scrolledMeebitsContainer: {
    // transform: "translateX(-100px)",
    opacity: 1,
  },
  scrolledMeebitsBlock: {
    "@media only screen and (min-width: 1024px)": {
      transform: "scale(.3)",
      opacity: 1,
    },
  },
  scrolledWhiteBlock: {
    "@media only screen and (min-width: 1024px)": {
      transform:
        "rotate(-27deg) scale(.4) translateX(-500px) translateY(-200px)",
    },
  },
  scrolledMeebitsLogo: {
    "@media only screen and (min-width: 1024px)": {
      transform: "rotate(5deg) scale(.4) translateX(-500px) translateY(50px)",
      top: -90,
      left: 80,
    },
  },
  minter: {
  
  },
  mintButton: {
    position: "absolute",
    right: 0,
    top: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 52,
    padding: "11px 48px",
    background: `url(${LightBlueRect})`,
    fontStyle: "italic",
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "29px",
    border: 0,
    cursor: "pointer",
    color: "#fff",
    textTransform: "uppercase",
    textDecoration: "none",
    boxSizing: "border-box",
    boxShadow: `0px 3px 5px ${Colors.black(0.5)}`,
    width: "unset",
    color: Colors.white(),
    ":hover": {
      opacity: 0.9,
    }
  },
  hidden: {
    display: "none",
  },
});

export default Navbar;
