import { css, StyleSheet } from "aphrodite";
import ReactTooltip from "react-tooltip";

import activeBackground from "../../assets/Light_Blue_Rect.png";
import { Colors } from "../../utils/colors";
import { getNotionValueByKey } from "../../utils/helpers";

export default function Delegate({
  delegate,
  coinsDelegated,
  active,
  className,
  hideTooltip,
}) {
  if (!delegate) return null;

  const name = getNotionValueByKey("name", delegate);
  const id = delegate.id;
  const img = getNotionValueByKey("picture", delegate);
  const bio = getNotionValueByKey("interest", delegate);

  return (
    <div className={css(styles.delegate, active && styles.active, className)}>
      {active && (
        <i
          style={{
            position: "absolute",
            top: -4,
            right: -6,
            color: "#00F790",
            zIndex: 3,
            fontSize: 18,
            borderRadius: 100,
            background: Colors.darkNavy(),
          }}
          className="fas fa-check-circle"
        ></i>
      )}
      <div className={css(styles.photo)}>
        <img src={img} className={css(styles.img)} />
      </div>
      <div className={css(styles.info)}>
        <div
          className={css(styles.name)}
          data-tip={name}
          data-for={`name-${id}`}
        >
          {name}
        </div>
        <div className={css(styles.coinsDelegated)}>{coinsDelegated}</div>
      </div>
      {!hideTooltip && <ReactTooltip id={`name-${id}`} />}

      {!hideTooltip && (
        <i
          data-for={`bio-${id}`}
          style={{
            color: active ? "#fff" : "rgba(0, 152, 248, 1)",
            marginLeft: "auto",
            cursor: "pointer",
            fontSize: 14,
          }}
          data-tip
          data-iscapture="true"
          className="fad fa-info-circle"
        />
      )}

      <ReactTooltip
        id={hideTooltip ? `name-${id}` : `bio-${id}`}
        getContent={() => {
          return (
            <div className={css(styles.tooltip)}>
              <div className={css(styles.topSection)}>
                <div className={css(styles.photo)}>
                  <img src={img} className={css(styles.img)} />
                </div>
                <div className={css(styles.info)}>
                  <div
                    className={css(styles.name)}
                    data-tip={name}
                    data-for={`name-${id}`}
                  >
                    {name}
                  </div>
                  <div className={css(styles.coinsDelegated)}>
                    {coinsDelegated}
                  </div>
                </div>
              </div>
              <div className={css(styles.bio)}>{bio}</div>
            </div>
          );
        }}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  delegate: {
    display: "flex",
    alignItems: "center",
    background: "#00386D",
    padding: 16,
    position: "relative",
    cursor: "pointer",
  },
  active: {
    background: `url(${activeBackground})`,
  },
  img: {
    width: 40,
    height: 40,
    objectFit: "cover",
  },
  photo: {
    marginRight: 16,
  },
  topSection: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    textAlign: "left",
    fontWeight: 800,
    fontSize: 14,
    fontStyle: "italic",
    maxWidth: 120,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tooltip: {
    maxWidth: 326,
    padding: 16,
  },
  bio: {
    marginTop: 16,
    textAlign: "left",
  },
});
