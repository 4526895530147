import { css, StyleSheet } from "aphrodite";
import { useEffect, useRef, useState } from "react";
import { Colors } from "../utils/colors";


const Box = ({ text, style, children }) => {
  return (
    <div className={css(styles.box, style && style)}>
      <div className={css(styles.boxTitle)}>{text}</div>
      {children}
    </div>
  );
};

const styles = StyleSheet.create({
  box: {
    background: "#00386D",
    padding: 16,
    maxWidth: 306,
    margin: "15px auto",
    border: `1px solid ${Colors.darkNavy()}`
  },
  boxTitle: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontWeight: 600,
    fontSize: 14
  },
})

export default Box;