import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

const injected = new InjectedConnector({
  supportedChainIds: [1, 4, 137, 80001]
});

const walletconnect = new WalletConnectConnector({
  rpcUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true
});

const walletlink = new WalletLinkConnector({
	url: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
	appName: "meekicks"
  });

export const connectors = {
  injected: injected,
  walletConnect: walletconnect,
  coinbaseWallet: walletlink
};


export const WALLET_OPTIONS = [
	{
		key: "injected",
		name: "MetaMask",
		icon: "/mm.png",
	},
	{
		key: "walletConnect",
		name: "WalletConnect",
		icon: "/wc.png",
	},
	{
		key: "coinbaseWallet",
		name: "Coinbase Wallet",
		icon: "/cbw.png",
	}
]

