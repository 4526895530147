import { css, StyleSheet } from "aphrodite";
import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import "react-toastify/dist/ReactToastify.css";

import Navbar from "./components/Navbar";
import HomePage from "./routes/homePage";
import ClaimPage from "./routes/claimPage";
import MBBTMintPage from "./routes/mbbtMintPage";
import MBKicksPage from "./routes/kicksClaimPage";
import PixelMBPage from "./routes/pixelClaimPage";
import TermsOfService from "./components/Tos/TermsOfService";

import { Routes, Route } from "react-router-dom";
import { connectors } from "./components/Web3/connectors";

import "./utils/interact";
import { Colors } from "./utils/colors.js";

import {
  getCurrentWalletConnected,
  getEnsFromAddress,
  connectWallet,
} from "./utils/interact";
import ClaimPageShell from "./routes/ClaimPageShell";
import ChooseDelegateContainer from "./components/Claim/ChooseDelegateContainer";
import Founders from "./components/Founders/Founders";

function App() {
  const { chainId, account, activate, deactivate, active } = useWeb3React();

  console.log("version 2");

  const [walletConnected, setWalletConnected] = useState(false);
  const [walletAddress, setWallet] = useState("");
  const [walletStatus, setWalletStatus] = useState("");
  const [userEns, setUserEns] = useState("");

  const [mailingNotificationOpen, setMailingNotificationOpen] = useState(false);
  const [metaMaskAddress, setMetaMaskAddress] = useState("");

  const [networkId, setNetworkId] = useState(1);
  const [networkErrorMessage, setNetworkErrorMessage] = useState("");

  const [disabled, setDisabled] = useState(false);

  const openMailingNotification = () => {
    setMailingNotificationOpen(true);
  };

  useEffect(() => {
    connectWithPrevWallet();
  }, []);

  useEffect(() => {
    if (!active && !account) {
      disconnectWallet();
    }

    setWallet(account);
    setWalletConnected(true);
    setNetworkId(chainId);
    _fetchWalletENS(account);
  }, [account, chainId]);

  async function connectWithPrevWallet() {
    // this is needed to bypass `Suppressed stale connector activation` warning
    await window.ethereum.request({ method: "eth_requestAccounts" });
    // this automatically tries to connect with user's previous wallet on refresh
    const provider = window.localStorage.getItem("provider");
    if (provider) {
      activate(connectors[provider]);
    }
  }

  const disconnectWallet = () => {
    refreshState();
    deactivate();
  };

  const refreshState = () => {
    setWalletConnected(false);
    setWallet("");
    setWalletStatus("");
    setUserEns("");
    setMetaMaskAddress("");
    setNetworkId(1);
    setNetworkErrorMessage("");
    setDisabled(false);
  };

  async function _fetchWalletENS(address) {
    if (!address) return;
    const ens = await getEnsFromAddress(address);
    setUserEns(ens);
  }

  async function _connectWallet() {
    const { address } = await connectWallet();
    if (address === walletAddress) return;
    setWallet(address);
    _fetchWalletENS(address);
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className={css(styles.app)}>
            <Navbar
              walletConnected={walletConnected}
              walletAddress={walletAddress}
              connectWallet={_connectWallet}
              userEns={userEns}
            />
            <HomePage
              walletConnected={walletConnected}
              setWalletConnected={setWalletConnected}
              walletAddress={walletAddress}
              setWallet={setWallet}
              walletStatus={walletStatus}
              openMailingNotification={openMailingNotification}
              selectedAddress={metaMaskAddress}
              networkId={networkId}
              setNetworkId={setNetworkId}
              networkErrorMessage={networkErrorMessage}
              setNetworkErrorMessage={setNetworkErrorMessage}
              disabled={disabled}
              setDisabled={setDisabled}
            />
          </div>
        }
      />
      <Route
        path="claim"
        element={
          <div className={css(styles.app)}>
            <Navbar
              walletConnected={walletConnected}
              walletAddress={walletAddress}
              connectWallet={_connectWallet}
              userEns={userEns}
            />
            <ClaimPage
              walletStatus={walletStatus}
              setWalletStatus={setWalletStatus}
              networkErrorMessage={networkErrorMessage}
              walletConnected={walletConnected}
              setWalletConnected={setWalletConnected}
              walletAddress={walletAddress}
              setWallet={setWallet}
            />
          </div>
        }
      />

      <Route
        path="claim/delegates"
        element={
          <div className={css(styles.app)}>
            <Navbar
              walletConnected={walletConnected}
              walletAddress={walletAddress}
              connectWallet={_connectWallet}
              userEns={userEns}
            />
            <ClaimPageShell className={styles.claimDelegates}>
              <ChooseDelegateContainer walletAddress={walletAddress} />
            </ClaimPageShell>
          </div>
        }
      />

      <Route
        path="mbbt"
        element={
          <div className={css(styles.app)}>
            <Navbar
              walletConnected={walletConnected}
              walletAddress={walletAddress}
              connectWallet={_connectWallet}
              userEns={userEns}
            />
            <MBBTMintPage
              walletConnected={walletConnected}
              setWalletConnected={setWalletConnected}
              walletAddress={walletAddress}
              setWallet={setWallet}
              walletStatus={walletStatus}
              openMailingNotification={openMailingNotification}
              selectedAddress={metaMaskAddress}
              networkId={networkId}
              setNetworkId={setNetworkId}
              networkErrorMessage={networkErrorMessage}
              setNetworkErrorMessage={setNetworkErrorMessage}
              disabled={disabled}
              setDisabled={setDisabled}
            />
          </div>
        }
      />

      <Route
        path="founders"
        element={
          <div className={css(styles.app)}>
            <Navbar
              walletConnected={walletConnected}
              walletAddress={walletAddress}
              connectWallet={_connectWallet}
              userEns={userEns}
            />
            <ClaimPageShell
              className={[styles.claimDelegates, styles.founders]}
            >
              <Founders
                walletAddress={walletAddress}
                walletStatus={walletStatus}
                setWalletStatus={setWalletStatus}
                networkErrorMessage={networkErrorMessage}
                walletConnected={walletConnected}
                setWalletConnected={setWalletConnected}
                setWallet={setWallet}
              />
            </ClaimPageShell>
          </div>
        }
      />
      <Route
        path="meekicks"
        element={
          <div className={css(styles.app)}>
            <Navbar
              walletConnected={walletConnected}
              setWallet={setWallet}
              walletAddress={walletAddress}
              userEns={userEns}
              setUserEns={setUserEns}
            />
            <MBKicksPage
              walletConnected={walletConnected}
              walletAddress={walletAddress}
              setWallet={setWallet}
              userEns={userEns}
              setUserEns={setUserEns}
            />
          </div>
        }
      />
      <Route
        path="meekicks/tos"
        element={
          <div className={css(styles.app)}>
            <Navbar
              walletConnected={walletConnected}
              setWallet={setWallet}
              walletAddress={walletAddress}
              userEns={userEns}
              setUserEns={setUserEns}
            />
            <TermsOfService />
          </div>
        }
      />
      <Route
        path="pixel"
        element={
          <div className={css(styles.app)}>
            <Navbar
              walletConnected={walletConnected}
              setWallet={setWallet}
              walletAddress={walletAddress}
              userEns={userEns}
              setUserEns={setUserEns}
            />
            <PixelMBPage
              walletConnected={walletConnected}
              walletAddress={walletAddress}
              setWallet={setWallet}
              userEns={userEns}
              setUserEns={setUserEns}
            />
          </div>
        }
      />
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  );
}
const styles = StyleSheet.create({
  app: {
    fontFamily: "Montserrat",
    background: "rgba(0, 56, 109, 1)",
    minHeight: "100vh",
    // minHeight: "calc(100vh - 63px - 70px)",
    // paddingBottom: 63,
    paddingTop: 70,
    color: "#fff",
    overflow: "hidden",
  },
  inner: {
    position: "relative",

    "@media only screen and (min-width: 1024px)": {
      background: "rgba(0, 56, 109, 1)",
      top: -350,
    },
  },
  innerInner: {
    maxWidth: 1720,
    margin: "0 auto",
    paddingTop: 24,
    "@media only screen and (min-width: 1024px)": {
      paddingTop: 50,
    },
  },
  width: {
    width: "50em",
    margin: "0 auto",
    marginTop: 88,

    "@media only screen and (max-width: 767px)": {
      width: "90%",
    },
  },
  claimDelegates: {
    width: "unset",
    maxWidth: 848,
    border: `1px solid ${Colors.darkNavy()}`,
  },
  founders: {
    maxHeight: "unset",
  },
});

export default App;
