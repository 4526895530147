require("dotenv").config();
const mbbtGeneralMembershipContractABI =
  require("../contracts/MeebitsDAOMBBTGeneralMembership.json").abi;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");

const alchemyKey = process.env.REACT_APP_ALCHEMY_URL;
const mbbtGeneralMembershipContractAddress =
  process.env.REACT_APP_MEEBITS_DAO_MBBT_GENERAL_MEMBERSHIP_CONTRACT_ADDRESS;
const web3 = createAlchemyWeb3(alchemyKey);

window.web3 = web3;
window.mbbtGeneralMembershipContract = new web3.eth.Contract(
  mbbtGeneralMembershipContractABI,
  mbbtGeneralMembershipContractAddress
);

/**
 * (MBBT) General Membership Token Functions
 */

export const mbbtCheckGeneralMembershipTokenCount = async (address) => {
  window.mbbtGeneralMembershipContract = await new web3.eth.Contract(
    mbbtGeneralMembershipContractABI,
    mbbtGeneralMembershipContractAddress
  );
  const balance = await window.mbbtGeneralMembershipContract.methods
    .balanceOf(address)
    .call();
  console.log("General Membership Balance: ", balance);
  return balance;
};

export const mbbtCreateMerkleTree = async (address) => {
  //Use merkle tree elements used to create smart contract merkle tree root

  const elements = [
    "0x8D75bEa1071b0DA90c0351cc36da2508a0c1A2d0",
    "0xB63AE4A1c6dDb1Fd50f21cDaBE4116b703715041",
    "0x733ba49C9dAa0e699A9Dd358C4a2F5af15633Cb0",
    "0xCaF07631229bdF0751D90fB55F6Bd9339dDb327c",
    "0xC7d9218281F263aAd719F21f20c1c0E3C434Ab95",
    "0x3a6397306604fCE95A7AA9bB8DF766E5574902cb",
    "0xC5e38233Cc0D7CFf9340e6139367aBA498EC9b18",
    "0xDFe8beeE223412F316baf2968B17527D6EbA29F1",
    "0x1D530d98105E6cf69e7A523fb307E3e450346D94",
    "0xa796c2b8b765251bB4c5C6a304A28B71C6A927d9",
    "0x521956486795001545d66058A79278a7A957f9Ee",
    "0x4FAFA767C9CB71394875c139D43AeE7799748908",
    "0x88F09Bdc8e99272588242a808052eb32702f88D0",
    "0x0C9F1e362b2b3C5Cf1fCdb239C13eAd7E803981B",
    "0xDD512AEbbbBa2e2bf1f239C68a5521fD462451Ec",
    "0xC948a1A21ed94C8d9C01608E1F1b56BE0D033609",
    "0x9ceC3E05496bfB40CEe6D4eab1D8A5F2b9227e61",
    "0x3C7676976600C90c8d106786d84bda4369f872a8",
    "0xA3E826a5D1631bAA4cf77d02ed829d2c6FcBc9E9",
    "0xD8e3165A460D7795a18393aCEF5613b042193B01",
    "0xEA533952C876d2CE7f80602E0fBf7C1Cbf20119f",
    "0x58f00d657387785F08365440897863f6cA8A3A54",
    "0xFA45D06f58A59F2775796ea6098E780ae87640f1",
    "0x1Bda89908Db0C7d9F9ca54429F6F0823d8949005",
    "0xA6a19dA231f88D44698769Ff4d1082A017E79aCc",
    "0x4eAC028FD4Fe262419Bc30B1Ca750F430d737A74",
    "0x6Eee796F80Dc191DcFB69C1Db8B5c8D37E06Ac1D",
    "0x57dcd38a94B7D074d2Ff031cdFa95b8Cff539c5E",
    "0x52BcD7A7720d044C72DF75b910AA46941a49AF46",
    "0x48Afb5e5529E150650cf6c8104cd0a9E1d0c9cF8",
    "0xc7e8F9537e515dF4b7C1FD6ac3283Fa642CDeC51",
    "0x5D025814b6a21Cd6fcb4112F40f88bC823e6A9ab",
    "0x2ede0ccEBFb3bf307DafC57Ff700183391620a69",
    "0x122A0597f3b93d339A04C3a9fa95C506c94874D7",
    "0xD117BFb67687ED9F0dA0A8490C86151fAd34bF8D",
    "0x300da191248a500b2174aeD992d6697BF97F9139",
    "0xCf2b7c6Bc98bfE0D6138A25a3b6162B51F75e05d",
    "0x92d07dA081da3603fCE208A29f40C22b8cc6A840",
    "0xA71C24E271394989D61Ac13749683d926A6AB81d",
    "0xb6a78170ADF6E57dEDF29313230d86dccf68D62A",
    "0x6ABaDBD5320bf25B349242EBfC7fa293601EA47F",
    "0xEae89E8cED8eE71b064c3601E19E253605b988fE",
    "0xB34a4BBc546F3218008BA78DEc5CD9773E237da6",
    "0xeB1534b4De07C718A00a89A2dfEdAe06fab6267d",
    "0xD51b738aB674fF8b475C8e7C6CDDA1cF9DCDa5C2",
    "0xfc41b2E5D1827bbb2e05Aec0418ec2A13d2ab8b1",
    "0x3175125AEDfC43Ec8930541f6f0550596b82A5b2",
    "0x9339acFa818DA6a0b2a196eF31D67acde156ACB0",
    "0xF44757FaE28E0BE18c2E7820a745aDd3E03A2E60",
    "0x6f45e1A536c5d8bE925c27e578eb2A430666fFE5",
    "0x2bb72eF03A2C4E75e33b279724239be7a0d69887",
    "0x8E8b9b2942E2cFF39C7A8BE7E129560dD695c8Ea",
    "0xD98B82b293ac589219ed9074B228C20E63c481f6",
    "0x958410d75bF7543ef6e4dd6134482BE368A5712B",
    "0x5f70e9a235A88Af615350Fe3b398590a79BCef80",
    "0xaf906155a4E624C7d39f8f15271A5f8b01B3915E",
    "0xe3D2283e983D9Ec973d1e773358981a7e9c62409",
    "0xeE2BB8598725445B532BDb14F522A99E04e84B38",
    "0x33899bF6c0F54607a9f8CeAd3D8c80443a7a1f59",
    "0x457A8E472661fB5624Cf7f84e8dB1acf7C37D49E",
    "0xBe8684fDB44769469411cAAC5EB6D93F6C1869E4",
    "0xEB976C34A7Ad738D923eD33E4376580f6A593456",
    "0x4537E8A5d4b98AF77B00B89d29A7BdfBF0f26Db3",
    "0x5e5CC3B4F71B257aF7dba2209a51802919bd8807",
    "0xaE87d7E94bAa3f416763f3100a748F9C186D3b44",
    "0x2215A3230187213Dc98ac006F2a7fD68D2045921",
    "0xd8E93E06a84581c10DC1F9F60ec91f0F38994f1b",
    "0x0C89176C253a78CceC62AD8D0a037b9448fbA8c5",
    "0x6cecA7911c1A4dd84451716B698995324609aD48",
    "0x82b332fdd56d480a33B4Da58D83d5E0E432f1032",
    "0x3f286C1796FD88A59Fae248dA7Db7AFe7489bA78",
    "0xbc8da288c106D83588751E30B45493a9AC775161",
    "0xA0A35Ea065F1888813E7Cf71aEcD1c1D4B6f4D6e",
    "0x591b0af7a233c5c349aF9930532d25a72F27B59E",
    "0x85B1917a7122594B35F167e1014F178f39b3b177",
    "0x016B79DAC725284128F913c4a1B3064d280241FE",
    "0xa99634c98c262fd6b51CB8662A9b4fd8774cA424",
    "0x846b38bCA519a7cb24e06eDcA34FC73726C5E252",
    "0x678d6f95001B78A8FFeFfaBDC3f6ccC49475F8Da",
    "0xad902132C2C1526e482BaB58fD5A1Dd1f07Ef891",
    "0xbED1408C224378E8F75DBfEfcb28E7AD1c419417",
    "0x06CAd31f35fe9dA950e9bC9EeF35023742D75286",
    "0x41621b9F75d37166d5efD94F33750Cf464A7a7eE",
    "0x0f18fc88312cd9B0049668638b84F7d7a48baB65",
    "0x64135DBd1685e0b08a504902368e28329a0D8D32",
    "0xDB42868a9a5fbB9932beDe5E9e0b677BCD7Ce890",
    "0x200241c7796B3b9dDe312D330e847807D087ec4E",
    "0x000e840A3122EC28d0c29321dB172375cB049762",
    "0xE9Bc2f39BdA9535660895195C5618ef251feC06E",
    "0xA36b24a4C772585005e8e7beF51a868B72c433e7",
    "0xCf532c287516d7683E52f284414Bd29b3bE3C557",
    "0x164382B3072812c3f58503c979261FE1fD6c5B9d",
    "0x6d3E7f6e0F846106F588661d095BBF654d8370Af",
    "0x26D04699E336c04ED8d95c9483a1C73c60C8169E",
    "0x3453b127b9EFe8A7e2666B3E3EEdA1BeF974ec3E",
    "0x577411F275Afa11C2057C11C59530cF96461fD9C",
    "0xDC7C0fA6755BD03AcBDb5ec0E4BDa9b5BC1dF22A",
    "0xD5a6E78E3c295ae32889C894e8943754dabe7fd7",
    "0xdfCe6F0abC4c05094AcdC5c522711e8D1AA18BB7",
    "0xB82D82deabD1dF1D7dD29F815F751e8D1Df7Dc60",
    "0xdF0Bb4F301A7D0F3701Ec94A168B1a33d68e21d1",
    "0xd92f81dEc7367C41D2c33f6a1723D79Dc2877dcE",
    "0x1dB302709a34DE2828CB7FA3973D4eB249E0ff52",
    "0xAEAD2B30D5be97a7128e087F544cC28AbaC2aB2e",
    "0xb6441Da15ae2806cd1F78edd4902A1880b238bfd",
    "0xA1301Bc3c9603deE6Ec6E9a730A28B208B3E7Ff1",
    "0x8527F1B7469DB029dC852411ddcA97ACcF370492",
    "0x31e8eA39a55a57f1AE78eA25A8eb61d2c3E05F38",
    "0x20c7249a13C280619BB43AC8b0DD9813a6788601",
    "0x3c82e37c5ce8a1CfdF7685fA4b9677b697723ABb",
    "0xE03E39A686FF4260b9e9976d0a6ADDbeEff08f6a",
    "0x5b38D9325d0070fd5E2c5d7d524C4e097df4EA10",
    "0x8323ea41885680bC2dEe1B879DFF60f75B1a90A3",
    "0x7B169bB05DA941c31E23DBbC07E89076a427CC1F",
    "0xc5Dc5284f0c00F29678d330C99275Ba46c56649c",
    "0x7765A840b2a90464d4F4E85Ba014Ffe170B135Be",
    "0x41b484Fe824AB915fAc226784E347aeB56a90D8a",
    "0x38BC768379D2Ed0C72e41cc973e6DE31bd2EAdA6",
    "0x717898a496f5B644F3f051d04B2CDDc72F82288f",
    "0x4Adaf96274d80E3048D68a183450a521544d18d1",
    "0x42880F25c9BD38F409fbAb1229EadAa9C216234F",
    "0x6c3b638982A93429eE99D2FF30f734F4b14Ef173",
    "0x41d41529E2Bc8E1Fbb270E469421026eC92987F7",
    "0x62440a5E585739409Abaa830F2C196ec2D281439",
    "0x84c7bBa6F4aFe6Ee3ab721c08d4f15c8dc7b0813",
    "0xC393A1DA7e180c3A46179Df984842063C01c34B6",
    "0x72b5A3B2703249916bE721F45527B49Cd2c3e650",
    "0xb21D0a4E081dA4e0a318b91291dE4d4E8041edc7",
    "0x023F4E39654A8ceC3D94eD9c85a080b9e9F70AFE",
    "0xb8C292B3c2819D46123e52F547662d84F07F3c8d",
    "0x2Bd57F4d3eE6379b2BdA6262b44D56b8343EfF09",
    "0xb295c0F2f83959a67C4F3c9cf10434F6A83BB0BB",
    "0x2B945952a7Fd0dCEA73299ee13911DC773577757",
    "0x11d153863e0655D3DDEe569a23fCc3a15e84a77A",
    "0xa8D405E3531Af13D44DB0830e7058c783b486344",
    "0x017a04631caF0Bacec325f68caAd2838a80Dd91D",
    "0xBb599f8A4914dC9976bADfc70A9dc8863ca9B462",
    "0x09c41efc1C7E2C2A11Bb153eF39AFFeaFf2CeDcD",
    "0x692248cC7a4d521E6237f50B56e699E159a963eB",
    "0xc6Ca2adC2736AbF4008d55BF8bbA7b08284c09Fa",
    "0xa90eE75e65B9D6B5FB7D8Ae48A5e2bbb97FEbcE9",
    "0xB6916a103AC864A0DFeb0e024d17f95832CFf4C3",
    "0x475ef7c1493bb29642529c30Ad9bd4D1f27AeD98",
    "0x3b0D26F96c9492dB58aF5AC0528DE5cE1f65772E",
    "0xA25606dA9CF1Ff827ac4c6289891CfFbb9F03720",
    "0xaE149e2a083d94B9833102cF4fd6BEFF5409Fb20",
    "0x57C56fa95e582FeCc9C312eA3f41D4652424616A",
    "0x08a86317586C80491258B9Bd66e92103931BF749",
    "0xF27946f9507f9C777c13A254a7389D306660088A",
    "0x2c6d2CFf266fBEDa10eD47B07b290548F973372a",
    "0x9883325f4ae85445585B420B2c9DB7A826c4CBC1",
    "0x012345e6Fa44EED2Df2443C739e65165861E0842",
    "0x71B095f10633f7aC65Cac6B476A3F0632339c5E4",
    "0x2C8A64Ada1bB3F34E67CD7472DDB90Ae0236651D",
    "0xD8c500a1F7dfC16F87FAB716C1385B517DE8fF4b",
    "0x3c1B27Ce449aE198ddE0AEd36Bb55a917D25F123",
    "0x3DeF0038D0493b2Ba32B45193e99673c901cc3BF",
    "0x5b0F153517E7035453402e0EDC4147aBb184A404",
    "0x2df0b6B5A705806dD6296d5372F5947a4aBD5f86",
    "0x8a00E8358Ae8eBFE1CEc2F7dCaA1B4EEF774B10B",
    "0xf7742AD0215CA5b95793117A471fd037E938CB68",
    "0xf7597666015Cb1Ab4e66C632C51612FE29309e65",
    "0xDBb302a940C23C3bD79228192fCB715F9ED1A1Da",
    "0x9018D0691fc21db6fFDfB3052EB558cceCFAFB34",
    "0xbF531057864C465871f1131DBEC290f5995b2A95",
    "0xb3DE2a0663EcCA4Cee5FcaC8929bf1580f861C07",
    "0x8bd9B892bA0acBf34Ca8d55F55D2A7804feC48e1",
    "0x6b1D635d090FCb2A27548b2AE22C352137002898",
    "0x102B9614c4309a3627d6c8E8c6272E9f74dD8923",
    "0x85EbC7d2098428Dc4C4b3C71b28ff0e921fEce37",
    "0xA158FFb97Cc5b65c7c762B31D3E8111688ee6940",
    "0xA4F5f51bD119Df4BC450D9d9b9A45B56EC9e2087",
    "0xBe1f9f3B935EEC3FbcCB4B97D5BF1FE87BDb98DF",
    "0x996290C17C874d989D6E3DB7dC0b2ef0A9FB876D",
    "0xad09Ccf1c5097A46D321FEB3FbA2e30fBAED07C3",
    "0x6dFE6B4dD11c0121e139e06e9f6d7a027264C9A3",
    "0x529408a6962Bae6Ca5328874d633738116B89B05",
    "0xC32c8487e58256fd8C7b80a4F6Eb0C5421c5b596",
    "0x65dfEad90D5203790368888cD95e05969AF198Fd",
    "0x13257b751A6c86D219c36949e50E2E499f57e56E",
    "0xc95F3C348E7b5e10F46117558Aab2Ba15a21991c",
    "0x04960BFdb30b34200658Bc2B71DF30C18f3ae69f",
    "0x87Bb1f1723ac6c6B341a5Df432f5a852d49ad12D",
    "0xc2172a3fE4e6AE13ef7409BDe3a8720847DAE413",
    "0x9b3c4FFF107A43E4d33BDF4B73D2136e24AC9786",
    "0xD7a9970752B82014a56c28bCF443ba19c09d4710",
    "0x89f1A6e530ea5032e260C8d5f1acA494D2d43187",
    "0x39a7aA6fBdfd8ff911e8f6523cdE9358c0791EFe",
    "0x199b6c03Cff208014686748A59FF28361aC30730",
    "0x5E15c0d5583Ac701464e5cb34f19bBf3944C0fAD",
    "0x49814F618DA4FD904ee4e47c3Fc1142463FA85d1",
    "0x7269a710463168F79d04b0778Ac8a0150523998F",
    "0xDff1385c83a3D4357ABC9FE6FDb463829A812C49",
    "0x16f7ef5beA0D2599D77Dee4a3384E817198429f7",
    "0x3E43342cFF9C7e0477809b7ae771697E9e0F7188",
    "0xf485c5Dd15c0118Ce6C535696F7Dd1ec2Bf6c97A",
    "0x50d6319Cc8688a4C93E38dD0831965734678ADb8",
    "0x6695F50EADA6069c8494D65654e52f4C8E43b0bf",
    "0xb2aC004c0AECf4e205F96fDEaeE799E5A8bE4273",
    "0xd88F110293097bcDA7157a4762611fA577e04319",
    "0xe0A569361344f7e83173f074A515228e1214597e",
    "0xE9D412869c361c99797083505A298fcC0c6fE4cb",
    "0x20D14A141176CB2DB4E3A81823502c971c1F973D",
    "0x65fE4C867B4b0B639dee34B329F3cB69A0151231",
    "0x3C5E562cf20EDb9ba5310D8Ca08A1f723d0Ab8eE",
    "0x0B3E6C77712d3c2112c8936C1669e49e1F0f6b39",
    "0xa396AE6fe5E7b6F1453514a4E6a73192Dcfab182",
    "0x840Fb2Da631Ce352104040b92808D19a3Ee73c18",
    "0xe5110F65C667B810590C4AC0B9100277E547C5Cf",
    "0xE9e9A022ff16662aD549C05954FB41482dAEA6A3",
    "0xE7a9DBC80C1472B460d604419BDa7B4Bf86bA07F",
    "0x08069Aa9331aC73e5Bf075d2F5631Ed8E3Bf6D5d",
    "0x5c54f2AEbc8F32555a48881944b0E2025bC8fC55",
    "0x8FC2F75a58f7a304f89C2A69E527e03A75F86806",
    "0x277ABF42d6605B9659F866Cc4B4e73EE6Ae74673",
    "0x9d8C50Af9e8908371502110e19299dD548FC5Ea3",
    "0x76628A5BfabC3bc826d580CB67a06D5d92C6A466",
    "0x0D820807819A267CeDE2885377Fe8672a4FFe0e3",
    "0x8C496cA756Dae74EFa78f0346F614c2c74c70d53",
    "0xa5a06984e0B82140e95c0Febbd6638E06D254bce",
    "0xb77E875421A8f24cc1143E28b3D43BB0c6b6A0f8",
    "0xB015771583a4C977EBB125Ed856F44ee7602Bf3C",
    "0x12282d0e305e00E37A6f2ACDCd4E9782bf40F015",
    "0xdaEd88ed03912312F32BaF035b1ef63Bc3F7E032",
    "0xb373451E4E422EA6bB92763c622Db62080F84788",
    "0x4ab70E6072Bc043f86856EfDC8F30B2Bb65bB5dC",
    "0xE9006C3732ddE8C2d5c4Ce65568179035aCE744D",
    "0x558132644688085935a9A49E4b1Ae7d63897b282",
    "0x7feD743Cab969D2d540f97E4Ac52FEa4A0D64044",
    "0x99950F765070456130F12a4203AC7acBC2CEDc6e",
    "0x4C9714dd3259962Af10e82f2048319c947514E70",
    "0x07B800883Ff4184aCc267117084569c4c5558326",
    "0x0353e9923e670CD18712fF4E8d9e6158E7006E35",
    "0x78c0c4662Cdc609C0d4F1677E5fD42464b2b67E3",
    "0x8a0473179492147c785bE2dd0B65F320aA4650Ec",
    "0x1A7d8E3ED8E6c1911a7770506C0B8b3fdFE9d04b",
    "0x5B28E91bd44945eC073ba1bA2b67e4DE42d4704d",
    "0xFbb53706595d2212Bb7430c2F8050330416b1a56",
    "0xF6997Ce22ac27793C336fCA17945dcd88Dc15154",
    "0xc4fcC11b6161c2254DF23234f02cE43321146782",
    "0xDA2B6E4EE93f0ac4F3f094eAD2D2353Bc29B0895",
    "0xf31Db9d9B2b31c36822ccBC7A0a74B6bE3477D5d",
    "0x67D56152506fD65228184DE81fA043D557d9d35E",
    "0x87e29b83c583516A1D0801c3222b1ED418d2936f",
    "0x94A3F761a05C4dCE96E0B0195E8634c22A5e22fa",
    "0x561D4C86576C9E1675F1f77318ECfc18EC85D9Dc",
    "0x0E8a2a225eB3E082cD5DeE44e35048CcE5FE5778",
    "0x5dBec8fcdE6df6a4D1c9708E24a1437B3b878E55",
    "0xD54876E1EeE1Ff8F6Cd77722e96159348Df6875E",
    "0x6625dD801c49CcEa67e80140d5d91efE55e69c67",
    "0x27873ade0B940Dc059d4bAB21C08A9590264Bb16",
    "0xac0c3F0212865e8b242E72F5a4663e13D609654E",
    "0x8F5aEB59DCFF09b9219081eE1B2233Ab42D6f237",
    "0xD06240B68A66fc009Cd81ef695B4b4CC31E37Bf9",
    "0xab1DC4B2EEEEcC9D98cA8cC5e96847a0b79Cd24c",
    "0x0284439da6039B2e2fdd4dcA08444856b9B7A6b9",
    "0x70E983a8303f73Cd7af87E91D528B7074dfade6E",
    "0xEd4ca23FE53A936f3B74906b731f730DFD269508",
    "0x462b9990F843ba6f7Ab141DDfEf03A65Df9e48EF",
    "0xcC93215f0DcE36F4E33e4911E68C7179AD18e8Bf",
    "0x3d25DdA985AbF6F19bEBD946607Ec2295159c63b",
    "0x34FA0BA700467Af58F0f60BFA781B734D329162E",
    "0xC4Ff988Eb3f60930C4e8402Ac3E357Aca8eBf5fd",
    "0xe481905FCF9e3EadcA2f73Dadf7AA0bb095Bb214",
    "0xBc27F8E99C9661bf5230754aF9aaBDa8B700a8De",
    "0x16B34693454847Ead258AF64bB6af1d2Db557243",
    "0x7ae8A51C5db644ef6C0a6359Cd60B93239E8e9cf",
    "0x291dA54D7AFd43dD7Ca84FA0EB637d0CC75c11df",
    "0x5b4A5eF5C2c6e7F19b0aC7197b8476D96Dfa40EA",
    "0x4ACc78142300CeA8e44Cb5548036623F69fA4330",
    "0xC9C7de65a33ABc7cF1aBB41D3A8F1dba2789Dd44",
    "0x4c7612f19bE628225c68e3589e6E7fB93840Db39",
    "0xE54dee946351Fa9B806C2b09FE69c6bF1534AFc7",
    "0x9D2B1C7e76A2987Dd8A9d8271B2078399FA4753C",
    "0x25609B917c0015046D6F0dA5f4C4ebE7f8239b43",
    "0xE8E736Ae64AF815dCc4d450D683ED592bE26bAA9",
    "0x9F949434eab1d9bEe502F3cf9651ee564cF1beFA",
    "0xD6a0bB53De9d73E5288f4bD50d2159535A1c5D7b",
    "0x9b0a2358a83001C6872a07620B16B724a2323b25",
    "0x2ED381f0e4eAFB4A79112DAAE850bBaBF5c39D82",
    "0x46635E7D95E6122805985dE2945cb67fa68525b2",
    "0x6f454FA5C8c9dC56209f6f5D4c7dF32c735C4946",
    "0x14433A8ED38FD568dEE09B138f12937e43DbeDaE",
    "0xB6aeF78D86464ec6C8095F1e0B7374A0f8a0bB16",
    "0xb8222E4ffc2Dff3b1e481Be36351593BFcF8Be2F",
    "0x71305F3C7A80721f0fEC2bc164389ee170630C56",
    "0x0EfFD398700d193E4BeD631C50DE7C97FB2B6099",
    "0x73CE4c69c1e4b26b324f62da4a2439bAE8957d38",
    "0xBc50cfc691225397621797107947d5D2787bF0ad",
    "0x00Ba9a508580d634A6eE4a6C96B6c389FFFeCB6d",
    "0x6b90c01a2a3D341E73b111BC911CF7522c79A7Fd",
    "0x6Bae6C87636799f4C28eFD0E426F4b0FA1cb436d",
    "0xe5f742D9296106b6027C92525AB32834F52152d0",
    "0x46f98D015aeb57171ED7A44A5c7080c45b74Aeab",
    "0x770752b48839c4d35b5157dAFc128b2662e6C0b4",
    "0x2b0c90C64C17a4057C30871d8e3D979BffCE427F",
    "0xc330440814443d4774ba112819392C366F1BD978",
    "0x348CD7f55d7aC4Ad381E833D0d447A336cB7bB1A",
    "0xf5307E6978FD413929D8AE5186308989a61205BE",
    "0x84A0655401304AD27feA4209b70875c52c45cb87",
    "0x43CCda6275973913Ec16392Afec6b1F547afDeD3",
    "0x09E0Ca81aC9741e48B7C862eE172246F60591D39",
    "0x95921Da9F69d177c1c4421FF3b70446883C538b8",
    "0x10959926fb4926D181a2ea46CeB234150Ab70C9B",
    "0xEBF3445B5c25c2d1A76c20AA138edb840eeacDc3",
    "0xa89cf6aD98d62c00E59413B53f0539F90Cc0C9FF",
    "0x2fFf1e9605Fc5DBB61902681Faa91d4F1B7C72d3",
    "0xE720988061C9Be1E0C3519B12d9a1CC422bE1FE8",
    "0xBFb43CF26A0622616fd16e7ADECF72C4E01be142",
    "0x70C6106D59d3c47bc42e494eBd52298d176913aE",
    "0x1BB097AD7AC108dbcdb6138C4c8C2b71902eca43",
    "0xCc1407C05eC391655F93c3A3dd40A6134434f7f7",
    "0xD56d8139d11Abd9209116fBf675f1A8f3aC2285C",
    "0x568588CF1896186Da51593f4ee5925712112Ee30",
    "0xE5e58Cf23a648dC2ba89355d76e04Dc2869BE98D",
    "0xD9c7cE66467F40BD42c4482C1EeE7ACdf13e62A9",
    "0xEb80536901cd94F3380E1a2a28eA32Cb368e4A2f",
    "0xA2d69e14CF6fad51E7FBFb0FF05a86e56294A877",
    "0x0F665f4c0525F8c4Bc5814CFC1188Bc87109Bdd5",
    "0x8f311136325Bb3602aF228E9449D277e72D82934",
    "0x2849735A064De9930B5c799a37e9E33ddd6E83E1",
    "0x366E056486ef9449210765De59b52a4FE3A2C6C3",
    "0xa4b23E4ED109DA6c7121aC0648F1fc2CC85c2E53",
    "0xC1dFeDD7B8Fc3010a42466E51c773068fb95F485",
    "0x241EAF77f937CA370087aF456f9572aB64EBC2fD",
    "0x9ED2dCe3890E77Ab8dec4b0A0702F73d8F59A007",
    "0xBB8e3A623cfeA38290e1FF60a5696Df937556572",
    "0x47a1322A8BEa75046551D74Ba7cefa47611396d6",
    "0xEfDb0e08423236c903C86Bc16Efc7B579b3C6D70",
    "0x0d9eC0aD66D54c4245fc8C3Dc5F5263904054589",
    "0xc7BC7F7922021C8E1aeEd88Dade728B045e491A9",
    "0xD353Ad67518508741E64e8A90531e591142f4575",
    "0x842702F9EdaeFAFBCF5C134F84492428d97D720B",
    "0x1fc16599C989755BaE7f2Ab4A8a754cC276E4d99",
    "0xb7992538B8f32F9b3775C0546F1899Ed7e57F6A5",
    "0xcEB5591110444504bf02283C05bFaF42d6eA0F07",
    "0x597d4dCF6e8c70E1B487496E715e772e81985f0C",
    "0x6B1d1BEf52b300d29b5b969C523ea0099C8fd960",
    "0x6f9f3bc055C5f86972E45588a610349824f69eE1",
    "0x4D13bEbb40bC6F9a752Bc9c607369831A22e151F",
    "0x00ed58aACcf9B89cd5Abe7c3eB77747947F74911",
    "0xd848d0d198773297E64167d8bbbfD5f6c5cD2630",
    "0x88Ae09BfdF62A768bA82BE7C033867A035f71552",
    "0xaF763E3c9AF93a75e93018F93227D34DD5D31D6D",
    "0xc138aE7bCD1fDe0606a4eD6c8B7413e80c796915",
    "0xBf5936f98d2bB9AD7bEccA91C957f75Ab8F4a9D0",
    "0x27d991Be14AEA0781a2A8EFA59E22bba151656BD",
    "0x83aF5a649B2164F5e51b4F6D7e650426B35665f7",
    "0xd82a1b9635D6dDD5611308D2F690AAD9B23C6DeD",
    "0x8db50fE3777cbB8895D8076af2C1EF2443F4c994",
    "0x31aA5430729C6e99C76DaA99c427A89E4DD75a73",
    "0x73C6534E2832f63ee62E4b1877aB783a2120796A",
    "0xB35F768Bf35D9e25cA48D647C9E91Dd6fF2bb865",
    "0xD11494A6D9c10a28EDd722Eb9F5D4492a321799C",
    "0x2cb5Dc7e58aCf57EfAA18d8f78E0a39458aa6fd7",
    "0x6878B312a886cEcaC9Db1EcE5B6478E2ad1Fcc13",
    "0xB14018F08f22084A1b25Bdac30267382ccf02c36",
    "0xa998612Ee862e01E6553615a41D9BF780727D40d",
    "0x36101c09Dd5453721e29e6295be9006bb81A1127",
    "0xcE538929975C2bDfec9EA0521684C64B2339859a",
    "0x17E32FdB2c47B2e46245e3ad25228cd3FAff230f",
    "0xDbaAf06EC5c23b845c9809Dc37013F191b5e8063",
    "0xcEaC2cF1cd6A135fe4D4FA072ED0Bb24e8B1E7cA",
    "0x1FCF207b5218A90cD3d11Eb4DD4e278693F3bA8b",
    "0xa2751A82916b4C8D2Ec827afb56E8Ffc0b794f75",
    "0x709B81045739D577D0bc239812c9Ab33Bd9A939C",
    "0x489a91CEbC04Be1E4f30E47A901158554965692F",
    "0x5FAF423BB9127EC86c187aff49d3DdDF0212EDC7",
    "0xACc6a1A6Df88bCDA37c843E0423A21d35Da1c65b",
    "0x80AF0aF8C7AB024039F49Fb4BB5F440b1297c413",
    "0x4f24d3C2e033fE38fE09673b4773Bb54326c2dae",
    "0xbf830432894AD39454314Cdd402D466c39A47b39",
    "0x41B39f7fBb186833c32f7a0a17e00f01605f8105",
    "0x221CedB73B5c825AB1F707Ac520807a29DCAd620",
    "0x729deb70357E961aBD688Bf658A555E4D791cE0f",
    "0x5Fe30b7c0ed489493A42A8Bc43b8A73Ca72F6735",
    "0x481382fD7019d52156133E97e29d232894c83969",
    "0x400F18AA3fC67FcA552e45d8E78b5921D60c99a3",
    "0xafF6e51aFAea6843274Ca9F900A1361e75d450B1",
    "0x581f1e4e8629D6a9aE5C24dbb7849CFF6C2F4558",
    "0x54A737AdA8249f011373B799245Aec941E30D9F8",
    "0x867319162659ac593B2f2cF97A970eb7A55d87e9",
    "0x93383277928B75510eEB06Eda43334B87dA3b4B7",
    "0x01fe9740074668407880B85F18804D4d8eA5641E",
    "0x707A29e6593406E6444C4A465f535aD44962cEf6",
    "0x32178d595815b129b5dfd6D76766BF7748DA2ba0",
    "0x3E33b7B6E72239c795758ea4cdC06403a0b83f92",
    "0x13CFE7Ea548d325002d0bD9F42772fE7aEA18E35",
    "0x88aC221156E9c4678df542E9334Ec86443160e8d",
    "0x065F255b78C5f4f19A79bD996188F39b40C084b3",
    "0x26CaAbFf1581233799dfe6D57C4ca80A9F20D713",
    "0x1c7ECD6EB506248727E5B6500f14106DEB2F2257",
    "0x859806BFE1Ea9C9E615942B3aa48B6712ea416Fa",
    "0xD06163a026b7CC2d65164f5EC628d9e2D0eA881D",
    "0xC6E9CEF92f584CB265dd8785598F95c5d6eA27DD",
    "0x3d09FaCB91EbF3CE40675d684374Bcb7056fcaD8",
    "0x7137ce5AED7b8d31856d34700E9e36fC78dc59D0",
    "0xd12A2D8a6e3C2D22c5Acb538DC0E5128C954c467",
    "0x98364834FC16F68BCfbC0723b6293a82283b35C9",
    "0x7130515B5Ad3D0962E1022593Cf26FBBa1Af9701",
    "0xFC4C99a58E9257D387C197B97529e6e847B0A159",
    "0x0d76C6577dFaF57AcA33848C40E646A7288cE183",
    "0xEeD8403B6513c80Bfcedeb8b616796e4b91D1a0b",
    "0x59371132ED9eB0Aa7114Dd17aeb9D498526C2732",
    "0x523137Bbca22Fe5556f1A4b01221a93747d87b23",
    "0x26FF4305D51bEAEcEe885524f43f6f872deeA932",
    "0xa81a3f78A38cB878890B0beDE8Df68E0e2B8592B",
    "0x169a216B9fc3D05b10C34C25071B05791Fa0f835",
    "0xa68d20dc2a6ACF744274a0301cde778744E812E9",
    "0x427cB4D6D70Df9d8911aB3F68286cb023CE1Ab81",
    "0x32a9c5B51a36CA0219A6523CB207E8fBaFE2f6e7",
    "0x36C9E304e843d50252F710aa6e337a67eE773116",
    "0xa3bBc5A9F32c12F6e51e83Bc520ED82B261B9798",
    "0xa11a5703eB288751D89C1191E14385c3666EAeB6",
    "0x9fcB1c83AA90Df4Cd2DCE5c4EB9e211c3d0D3cCc",
    "0x6E4ef712deCBe435C2E7edCB2Ce4A3c7fa46317a",
    "0x33cf288A08faC26094289e48B32f9d790642701e",
    "0x92A3692b5F3254060385D270eaF077917d7527ea",
    "0x59092cd139f0B9dae4165c7165a45168012159d6",
    "0xCe04FB29F98f426f7ba12D193f5f42Cbb26FE6b3",
    "0x805576E6106E5e24765DA8379BDb30a2e38F3CDd",
    "0x02736d5c8dcea65539993d143A3DE90ceBcA9c3c",
    "0x0bA3061F2cC48787846Aaf58B5cea3509151932a",
    "0x91E9CC1242018041cF419E475Bdbe893a3343312",
    "0x7de40d2Ec0CE229E2F71EFDfa1dd5DA1e34208B1",
    "0xb58F440fa41d5AfA09B69b7Cadd106C12F05850E",
    "0x97F3e6408f9679830cbf93b57B30693be566939f",
    "0x77F7b629bB8C54bD8D12150f5dA56918AF554963",
    "0xA192fcB81a6027c67576C58a8ed86D0278289c5c",
    "0x97012A4DC1F3cE994bF700566cF9897243d4a0a7",
    "0x1544a2eE418F18347a86599f450b8ff26930a4aA",
    "0x32b98a0f12C70a16B2e91c37aE6282cC18Ba5C53",
    "0x29001Eab4c1c7Fe0550D2D14e98d78787C6a78CF",
    "0xb0240794108Fd89C99BB828C9eBc0e7d9703C2f8",
    "0xC78d55FEf09278943227D0131Ae919A2620E67D1",
    "0x4332CBc500a70B6031b7329FDaA58f3737f8f77e",
    "0x5F822648389Ed45c9905de7E6A71Fc69CF5708Ab",
    "0x9701927B7317b27D2420B1c8d8fe95ea26588121",
    "0x6CbA73FCf90d8b268c965B6F7d15565072552338",
    "0x3ee1A224F34c962dC70c4A5544B96a7e0F26c2A4",
    "0x2b0A297590b2af143c5a6bE58Fd2034690e502b5",
    "0xB81EAca1648450f591c2809EFe68da07B879F55d",
    "0xF5ec8189e583DF6f059539f6b808c5231e100D2F",
    "0xDAFe7BC4d7E5aAcb5D6A7a2B09d0165Cdd4B525d",
    "0xE74984837dF1103fb22F8519597915F3FC037237",
    "0xa620FA9D7CC1D86F87992fE8C6a2fE5cE01C19cA",
    "0x8Ad86D27369b307F621Abc2B32F33C34e68d7Cc5",
    "0x14822b726b856182cDcD85b85EeCb9deBD36C999",
    "0xDdae90E72c4A80dAA3DBdf45cC142d1Fbe238177",
    "0x3182895CEcd86632CD9ffad135852FC951B13235",
    "0x76Fc1819B22D5B29490bbf8355ed29b980D17CD8",
    "0x9Ac0233a97B0005462f7D65943B8CF6d7d074280",
    "0xA1374ECa41d507D7711bE88DD4F307b5FcE0Cf2f",
    "0xca4115CD1A357a89FF15Ff2edF9c40bbC58Da4c3",
    "0xa467E7447DA05688Ab2e3d09fe9cc5E0a6FCACf1",
  ];

  const merkleTree = new MerkleTree(elements, keccak256, {
    hashLeaves: true,
    sortPairs: true,
  });

  const proof = merkleTree.getHexProof(keccak256(address));

  return proof;
};

export const mbbtIsEligibleWhitelist = async (address) => {
  window.mbbtGeneralMembershipContract = await new web3.eth.Contract(
    mbbtGeneralMembershipContractABI,
    mbbtGeneralMembershipContractAddress
  );
  const proof = await mbbtCreateMerkleTree(address);

  const message = await window.mbbtGeneralMembershipContract.methods
    .isEligibleWhitelist(proof, address)
    .call({ from: address });

  return [proof, message];
};

export const mbbtMintNFTWhitelist = async (address) => {
  const connectedNetworkId = await window.web3.eth.getChainId();

  window.mbbtGeneralMembershipContract = await new web3.eth.Contract(
    mbbtGeneralMembershipContractABI,
    mbbtGeneralMembershipContractAddress
  );
  const eligibleObject = await mbbtIsEligibleWhitelist(address);
  let eligible = eligibleObject[1];
  let proof = eligibleObject[0];
  //Address is whitelisted
  if (eligible) {
    let nonce = await web3.eth.getTransactionCount(address, "latest");

    //Set up your Ethereum transaction
    const transactionParameters = {
      to: mbbtGeneralMembershipContractAddress, // Required except during contract publications.
      from: address, // must match user's active address.
      nonce: nonce.toString(),
      gas: web3.utils.toHex(244000),
      data: window.mbbtGeneralMembershipContract.methods
        .mintWhitelistGeneralMembership(proof)
        .encodeABI(), //make call to NFT smart contract
    };

    //Sign the transaction via Metamask
    try {
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      return {
        success: true,
        status: "View your transaction on Etherscan",
        txLink: `https://${
          connectedNetworkId === 1 ? "" : "rinkeby."
        }etherscan.io/tx/${txHash}`,
        txHash,
      };
    } catch (error) {
      return {
        // success: false,
        // status: "😥 Something went wrong: " + error.message,
        // txLink: "",
      };
    }
  } else {
    return {
      success: false,
      status: "Not eligible for minting",
      txLink: "",
    };
  }
};
