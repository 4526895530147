import { Fragment, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { Colors } from "../../utils/colors";

import SpinLoader from "../SpinLoader";

const TermsOfService = () => {

  const [isPageLoading, setIsPageLoading] = useState(true);


  return (
    <Fragment>
      {isPageLoading ? (
        <div className={css(styles.loadingPage)}>
          <SpinLoader isLoading={true} />
        </div>
      ) : null}
      <iframe 
        src="https://v1.embednotion.com/embed/f60ec22bfb3a42419aa60829e9f71698"
        className={css(styles.iframe)}
        onLoad={() => setIsPageLoading(false)}
      />
    </Fragment>
  )
};

const styles = StyleSheet.create({
  iframe: {
    width: "100%", 
    height: "calc(100vh - 70px)", 
    padding: "none",
  },
  loadingPage: {
    position: "absolute",
    zIndex: 3,
    top: 70,
    left: 0,
    width: "100%",
    height: "calc(100vh - 70px)",
    background: Colors.white(),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backdropFilter: "blur(5px)"
  }
});

export default TermsOfService;