import { css, StyleSheet } from "aphrodite";
import { fadeInRight } from "react-animations";
import Header from "../components/Header";
import TitleHeader from "../components/TitleHeader";
import ContentBlock from "../components/ContentBlock";
import ActionButton from "../components/ActionButton";
import magenta from "../assets/magenta.webp";
import Meebit from "../assets/Meebit_2.webp";
import MeebitContainer from "../components/MeebitContainer";

import MBBTMembershipMinter from "../components/Minting/MBBTMembershipMinter";
import { useEffect, useState } from "react";
import MailingList from "../components/Sections/MailingList";
import MBBTAbout from "../components/Sections/MBBTAbout";

function MBBTMintPage(props) {
  //Wallet variables
  const {
    walletConnected,
    setWalletConnected,
    openMailingNotification,
    selectedAddress,
    walletAddress,
    setWallet,
    networkId,
    setNetworkId,
    networkErrorMessage,
    setNetworkErrorMessage,
    disabled,
    setDisabled,
  } = props;

  const [hasMBBTMembershipToken, setHasMBBTMembershipToken] = useState(false);
  const [remainingMBBTMembershipCount, setRemainingMBBTMembershipCount] =
    useState(455);

  const mbbtMembershipMinted = () => {
    setHasMBBTMembershipToken(true);
    setRemainingMBBTMembershipCount(remainingMBBTMembershipCount - 1);
  };

  useEffect(() => {
    const checkHasMBBTMembershipToken = async () => {
      if (window.mbbtGeneralMembershipContract && selectedAddress) {
        const numTokens = await window.mbbtGeneralMembershipContract.methods
          .balanceOf(selectedAddress)
          .call({ from: selectedAddress });

        setHasMBBTMembershipToken(parseInt(numTokens, 10) > 0);
      }
    };

    checkHasMBBTMembershipToken();
  }, [walletConnected, selectedAddress]);

  useEffect(() => {
    const getMBBTMembershipSupply = async () => {
      if (window.mbbtGeneralMembershipContract) {
        const numTokens = await window.mbbtGeneralMembershipContract.methods
          .totalSupply()
          .call();

        const intNumTokens = parseInt(numTokens, 10);
        setRemainingMBBTMembershipCount(455 - intNumTokens);
      }
    };

    setTimeout(() => {
      getMBBTMembershipSupply();
    }, 200);
  }, []);

  const renderMintedBlock = () => {
    return (
      <div>
        <div className={css(styles.joinContentContainer, styles.fadeInRight)}>
          <span className={css(styles.joinTitle)}>General Membership NFT</span>

          <MBBTMembershipMinter
            walletConnected={walletConnected}
            setWalletConnected={setWalletConnected}
            walletAddress={walletAddress}
            setWallet={setWallet}
            openMailingNotification={openMailingNotification}
            selectedAddress={selectedAddress}
            setHasMBBTMembershipToken={setHasMBBTMembershipToken}
            hasMBBTMembershipToken={hasMBBTMembershipToken}
            networkId={networkId}
            setNetworkId={setNetworkId}
            networkErrorMessage={networkErrorMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
            disabled={disabled}
            setDisabled={setDisabled}
          />

          <div className={css(styles.joinDiscord)}>
            <div className={css(styles.innerJoinTitle)}>Join The Discord</div>
            <a
              href="https://discord.com/invite/zuJUaTjWBZ"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "#fff",
                fontSize: 18,
                width: "100%",
                overflow: "hidden",
                display: "block",
                textOverflow: "ellipsis",
              }}
            >
              https://discord.com/invite/zuJUaTjWBZ
            </a>
          </div>

          {/* <MailingList
            className={styles.mailingList}
            titleClassName={styles.mailingTitle}
            formClassName={styles.formClassName}
            topMailFormGroupClassName={styles.topMailFormGroupClassName}
          /> */}

          <div className={css(styles.remaining)}>
            Current Members:{" "}
            {(455 - remainingMBBTMembershipCount).toLocaleString()}
          </div>
        </div>
      </div>
    );
  };

  const renderMintBlock = () => {
    return (
      <div className={css(styles.joinContentContainer)}>
        <span className={css(styles.joinTitle)}>
          General Membership NFT (MBBT)
        </span>
        <span className={css(styles.description, styles.italics)}>
          If you purchased at least 10 MBBT prior to November 27, 2021, you're
          eligible to mint a free (+ gas) MeebitsDAO General Membership NFT.
        </span>
        <span className={css(styles.description)}>
          Your General Membership NFT is your key to joining the world of
          MeebitsDAO. With that comes:
        </span>
        <ul className={css(styles.list)}>
          <li className={css(styles.bulletPoint)}>
            Access to MeebitsDAO Discord
          </li>
          <li className={css(styles.bulletPoint)}>
            The ability to participate in project/DAO governance
          </li>
          <li className={css(styles.bulletPoint, styles.lastBullet)}>
            The opportunity to build the future of the Meebits ecosystem
          </li>
        </ul>
        <MBBTMembershipMinter
          walletConnected={walletConnected}
          setWalletConnected={setWalletConnected}
          walletAddress={walletAddress}
          setWallet={setWallet}
          openMailingNotification={openMailingNotification}
          selectedAddress={selectedAddress}
          setMBBTMembershipMinted={mbbtMembershipMinted}
          hasMBBTMembershipToken={hasMBBTMembershipToken}
          networkId={networkId}
          setNetworkId={setNetworkId}
          networkErrorMessage={networkErrorMessage}
          setNetworkErrorMessage={setNetworkErrorMessage}
          disabled={disabled}
          setDisabled={setDisabled}
        />
        {/*        <div className={css(styles.remaining)}>
          0.05 ETH (limit 1 per address)
        </div>*/}
        <div className={css(styles.remaining)}>
          Current Members:{" "}
          {(455 - remainingMBBTMembershipCount).toLocaleString()}
          {/* Available: {remainingMBBTMembershipCount.toLocaleString()} / 10,000 */}
        </div>
        {window.ethereum?.selectedAddress && (
          <div className={css(styles.remaining)}>
            Connected Address:{" "}
            {selectedAddress.substring(0, 6) +
              "..." +
              selectedAddress.substring(
                selectedAddress.length - 4,
                selectedAddress.length
              )}
          </div>
        )}
        <div className={css(styles.disclaimer)}>
          This token does not provide ownership or fractional ownership of any
          Meebits. MeebitsDAO is not affiliated with LarvaLabs. The $MBBT
          promotion has also ended.
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={css(styles.about)}>
        <MBBTAbout />
      </div>
      <div className={css(styles.joinSection)} id="join-mbbt-section">
        <TitleHeader title={"Join"} />
        <ContentBlock
          content={
            <>
              {hasMBBTMembershipToken ? renderMintedBlock() : renderMintBlock()}
            </>
          }
          blockStyle={styles.blockStyle}
        />
        <MeebitContainer meebit={Meebit} background={magenta} left />
      </div>
    </>
  );
}
const styles = StyleSheet.create({
  fadeInRight: {
    animationName: fadeInRight,
    animationDuration: ".7s",
  },
  joinSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    position: "relative",
  },
  inner: {
    position: "relative",

    "@media only screen and (min-width: 1024px)": {
      background: "rgba(0, 56, 109, 1)",
      top: -350,
    },
  },
  about: {
    marginTop: 50,
  },
  blockStyle: {
    marginRight: 341,
    height: 650,
    width: 575,
  },
  joinDiscord: {
    width: "100%",
    marginTop: 24,
  },
  formClassName: {
    maxWidth: "unset",
  },
  innerJoinTitle: {
    fontSize: 20,
    fontStyle: "italic",
    fontWeight: 800,
    marginTop: 8,
    border: 0,
    color: "rgb(255, 255, 255)",
    textTransform: "uppercase",
    marginBottom: 8,
  },
  joinTitle: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "29px",
    marginBottom: 15,
  },
  italics: {
    fontStyle: "italic",
    fontWeight: 400,
    opacity: 0.8,
  },
  mailingTitle: {
    fontSize: 20,
    textAlign: "left",
  },
  joinContentContainer: {
    paddingTop: 39,
    paddingBottom: 33,
    display: "flex",
    boxSizing: "border-box",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  mailingList: {
    background: "none",
    padding: 0,
    alignItems: "flex-start",
    height: "unset",
    marginTop: 24,
  },
  description: {
    marginBottom: 15,
  },
  bulletPoint: {
    marginBottom: 15,
    width: "100%",
  },
  remaining: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "17px",
    marginTop: 14,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    textAlign: "center",
  },
  lastBullet: {
    // marginBottom: 40,
  },
  list: {
    paddingLeft: 20,
  },
  topMailFormGroupClassName: {
    marginTop: 8,
  },
  disclaimer: {
    fontSize: 12,
    opacity: 0.6,
    fontStyle: "italic",
    marginTop: 16,
    textAlign: "center",
    // color: "#ddd",
  },
  mbbt: {
    marginTop: "unset",
    // position: "absolute",
    // top: 16,
    // right: 16,
  },
});

export default MBBTMintPage;
