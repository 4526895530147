import { css, StyleSheet } from "aphrodite";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";

// Components
import ActionButton from "../ActionButton";
import Delegate from "./Delegate";
import BackButton from "../BackButton";
import Box from "../BoxContainer";
import SpinLoader from "../SpinLoader";
import ErrorBanner from "../ErrorBanner";
import TransactionSuccess from "./TransactionSuccess";

// Utils
import { Colors } from "../../utils/colors.js";
import {
  mintMonthlyToken,
  isEligibleMonthlyMint,
  getSigningHash,
} from "../../utils/governanceTokenInteract.js";
import { setDelegate } from "../../utils/delegationInteract";
import { switchNetworkMatic } from "../../utils/interact";
import { getNotionValueByKey } from "../../utils/helpers";

export default function ClaimSuccess({
  delegate,
  setPage,
  walletAddress,
  userCurrentDelegate,
}) {
  const {
    library,
    chainId,
    account,
    activate,
    deactivate,
    active
  } = useWeb3React();
  const location = useLocation(); // used to read router props
  const navigate = useNavigate(); // used to programmatically push route

  const [isInitializing, setIsInitializing] = useState(true);
  const [isMintingToken, setIsMintingToken] = useState(false);
  const [isSettingDelegate, setIsSettingDelegate] = useState(false);

  const [mintingSummary, setMintingSummary] = useState({});
  const [delegationSummary, setDelegationSummary] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const [isEligibleForToken, setIsEligibleForToken] = useState(false);

  useEffect(() => {
    async function checkEligibility() {
      if (!walletAddress) {
        // push user back to claim page to connect wallet (case: hard refresh)
        navigate("/claim");
      }

      // checks eligibility again before transaction is made
      setIsInitializing(true);
      try {
        const eligible = await isEligibleMonthlyMint(walletAddress);
        setIsEligibleForToken(eligible);
        setIsInitializing(false);
      } catch (error) {
        setIsInitializing(false);
        handleResponse({ success: false, status: error });
      }
    }

    checkEligibility();
  }, [walletAddress]);

  /**
   * Determines if criterias are met for user to be able to mint & claim token
   * @returns boolean
   */
  const canUserClaimToken = () => {
    return (
      isEligibleForToken &&
      !isMintingToken &&
      !isSettingDelegate &&
      !mintingSummary.success
    );
  };

  /**
   * Determines if criterias are met for user to be able to set delegate
   * @returns boolean
   */
  const canUserSetDelegate = () => {
    const isUserChangingDelegates =
      userCurrentDelegate && delegate && userCurrentDelegate.id !== delegate.id;

    return (
      !userCurrentDelegate || // if user does not currently have a delegate
      isUserChangingDelegates // setting delegates costs gas. we only want to set if user is changing delegates
    );
  };

  const onBackButtonClick = () => {
    const { state } = location;
    if (shouldShowSuccessScreen() || state?.isClaimingTokenOnly) {
      navigate("/claim");
    } else {
      setPage("delegate");
    }
  };

  const handleResponse = (data, onSuccess) => {
    const { success, status } = data;

    if (success) {
      onSuccess && onSuccess(data);
      setErrorMessage(null); // removes prev error message on success
    } else {
      if (typeof status == "object") {
        const error = status.response?.data?.error;

        // return setErrorMessage("Something went wrong. Please try again later!")
        return setErrorMessage(error || "Something went wrong. Please try again later!");
      }
      setErrorMessage(status);
    }
  };

  const claimTokenAPI = async () => {
    try {
      setIsMintingToken(true);
      const { provider } = library;
      await switchNetworkMatic(provider);
      const userSignature = await getSigningHash(walletAddress);
      const response = await mintMonthlyToken(walletAddress, userSignature, provider, chainId);
      setIsMintingToken(false);
      handleResponse(response, setMintingSummary);
    } catch (error) {
      setIsMintingToken(false);
      handleResponse({ success: false, status: error });
    }
  };

  const setUserDelegateAPI = async () => {
    try {
      setIsSettingDelegate(true);
      const { provider } = library;
      await switchNetworkMatic(provider);
      const delegateAddress = getNotionValueByKey("eth_address", delegate);
      const response = await setDelegate(walletAddress, delegateAddress, provider, chainId);
      setIsSettingDelegate(false);
      handleResponse(response, setDelegationSummary);
    } catch (error) {
      setIsSettingDelegate(false);
      console.log(error);
      handleResponse({ success: false, status: error });
    }
  };

  const handleClickByState = async () => {
    
    if (canUserClaimToken()) {
      return claimTokenAPI();
    } else if (canUserSetDelegate()) {
      return setUserDelegateAPI();
    }
  };

  const buttonLabelByState = () => {
    if (canUserClaimToken()) {
      return "Claim Token";
    } else if (isMintingToken) {
      return "Claiming Token";
    } else if (canUserSetDelegate()) {
      return "Set Delegate";
    } else if (isSettingDelegate) {
      return "Setting Delegate";
    }
  };

  const shouldShowSuccessScreen = () => {
    if (
      canUserClaimToken() &&
      canUserSetDelegate() &&
      delegationSummary.success &&
      mintingSummary.success
    ) {
      return true;
    } else if (
      canUserClaimToken() &&
      !canUserSetDelegate() &&
      mintingSummary.success
    ) {
      return true;
    } else if (
      canUserSetDelegate() &&
      !canUserClaimToken() &&
      delegationSummary.success
    ) {
      return true;
    } else if (!canUserSetDelegate() && !canUserClaimToken()) {
      return true;
    } else {
      return false;
    }
  };

  const renderScreenByState = () => {
    if (isInitializing) {
      return <SpinLoader isLoading={true} />;
    } else if (shouldShowSuccessScreen()) {
      return (
        <TransactionSuccess
          mintingSummary={mintingSummary}
          delegationSummary={delegationSummary}
          walletAddress={walletAddress}
        />
      );
    } else {
      return (
        <>
          <p className={css(styles.subtext)}>
            Please make sure the following details look correct.
          </p>
          {isEligibleForToken && (
            <Box
              style={mintingSummary.success && styles.opaque}
              text={mintingSummary.success ? "Claimed" : "You will receive"}
            >
              <div className={css(styles.bottom)}>1 MVOX</div>
            </Box>
          )}
          <Box text="You're delegating to">
            <Delegate
              className={styles.center}
              delegate={delegate}
              hideTooltip={true}
            />
          </Box>
          <ErrorBanner message={errorMessage} isShowing={errorMessage} />
          <ActionButton
            text={buttonLabelByState()}
            style={styles.button}
            onClick={handleClickByState}
            disabled={isMintingToken || isSettingDelegate}
          />
        </>
      );
    }
  };

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.backButtonContainer)}>
        <BackButton onClick={onBackButtonClick} />
      </div>
      {!shouldShowSuccessScreen() && (
        <h2 className={css(styles.title)}>Review your claim</h2>
      )}
      {renderScreenByState()}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    position: "relative",
  },

  title: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontSize: 24,
    marginTop: -16,
    "@media only screen and (max-width: 767px)": {
      marginTop: 25,
    },
  },
  subtext: {
    fontSize: 14,
    fontWeight: 300,
    textAlign: "justify",
    textAlignLast: "center",
    paddingBottom: 15,
  },

  bottom: {
    marginTop: 8,
    fontWeight: 800,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 24,
    fontStyle: "italic",
  },
  button: {
    fontSize: 16,
    margin: "0 auto",
    marginTop: 32,
    width: "fit-content",
    maxWidth: 306,
  },
  backButtonContainer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  tokenIcon: {
    height: 20,
    width: 20,
  },
  opaque: {
    opacity: 0.6,
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  editDelegateButton: {
    margin: "0 auto",
    padding: 0,
    fontSize: 14,
    height: 30,
    width: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0, 153, 250, 0.1)",
    fontStyle: "italic",
    textTransform: "uppercase",
    cursor: "pointer",
    // position: "absolute",
    // right: 10,
    // bottom: "calc(100% / 3 - 5px)",
    color: Colors.white(),
    textDecoration: "none",
    ":hover": {
      color: Colors.uiTeal(),
      textDecoration: "underline",
    },
  },
  relative: {
    position: "relative",
  },
});
