import { switchNetworkMatic } from "./interact";
import { ethers } from "ethers";

require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_MUMBAI_URL;
const governanceTokenContractABI =
  require("../contracts/MeebitsDAOGovernanceToken.json").abi;
const governanceTokenContractAddress =
  process.env.REACT_APP_MEEBITS_DAO_GOVERNANCE_TOKEN_CONTRACT_ADDRESS;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const axios = require("axios");
window.web3 = web3;
const { BASE_URL } = require("./constants");

window.governanceTokenContract = new web3.eth.Contract(
  governanceTokenContractABI,
  governanceTokenContractAddress
);

export const isEligibleMonthlyMint = async (address) => {
  window.governanceTokenContract = new web3.eth.Contract(
    governanceTokenContractABI,
    governanceTokenContractAddress
  );
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  try {
    const eligible = await window.governanceTokenContract.methods
      .isEligibleMonthlyMint(address, currentMonth)
      .call({ from: address });
    return eligible;
  } catch (e) {
    console.log(e);
  }
};

export const checkDelegatedTokenCount = async (address) => {
  window.governanceTokenContract = new web3.eth.Contract(
    governanceTokenContractABI,
    governanceTokenContractAddress
  );
  const balance = await window.governanceTokenContract.methods
    .balanceOf(address)
    .call();
  return balance;
};

export const getSigningHash = async (address) => {
  let url = BASE_URL + "/signature";
  const result = await axios.post(
    url,
    { address: address },
    { headers: { "Content-Type": "application/json" } }
  );
  return result.data;
};

export const mintMonthlyToken = async (address, signature, provider, chainId) => {
  console.log("address", address);
  const connectedNetworkId = chainId;
  window.governanceTokenContract = new web3.eth.Contract(
    governanceTokenContractABI,
    governanceTokenContractAddress
  );
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const isCheckSumAddress = web3.utils.checkAddressChecksum(address);
  if (!isCheckSumAddress) {
    address = web3.utils.toChecksumAddress(address);
  }

  console.log("addres", address)
  const eligible = await window.governanceTokenContract.methods
    .isEligibleMonthlyMint(address, currentMonth)
    .call({ from: address });

  //Address is whitelisted
  if (eligible) {
    let nonce = await web3.eth.getTransactionCount(address, "latest");

    // const feeData = await (
    //   await fetch("https://gasstation-mainnet.matic.network/v2")
    // ).json();

    // const maxFeePerGas = ethers.utils.parseUnits(
    //   feeData.standard.maxFee.toFixed(2),
    //   "gwei"
    // )._hex;
    // const maxPriorityFeePerGas = ethers.utils.parseUnits(
    //   feeData.standard.maxPriorityFee.toFixed(2),
    //   "gwei"
    // )._hex;

    //Set up your Ethereum transaction
    const transactionParameters = {
      to: governanceTokenContractAddress, // Required except during contract publications.
      from: address, // must match user's active address.
      nonce: nonce.toString(),
      maxPriorityFeePerGas: null, // Recommended maxPriorityFeePerGas
      maxFeePerGas: null, // Recommended maxFeePerGas
      data: window.governanceTokenContract.methods
        .mintMonthlyToken(signature)
        .encodeABI(), //make call to NFT smart contract
    };

    //Sign the transaction via Metamask
    try {
      const txHash = await provider.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      return {
        success: true,
        status: "View your transaction on Polygonscan",
        txLink: `https://${
          process.env.REACT_APP_ENVIRONMENT === "production" ? "" : "mumbai."
        }polygonscan.com/tx/${txHash}`,
        txHash,
      };
    } catch (error) {
      return {
        success: false,
        status: "Something went wrong: " + error.message,
        txLink: "",
      };
    }
  } else {
    return {
      success: false,
      status: "Not eligible for monthly mVOX token",
      txLink: "",
    };
  }
};

export const mintMaster = async (address) => {
  const connectedNetworkId = await window.web3.eth.getChainId();
  window.governanceTokenContract = new web3.eth.Contract(
    governanceTokenContractABI,
    governanceTokenContractAddress
  );
  const isCheckSumAddress = web3.utils.checkAddressChecksum(address);
  if (!isCheckSumAddress) {
    address = web3.utils.toChecksumAddress(address);
  }
  let nonce = await web3.eth.getTransactionCount(address, "latest");

  //Set up your Ethereum transaction
  const transactionParameters = {
    to: governanceTokenContractAddress, // Required except during contract publications.
    from: address, // must match user's active address.
    nonce: nonce.toString(),
    data: window.governanceTokenContract.methods.mintMaster().encodeABI(), //make call to NFT smart contract
  };

  //Sign the transaction via Metamask
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status: "View your transaction on Polygonscan",
      txLink: `https://${
        process.env.REACT_APP_ENVIRONMENT === "production" ? "" : "mumbai."
      }polygonscan.com/tx/${txHash}`,
      txHash,
    };
  } catch (error) {
    return {
      // success: false,
      // status: "😥 Something went wrong: " + error.message,
      // txLink: "",
    };
  }
};

export async function addToMetaMask(metamask, network) {
  if (!window.ethereum) {
    alert("Please install MetaMask and try again!");
    return;
  } else {
    switchNetworkMatic();
  }

  try {
    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: governanceTokenContractAddress,
          symbol: "mVOX",
          decimals: 0,
        },
      },
    });
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
  }
}
