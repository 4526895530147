import { css, StyleSheet } from "aphrodite";
import { useEffect, useRef, useState } from "react";
import ToolTipIcon from "../../assets/info-icon.png";
import { Colors } from "../../utils/colors";


export default function TokenSummaryRow({ label, count }) {
  
  return (
    <div className={css(styles.row)}>
      <img 
        src={ToolTipIcon} className={css(styles.toolTipIcon)} 
      />
      <span className={css(styles.label)}>{label}</span>
      <span className={css(styles.count)}>{count}</span>
    </div>
  )

}

const styles = StyleSheet.create({
  row: {
    display: "flex",
    alignItems: "center",
    padding: "5px 0"
  },
  toolTipIcon: {
    height: 23,
    width: 23
  },
  label: {
    color: Colors.white(),
    margin: "0px 15px 0px 10px",
    width: 180
  },
  count: {
    color: Colors.uiTeal(),
    fontSize: 24,
    fontStyle: "italic"
  }
})