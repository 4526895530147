import { css, StyleSheet } from "aphrodite";
// import { useEffect, useState } from "react";

// Utils
import { Colors } from "../utils/colors.js";

const ErrorBanner = ({ message, isShowing }) => {
  return (
    <div className={css(styles.bannerContainer, isShowing && styles.reveal)}>
      <p className={css(styles.bannerMessage)}>
        <i className={css(styles.errorIcon) + " fas fa-exclamation-triangle"} />
        {message}
      </p>
    </div>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    margin: "0 auto",
    height: 0,
    opacity: 0,
    transition: "all ease-in-out 0.2s",
  },
  bannerMessage: {
    margin: 0,
    fontSize: 14,
    color: Colors.uiRed(),
    maxWidth: 500,
    textAlign: "center",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },
  errorIcon: {
    fontSize: 20,
    marginRight: 5,
  },
  reveal: {
    height: "unset",
    opacity: 1,
  },
});

export default ErrorBanner;
