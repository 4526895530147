import { css, StyleSheet } from "aphrodite";

import ContentBlock from "../ContentBlock";
import MeebitContainer from "../MeebitContainer";
import Meebit from "../../assets/Meebit_Banner.webp";
import green from "../../assets/green.webp";
import TitleHeader from "../TitleHeader";
import ActionButton from "../ActionButton";
import Discord from "../../assets/discord-large.webp";
import Twitter from "../../assets/twitter-large.webp";
import Opensea from "../../assets/opensea-large.webp";
import Medium from "../../assets/medium-large.webp";

const socials = [
  {
    id: "discord",
    label: "Discord",
    href: "https://discord.gg/zuJUaTjWBZ",
    logo: Discord,
  },
  {
    id: "twitter",
    label: "Twitter",
    href: "https://twitter.com/MeebitsDAO",
    logo: Twitter,
  },
  {
    id: "medium",
    label: "Medium",
    href: "https://medium.com/meebitsdao",
    logo: Medium,
  },
  {
    id: "opensea",
    label: "OpenSea",
    href: "https://opensea.io/collection/mdao-members",
    logo: Opensea,
  },
];

function FindUs() {
  return (
    <div className={css(styles.section)}>
      <TitleHeader title={"Find Us"} style={styles.titleHeader} alignRight />
      <ContentBlock
        content={
          <div className={css(styles.contentBlockContainer)}>
            {socials.map((social, index) => {
              return (
                <ActionButton
                  text={
                    <div className={css(styles.actionButtonInner)}>
                      <img
                        src={social.logo}
                        className={css(styles.buttonLogo)}
                      />{" "}
                      <span>{social.label}</span>
                    </div>
                  }
                  key={index}
                  href={social.href}
                  style={[
                    styles.social,
                    index === socials.length - 1 && styles.lastSocial,
                  ]}
                />
              );
            })}
          </div>
        }
        blockStyle={styles.blockStyle}
      />
      <MeebitContainer
        meebit={Meebit}
        background={green}
        style={styles.meebitContainer}
      />
    </div>
  );
}
const styles = StyleSheet.create({
  section: {
    display: "flex",
    flexDirection: "column",
    marginTop: 96,
  },
  blockStyle: {
    marginLeft: 50,
    padding: 22,
    height: 400,
    width: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  contentBlockContainer: {
    width: "100%",
  },
  sandboxImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  button: {
    marginTop: 16,
  },
  actionButton: {
    margin: "0 auto",
    fontSize: 24,
  },
  meebitContainer: {
    left: "50%",
  },
  titleHeader: {
    width: 578,
  },
  social: {
    width: "100%",
    marginBottom: 16,
    height: 71,
  },
  lastSocial: {
    marginBottom: 0,
  },
  actionButtonInner: {
    position: "relative",
    width: "100%",
    height: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonLogo: {
    position: "absolute",
    left: -16,
    top: "50%",
    height: "100%",
    objectFit: "contain",
    transform: "translateY(-50%)",

    "@media only screen and (max-width: 767px)": {
      left: -32,
    },
  },
});

export default FindUs;
