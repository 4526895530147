import ENS, { getEnsAddress } from '@ensdomains/ensjs'
require("dotenv").config();
const contractABI =
  require("../contracts/MeebitsDAOGeneralMembership.json").abi;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const {
  POLYGON_SCAN_API_ENDPOINT,
  MATIC_NETWORK_PARAMS,
} = require("./constants");
const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");


const alchemyKey = process.env.REACT_APP_ALCHEMY_URL;
const contractAddress =
  process.env.REACT_APP_MEEBITS_DAO_GENERAL_MEMBERSHIP_CONTRACT_ADDRESS;
const web3 = createAlchemyWeb3(
  alchemyKey
);
window.web3 = web3;
window.contract = new web3.eth.Contract(contractABI, contractAddress);
window.ens = new ENS({ provider: window.web3.currentProvider, ensAddress: getEnsAddress('4') })

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "Connected.",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: err.message,
      };
    }
  } else {
    return {
      address: "",
      status:
        "You must install Metamask, a virtual Ethereum wallet, in your browser.",
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "Connected.",
        };
      } else {
        return {
          address: "",
          status: "Connect to Metamask to start.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: err.message,
      };
    }
  } else {
    return {
      address: "",
      status:
        "You must install Metamask, a virtual Ethereum wallet, in your browser.",
    };
  }
};

export const getEnsFromAddress = async (address) => {
  if (!window?.ens) return;

  try {
    // const ens = await window.ens.reverse(address);
    const { name: ens } = await window.ens.getName(address);
    if (!ens) return null;

    // best practice: we need to verify that the ens & address are linked
    const addressFromENS = await window.ens.name(ens).getAddress();
    if (!addressFromENS) return null;
    const a = address.toLowerCase();
    const b = addressFromENS.toLowerCase();
    return a === b ? ens : null;
  } catch {
    return null;
  }
};


/**
 * (Original) General Membership Token Functions
 */

export const checkGeneralMembershipTokenCount = async (address) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const balance = await window.contract.methods.balanceOf(address).call();
  console.log("General Membership Balance: ", balance);
  return balance;
};

export const mintNFT = async (address) => {
  const connectedNetworkId = await window.web3.eth.getChainId();
  const nonce = await web3.eth.getTransactionCount(address, "latest");

  //set up your Ethereum transaction
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: address, // must match user's active address.
    nonce: nonce.toString(),
    gas: web3.utils.toHex(244000),
    value: web3.utils.toHex(web3.utils.toWei("0.05", "ether")),
    data: window.contract.methods.mintGeneralMembership().encodeABI(), //make call to NFT smart contract
  };

  //sign the transaction via Metamask
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status: "View your transaction on Etherscan",
      txLink: `https://${
        connectedNetworkId === 1 ? "" : "rinkeby."
      }etherscan.io/tx/${txHash}`,
      txHash,
    };
  } catch (error) {
    // return {
    //   success: false,
    //   status: "😥 Something went wrong: " + error.message,
    //   txLink: ""
    // }
    return {};
  }
};

export const createMerkleTree = async (address) => {
  //Use merkle tree elements used to create smart contract merkle tree root

  const elements = [
    "0xC5e38233Cc0D7CFf9340e6139367aBA498EC9b18",
    "0xEDe64a571CFe98B936271B935a955620f387E05A",
    "0xF9e20049D1634b3aD75Dedc6072b04fF68FE01Db",
    "0x972E633f5f6F62d1F2A13977F113941FCb3b606b",
    "0x46E0DCcEb5357f1c59a9dE1B29173F85dDb8198F",
    "0xeF3d9DDf59F3622A04753C61E6C4D596e7FC610d",
    "0xb0325DbE7fA891436E83A094f9F12848c78e449b",
    "0x137d9174D3bd00F2153DcC0Fe7AF712d3876a71E",
    "0x63a9dbCe75413036B2B778E670aaBd4493aAF9F3",
    "0x78D29F8f33af8a1f4aD1EB33F5c46C953cB0cEef",
    "0xE8aeA1BBA7aec9b7a4522e6c2A96327964c217CB",
    "0xEbe7BD20A3FDEDD07DBE4Ff73Bb2348A61921415",
    "0x68D2E33c98B14c03c0693714BD389218c1713958",
    "0x94de7E2c73529EbF3206Aa3459e699fbCdfCD49b",
    "0x19FfA65cF48B8D0cD9081958e69b7A170824955C",
    "0x67265c94BDba5f990B19597A3838C11cE55F9a33",
    "0x7A9fe22691c811ea339D9B73150e6911a5343DcA",
    "0x022885692db44c78C4AF5088A37ce811937b1934",
    "0x738dF6bFd711d04416dAA15B10E309Fdf5Dd5945",
    "0x31e99699bCCde902afc7C4B6b23bB322b8459d22",
    "0x1Df242CD64DD0e59be52fb4d2EC359C6FE28A373",
    "0xDEC752D6dC5A17cCB4fE1673De0A282703Dbb661",
    "0x9f0004e85aB1a65d569cBd9a59A46ef0c84Cf470",
    "0xc842Ce7214A14Fa98186a010bcb43c7e99e4caF3",
    "0x452F438aAD8B675232C1fD7Ff8E940D72d8A9F45",
    "0x73667A14E46e6be3810CA9aA6c619c1c19c16B81",
    "0xe74166afB1a6c0cA3C355Ba33965a8C7fc3E2616",
    "0x6eF2376fA6e12Dabb3a3ED0Fb44E4ff29847Af68",
    "0xa047D7A9750986Ec993272485e83B5d0886A865B",
    "0x2Ab1735Ab09F57DB6436aa70676B158ed70a70BB",
    "0xEFCC4C68e1dDFaA4f0FA3a7479F0fB082f96A56b",
    "0xd7EAe9fe9152317ee0542558836Cb45FC5aF2D39",
    "0x6efF21c2BFDf83a33aa690fe3DB17Fc672244907",
    "0xD20Ce27F650598c2d790714B4f6a7222B8dDcE22",
    "0x0a690B298f84D12414F5c8dB7de1EcE5a4605877",
    "0x3FcDDB0dd912e6f68927B448546cCE99F22A3e31",
    "0x6E02e27F03Ae982a3De019BCdB01AeF3CA8a91e0",
    "0x6186290B28D511bFF971631c916244A9fC539cfE",
    "0xa06EaF7D65057d0B6C3Ee8f0197f94F61BBdFAb1",
    "0xeB1c22baACAFac7836f20f684C946228401FF01C",
    "0x1468Ab4AF0c76186c071e07f282bf11E4af37D5d",
    "0x442DCCEe68425828C106A3662014B4F131e3BD9b",
    "0x760e7B42c920c2a0FeB58DDD610c14A6Bdd2Ebea",
    "0xD31ae1707fFF96401f716fbdE73AF6687E481631",
    "0x1e32a859d69dde58d03820F8f138C99B688D132F",
    "0x96e3BAA591C9Cf4d5f44dd44D02A3d4cE9290ac2",
    "0x31fBa23c1774Ab6f4b6CAe78101B72c34Cf42e42",
    "0xf3860788D1597cecF938424bAABe976FaC87dC26",
    "0x639BB215b1B243561A9F19c13A1dB3DB0919Fd60",
    "0x4d1572Ea399cfcb0a4b25B364dF2c5ba68697e18",
    "0x8abFcAA233Ff45619543f4E10532C2B3292d0c6d",
    "0xa25803ab86A327786Bb59395fC0164D826B98298",
    "0x04c1c15a1554891fb2dD7C4939e7d95d06eE253F",
    "0xa081AE42802E0Be86f5A6dD9274ADf50C9986a1D",
    "0x2FE4b878E1fD34e7EC449E7521B8333495608805",
    "0x0545457833F91042e0390ff14F7DA9d953e4C7F2",
    "0xEE05F658E18eb04D250f829b1920c2FbF6907E27",
    "0x6b4331048c411795a89D54484E3653107D58a64E",
    "0xe75B05cf7F73C2808c711D38488Cc8e0186d259E",
    "0xD1bEA81DD97d4fCebc5b25686bdCa04DEFf3991F",
    "0x50664edE715e131F584D3E7EaAbd7818Bb20A068",
    "0x7Bc2031cc88E61fD293A70e3C15EdC0d8d41A846",
    "0x3753FA57980fd180655c281158E428Ca994d6dfA",
    "0x9482C72Cb018eE03d8c23395038B510ED4e6040C",
    "0x203Bc267a4657ae5EE774Eb35cec32FAa2C0bC1f",
    "0xDFc05563F947718CA9EBd9E685022a200A5075C8",
    "0x4e39795874922b61CF3a6Adb3a85d52fa736ECeB",
    "0x7B1d4f3602F0FE16C4F611E124d3D7B307245Ba8",
    "0x6f35B0Cfc58Eb1e21eeF8a439BbB0cE4C929d32a",
    "0x59bdFB381CA2080D0D042903e776D3DCb548050A",
    "0x35001A8Bdb3a224D05F086094c12fd4c9009986D",
    "0x5C57abD3548b87Ef9bAbEa37ed3abD51fad523a3",
    "0x1fAfa69CfbE020063fE4376Fca3ECC4ED2b6936d",
    "0xc08bE15e7Aadd07a9882Aa710df29B7331aa9334",
    "0xa4Ba53ef39037AF3Cc1b916F44541d6Af4bEEaae",
    "0x83506d12acA4cdB36e8d03cda7611365142A29bf",
    "0x35937462A74D3C64b173a755A40C916E912a8Eb5",
    "0x092cfF73c77a9de794D25b0088DeD0e430733dbb",
    "0x300da191248a500b2174aeD992d6697BF97F9139",
    "0x8Dbbca57Ea56290Efa14D835bBfd34fAF1d89753",
    "0xd0Fd6D1C05b15F5B141253F13855c86cC3E655B8",
    "0x2E0D63fFCB08eA20fF3AcDbB72dfEc97343885d2",
    "0x1B2965dC3B1697DD10b7126EC7393d79cdA2cF91",
    "0x3295df41A2F288Da03818aE32565E1599f1B2Eee",
    "0xf6Bc89cc57e33c4e8053C4d8e63b70bf8C47e1A1",
    "0x8d33a9656F3cc17EB8aC5744D1EC1f864556B235",
    "0x36A5Bc205DF1ED65C86301022cfc343a6ce546ff",
    "0xF36972BBFf0Bb63dBc8F2C8A93625647b32e024b",
    "0x3c2B4bDCD2F742C55186fc599Cb733a127E2b8ab",
    "0x606C3Af5cc0bF4afc6AFD1010E8Fb424593Eb9fB",
    "0xC40dF87e16339f21fBB2E59FB38bF2A957A16FfD",
    "0x165C75A2e4566257a06805F6B0A3733DD9dDF407",
    "0x90e5aa59a9dF2ADd394df81521DbBEd5F3c4A1A3",
    "0x432DcBdA06e8B296CA29705572D7cb6315Ed8Bed",
    "0xE31c0a63B4509eDE74A80cBE75b8f93B2D3B0aE9",
    "0xe4edc2790F4034e1AE67a6fD7f55039Bb2A295d4",
    "0x5eA12341d073Ec5a1226b85f0478413A19081535",
    "0x38e71c42Dbee9b368f93c1278b8cE0b4AaC44b29",
    "0x45d28aA363fF215B4c6b6a212DC610f004272bb5",
    "0x96ff1d6B5E9ca15f9E61b7E2130599005144fb28",
    "0xf4fF19C30c98533fd6D3cEcF09b3d6802e470dD0",
    "0x6B67623ff56c10d9dcFc2152425f90285fC74DDD",
    "0x01e0C7b70E0E05a06c7cC8deeb97Fa03d6a77c9C",
    "0x2D1547D6C43Eb8185379D11Cf1A6dDAf2780B62A",
    "0x91E371C3CD3Aa81aF27b1602D4d8cf9D81ec5a90",
    "0xfD22004806A6846EA67ad883356be810F0428793",
    "0xdCDb88F3754B2841093D9348A2D02DF8cf06314C",
    "0xF9C298545dA80df7767C4e5a9296a071F13702B8",
    "0x0e0AeF02266624b7C2693cbE5288EE6cE64a7a4A",
    "0xDf5cE70CFE1ADDBC9Fb26b5952d9d600Dfe3f628",
    "0xbe548576d6795b011991F7c5d1A5eefb631a9aEA",
    "0x4e012D25472225f3385baa43ED64DfEC5885c51d",
    "0x1C41D8667E815451c6EfC242367cd15F0a4564DD",
    "0xAD0BCcb7FA9a1e77b9Bf347E177d4C08cFF1E1F1",
    "0xfeb53EE5B49D472233A491cbf0E4Dc0d356F9234",
    "0x4dc2E00C88b8C9E3c2BF090D7854770640dAF27f",
    "0x202eCa424A8Db90924a4DaA3e6BCECd9311F668e",
    "0xD387A6E4e84a6C86bd90C158C6028A58CC8Ac459",
    "0xF134c8f76214c500dcDC9188A21bD405CdAAf4c8",
    "0xBc6bcCeDd927Bc51C128C9355dA735869a5dC280",
    "0xEc7641e298aF02c19171451381C570327389b0c2",
    "0x0d41F957181E584dB82d2E316837B2DE1738C477",
    "0xC87C5A6702aBd6eB76130cd5ac4d1a837Fe9d7F7",
    "0xB3E08599AC57666BE68dBB3D311B9C607900A83b",
    "0x8F903cFC0Af3C2EC0d872c57538AF5e071544a57",
    "0x11d91151f54d4Edc390BA4Fc650aFdaEf4b676EE",
    "0x0481ac68Cc3CB70f71C537C9CC14a6DDc29881B1",
    "0x15332586e0d34E9F8E8B28A5F82Fa1d9175491A2",
    "0x22aD49d3E1F81A2A626E2DF64Fbdabe1536CaB07",
    "0xE84c5c241eD5e88b10AF63436b99E3600ba9A041",
    "0xC52650d88793Cf5fc0295BEE9aE49284e67fEcB1",
    "0xdf67544b82971A823Ba1a3fA1D243A9eEa392eB0",
    "0xf0D6999725115E3EAd3D927Eb3329D63AFAEC09b",
    "0x15EF584bDcD03AAEA40e567C70C393361432bcF4",
    "0x0C54a765C77A88D9CdA0BD7ad9B10422848D92Ef",
    "0x03D1a11f5e98A02EcD31962dc787000451E0c1d2",
    "0x581b3d2CdCd3911d9Bbf503550C3f6C1f3c997b1",
    "0x7ccd2EE72a75F7e4776f598c1Be11A119fD8d191",
    "0x4613739e5BCea4730F2a4983CE2100432415b01B",
    "0x9AD85d7C0f7eFF3D0718C3A3A032557558F7048b",
    "0xB02365Dc1A28D82cC454D05a765fEEe31ccC2B00",
    "0x2Debdf4427CcBcfDbC7f29D63964499a0ec184F6",
    "0x2F28c719768f71ad3657463f230292A0b63b65d0",
    "0x1c90F4F680e942c42B0818c77f7A6804d339e89b",
    "0x4eddDD1B1FdD851DC739eCd54Fbb71fD6B01ffd0",
    "0x8EE376DE530fB9a734DF676e7E4342B48355F483",
    "0x44a3CCddccae339D05200a8f4347F83A58847E52",
    "0x795cf8ddC20BbB4cFC7EEbA127434e1c3cDdA92E",
    "0xCCe7236CD6f2d60BBA509220De179f701AD2A7fd",
    "0x5B11FF372D7da5968E474a023A566F17F948a81D",
    "0x2bD9c24FDf18ff40dD05FBc6240D6fa5feA5457B",
    "0x5d8bff9300B6944cb2a2B88AE36fBAF8EE820afa",
    "0xFa70E93F0FC76a357e9447b895FDB19A879174d6",
    "0x340DC2e57d64e4f8a7010eB347fD1Ca1b18bF935",
    "0xE2E58b5BEc8F79c7a681C9efBeC12b3ECe7A76fd",
    "0xCCB77c3e5c60Cf9A30fE1a83827C0CDD8e09991f",
    "0x789fE157d0EDB355BbE13DE1Cf1f33583c11c0BE",
    "0x0403542A67116b628fe61beb3acb9262935aF0a0",
    "0xb8c50Ae12576069aA5EF8304356957EFc12D4f38",
    "0x614b89F072eA263a9387460963142e73548FBAF1",
    "0xC67c60cD6d82Fcb2fC6a9a58eA62F80443E32683",
    "0x68A7Ac13477AAD590982293FEEeb786A00276cF2",
    "0x8E59F2A9d779815E7Db9a066D50f5859c2596D3c",
    "0x4322AA46ffEd67809862Da613725728e2fb8eAe3",
    "0x55A0730A844727BD694FD8f938a456158bE0F8A6",
    "0x4E84a91a450aeEF23F0670Fb486d55b1d85A8dD0",
    "0xbd9b7373aAc15d9A93c810dF3999343f4Fe1eD88",
    "0x02245C0A611064ED72Cf9610EeaCa045fF8aeA39",
    "0xF70217ab5f4BE94a0c499984e40C898B4653f201",
    "0x324f6ead99AeDa8b0083a4445E8DF08a342E22e1",
    "0x93730CE5Ac9de1DB9F3FBb73bF522a690629CCEc",
    "0x2B3Ab8e7BB14988616359B78709538b10900AB7D",
    "0x2009a752a50D3CDe486d7b5921944377B729E747",
    "0xE4160a3bEf14DFDc0Cf49D9dCECd16FfE441DE91",
    "0xF14afA6a2960A3E79A9A1D617405907f67A7F9d4",
    "0xaEda7E9CB7C80F828A4FA1e3C23E679eC3b4B57a",
    "0x9Ca1D84259F19bAcbFd89b3f5DD018C162bc7C53",
    "0x3D5f1ad033C4E7C6A6d433d50d694c488dcAebCF",
    "0x3Bd77B00f02C8BcfF586C565E2c5e6B6c5878EC3",
    "0x20Ee31efB8e96d346CeB065b993494D136368E96",
    "0x01C2e7b1de06DA53Bd0EC82fdB59E5767b8c6dA1",
    "0xcC87Ab42fc4aCE2a68BFC1E28D7dDd578273035a",
    "0xe64DdADBDBD11b21112107Ac2d0cfB978bdaCeA7",
    "0x9b180d1401E35652A82C7BB37c7a754e48310D93",
    "0x1C5EB68630cCd90C3152FB9Dee3a1C2A7201631D",
    "0xaF2E919B59734B8d99F6Bc1f62Dc63d6519d14BC",
    "0xE5499A32BBFEdD563f87c6FCB05d5EB0333aC996",
    "0x60106c53a263A997489BaefDcccF589A0Ef3C7f3",
    "0x5d32D63D159a206f12A1F7E378b6fEf324766cEd",
    "0x6513B753670786367922fd32EfbB6Ce18abd3041",
    "0x14df40586e0F2Ca5CA222DB0dAc8Df2f4C5b1AAb",
    "0x3433faf996A75d642A1E8DC82B8d70381E252881",
    "0x592c6C12c6cF71722880b9263e93B35FF7952CC2",
    "0x66c3d1948EdB3ef275DD7b7101788196E22a372D",
    "0x3cAeAc7FF83B19f4D14C02CDa879c4740B4F0378",
    "0x2e083206FF94Af9932457b556B983f01abc4F3e6",
    "0xeF2e4d2644d77cD0276eDdfd924E4d021D0112FF",
    "0xC1D95E3314DB55f0cFbefE7A5F3055b2B40b4A36",
    "0xa40B66e14FA9Fc3992Dc29B4179D21eE0909AF02",
    "0x303052148A78A692d7Aa89bAd454F3e91785488D",
    "0xf06269C391c6e73031B50dd1bE123b6Af70fd62a",
    "0xE78D15ea83675cFb6c087ebbF8fE44E200cacC31",
    "0x7cc21109E4CDFBa27ADe441129D52CAc73A395D1",
    "0x162f3a373A8605Fd0dA52b1b6D44dc8BCc97F0AA",
    "0xCCE1F0b0B6DdF473AFA9624808D45568d01F43FA",
    "0x752184A2069c35f03d0216Ba806013693740B285",
    "0xCF330213906bf2E5dBb7983BAbb7f7b47f3dB03c",
    "0x49bf3387494bfc8918BADdb04C52Bc14480b0dbb",
    "0x6C0aff3a716292F37c79bc726964941f1D8eE819",
    "0x819e9B46756176838796454a3719f346cB984689",
    "0xF0F225E01cf084d3C246Ad595Ab60d0D4F5b4c78",
    "0x7361265D31d5B6bBfC2FABe5aE2E2865f1B33245",
    "0x17476d0Ed31f81d95b5ba8960b2D0b4dE4675e64",
    "0x15e318e1019cEd7fD87da98799c7c39a998121C4",
    "0xA2924f9cbb4d45E2D8E435272a4869b072bDff74",
    "0x05DcE0515B61b110894ced943543836E698E30ec",
    "0xed97d68EaAb2fb3f72363906d54D267DD5F85C21",
    "0x6cecA7911c1A4dd84451716B698995324609aD48",
    "0xe01A97FC4607A388a41626D5e039FDEcbfC6ACd9",
    "0x94eF50aFAc9c04572813F36Fb8D676EB400de278",
    "0x2e9BEC39D3Da08f17ED9F0bfaAEff5aCd4938383",
    "0x6A9eBf94C430f3b680c26fafaA94eAF100143AC6",
    "0xfB6Cd6e84FEf8A0dB992bF6371ED90801b88bb1b",
    "0x372443ecd48728D50a628DD7991a9656B5F73e0d",
    "0xCD486efdDBCA4D4Ac9504F4272a7148376B36e94",
    "0xb6a78170ADF6E57dEDF29313230d86dccf68D62A",
    "0x3Cf00cE7083958b7B0c1C1eA3a79FDDBb03b41A6",
    "0x46b2bD5C888E6d57a839c559FD6076F2c15A8cB1",
    "0x713f90407fc49f0BC59A6661C23FB67692a80E6d",
    "0xee402489D83e2B22D496910F8C810D35a3Ad7B25",
    "0xb616D4653E4fc0398d600EAA400e1aFee05774FD",
    "0xdf1B02672d19909F80805AA3b217b8C9cD704D60",
    "0x20335C504A4f0D8Db934e9f77a67b55E6AE8e1e1",
    "0x3291463CcbAC75a2F05AeBDB989173314715A75F",
    "0xFb054de87c048fE9f9D859afE6059d023529E0d8",
    "0x0F18C529B51851079b348BcFfD1A45C9426Fa65D",
    "0x91341a8FA3c927731b18Cd76e861F6b4342C23Bd",
    "0x407530d583c17e858B143940701F21B01CB73D1B",
    "0xb6f7a92CD624159BF150a1fd2Ba584700A8409DB",
    "0x5C8e99481e9D02F9efDF45FF73268D7123D2817D",
    "0xB09861246e4149AFa18581449C0bb6D4F56bfee3",
    "0xb19810efBef1fAfDe517f0794246a973a5568166",
    "0x9Ef13e6bFa6d38065207b5AAf757D124c25fB294",
    "0xa2cfDFD1a75832e4cf87Bb9c0C9d705aB2E63ec9",
    "0xb14bE0a9f67eF3E8bBf44CfC516aD17D186B3Aa2",
    "0xa80be8CAC8333330106585ee210C3F245D4f98Df",
    "0xda0691163e9BEAE3745f6584c5ab5fEF3D1276eB",
    "0x85c0c09570C955F6e07522E9ad6041716CbBb7fE",
    "0x1Da98aa4FaEFB6eC93cC1bA6AdcFB59c8aF51152",
    "0x8CE5C56c0BEDfB5303b8746225605AA61F057200",
    "0xf159190708d738d36b395971a7367CDd4e9aC60f",
    "0x11a564620eB72f4Fd94be93C62f811f5D07c6Fc0",
    "0xb789221616C7be137f33D545758F5510591D725E",
    "0x74395e48161536aaEBAA510455C3983188c317e8",
    "0x028026bc32D39b7550203d8aF76c9Db40c900c82",
    "0x386A777880035408e0eC0b53eCbE96D2F6D1A4f4",
    "0xB48037BD8eeb113501D7e6690beB44438d5603B5",
    "0x801971e751bFc761F5c90282F0f1dE1DfeDab214",
    "0x6436564b0D807fDB3E2bBC5c4F0aF44E1DDbD67F",
    "0x2B97b81A3edC9107156C6defbbd7a3a2e96A0Fd2",
    "0xA2b3b5C4aB5F3D3aFdD2f73352a852A6E8c4df65",
    "0xB72f37f5bC3EFeA93904883CD609B14fB13196B3",
    "0x618040E700bf5217740af504C926e041B4568b9E",
    "0x5232a33E75Db149E6FdD9D0616f49f97FdA1c466",
    "0x46914C794A7e0f5776405EF11c66Ec1b197414d5",
    "0x765c0E040B294d7e3607373998F2bBb7259673a7",
    "0xaa584127b91100DdE6b52228C28848A7B1D059c9",
    "0xbE694be8e812ae1a99443b32041142C36597A772",
    "0xb42573ddb86b68429C8f791ED0F2B79d2ca95588",
    "0xcaF6Fd54fB74C95B28F5e94952ca21B46E4071cc",
    "0xF930b0A0500D8F53b2E7EFa4F7bCB5cc0c71067E",
    "0x10D8f7259b0BdCbf73Cf965e95640d4B9276b543",
    "0xA32bFfFdB0431EC74e5C4Fb292F33D12B642421C",
    "0x2C0e519Cc4d8Af8046F26C0498a6942Ed0aab53E",
    "0xC2e7C3a5675EE1308D56f5E4fD614007e0fcc63b",
    "0x9224bbB4E0fBE2f2f8fab55DEbC41Eb21fDfB804",
    "0x14Aa7CAFb8871cAFA3E02688B66150a8EC338579",
    "0x632F7f3Da8e705631f83d3FceAed8EDc4E809D31",
    "0x89F9858059D05010503b8319743d6F6259ef23E5",
    "0xA158FFb97Cc5b65c7c762B31D3E8111688ee6940",
    "0xFAf9f63bAf57b19cA4E9490aaab1edE8b66Cc2b5",
    "0x09d8270a1dE38b53df1f47DEC27F377Ce145115c",
    "0x1Ccc5914dad16B432644F832890d8Cc40Df72A2D",
    "0x002e843f85521c4e48e667185663902a7A3a9C10",
    "0x4ce73cC57943602dF15fCCF1EE291c5d67D38326",
    "0x651c95DF5081f7df71cc5E88F0C03D799ad7ccb2",
    "0xC145488894Eb362C174F47039855bCF07D204671",
    "0xc23Bcc018f438854B98a3d4aef677A989855E707",
    "0xC42008F4AaDc0b487F214d738fCdCea1D0488e9D",
    "0xeAd90127D60F78f3e97781938418004Be1794EAe",
    "0x006a8a496889e9983A5256F34B608Ee9569ba319",
    "0xd6D5b6700742debb93229636626691e9c2bd088b",
    "0x3c9fb5EF18684639f84dA90773d554B77A38D7B8",
    "0xFB03492C724D152a50D233f6b0866BA6CD0f08cC",
    "0xe907C99288007b394E77267f9B77E1053cd55D29",
    "0x0fC6FE8Ed427ca62eF70151Be1ce3668004ED64b",
    "0x77087B6ff3dFE468D54Ba121b6b2cA7C4A99657f",
    "0x5d9f8ecA4a1d3eEB7B78002EF0D2Bb53ADF259ee",
    "0xED6192880eB200E391DB33312C4911295e11a5B4",
    "0xcBdcc4cc31a41868D60Ad7f2645999fe44430F8d",
    "0x5400DB91661Ad2b2a5664cAaF81C5Cae8AafF514",
    "0xEb89cBa91F7e94231Ec677504c45d9f1e3570512",
    "0x197D9f1370f2f7441a17cb035f33A6C19866D67E",
    "0x2BA9Dc7a4530996AB7A67c7538e8405b2CFd610D",
    "0x461AE8c33224ACeB7f1259095dd2a334Ad20f322",
    "0x48091F8BD6CdDf236a24c1Cecc183390377719Fe",
    "0x9011B9bE2b067562B732E09a25f39A59871DA8Eb",
    "0x3fE76C373E1ae6c3e5448DC4821a24d21F1eA3fB",
    "0x2FF08e693319ABa48Bf8eFB70d09B73B63b10e08",
    "0x481C7823E54f000AA278Bab4C001971177737aeC",
    "0x1111111AF3D020903F17AD7665004D9537EC2891",
    "0x7530566D47eadAb0BB93d560b9c80fc350871d46",
    "0x0901E9aFEd4AEa46e5A809B477d30cf9cE0E62B5",
    "0x73e20E65B95D02b2D7314159A320D29f7486D828",
  ];

  const merkleTree = new MerkleTree(elements, keccak256, {
    hashLeaves: true,
    sortPairs: true,
  });

  const proof = merkleTree.getHexProof(keccak256(address));

  return proof;
};

export const isEligibleWhitelist = async (address) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const proof = await createMerkleTree(address);

  const message = await window.contract.methods
    .isEligibleWhitelist(proof, address)
    .call({ from: address });

  return [proof, message];
};

export const mintNFTWhitelist = async (address) => {
  const connectedNetworkId = await window.web3.eth.getChainId();

  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const eligibleObject = await isEligibleWhitelist(address);
  let eligible = eligibleObject[1];
  let proof = eligibleObject[0];
  //Address is whitelisted
  if (eligible) {
    let nonce = await web3.eth.getTransactionCount(address, "latest");

    //Set up your Ethereum transaction
    const transactionParameters = {
      to: contractAddress, // Required except during contract publications.
      from: address, // must match user's active address.
      nonce: nonce.toString(),
      data: window.contract.methods
        .mintWhitelistGeneralMembership(proof)
        .encodeABI(), //make call to NFT smart contract
    };

    //Sign the transaction via Metamask
    try {
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      return {
        success: true,
        status: "View your transaction on Etherscan",
        txLink: `https://${
          connectedNetworkId === 1 ? "" : "rinkeby."
        }etherscan.io/tx/${txHash}`,
        txHash,
      };
    } catch (error) {
      return {
        // success: false,
        // status: "😥 Something went wrong: " + error.message,
        // txLink: "",
      };
    }
  } else {
    return {
      success: false,
      status: "Not eligible for minting",
      txLink: "",
    };
  }
};

export const getPolygonTransactionStatus = async (txHash) => {
  try {
    const response = await fetch(POLYGON_SCAN_API_ENDPOINT(txHash));
    const receipt = await response.json();
    return receipt;
  } catch (error) {
    return null;
  }
};

export const switchNetworkMeekicks = async (provider) => {
  if (!window) return;

  if (!provider) {
    throw new Error("Wallet must be connected!")
  }

  const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";

  try {
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: isProduction ? "0x1" : "0x4" }],
    });
  } catch (error) {
    throw new Error(
      `${isProduction ? "Mainnet" : "Rinkeby"} Network is required to claim Meekicks. Please try again.`
    );
  }
};

export const switchNetworkRinkeby = async () => {
  if (!window) return;

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x4" }],
    });
  } catch (error) {
    throw new Error(
      "Rinkeby Network is required to claim token. Please try again."
    );
  }
};

export const switchNetworkMatic = async (provider) => {
  if (!window) return;

  try {
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: MATIC_NETWORK_PARAMS.chainId }],
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [MATIC_NETWORK_PARAMS],
        });
      } catch (error) {
        throw new Error(error.message);
      }
    } else if (error.code == 4001) {
      throw new Error(
        "Matic Network is required to set delegate. Please try again."
      );
    } else {
      throw new Error(error.message);
    }
  }
};
