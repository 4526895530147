import { css, StyleSheet } from "aphrodite";
import DarkBlueRect from "../assets/Dark_Blue_Rect.png";

export default function ClaimPageShell({ children, className }) {
  return (
    <div className={css(styles.claimContainer, className)}>{children}</div>
  );
}

const styles = StyleSheet.create({
  claimContainer: {
    display: "flex",
    position: "relative",
    background: `url(${DarkBlueRect})`,
    width: 600,
    maxHeight: 500,
    padding: "30px 40px 15px",
    color: "#fff",
    textAlign: "center",
    boxSizing: "border-box",
    margin: "100px auto auto auto",
    zIndex: 3,
    flexDirection: "column",
    "@media only screen and (max-width: 767px)": {
      width: "90%",
      maxHeight: "calc(100vh * 0.75)",
      // maxHeight: "unset",
      fontSize: 40,
      padding: "20px 30px 7px",
      marginTop: 16,
    },
  },
});
