import { useEffect, useRef, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import video from "../assets/meekicks-trailer.mp4";
import { Colors } from "../utils/colors";
import { useNavigate } from "react-router-dom";


function Header() {
  const navigate = useNavigate();

  const [isMute, setIsMute] = useState(true);
  const videoRef = useRef();

  useEffect(() => {
    // start vid at low volume for good UX
    videoRef.current.volume = 0.1;
  }, [])

  function toggleAudio(e) {
    e && e.stopPropagation();
    setIsMute(!isMute);
  };

  function navigateToMeekickClaim(e) {
    e && e.stopPropagation();
    navigate("/meekicks")
  }

  return (
    <div className={css(styles.header)}>
      <div className={css(styles.videoContainer)} onClick={navigateToMeekickClaim}>
          <i 
            className={css(styles.audioIcon) + ` ${isMute ? "fas fa-volume-mute" : "fas fa-volume"}`} 
            onClick={toggleAudio}
          />
        <video
          ref={videoRef}
          controls={false}
          autostart="true"
          autoPlay
          loop
          muted={isMute}
          src={video}
          type="video/mp4"
          width={1440}
          height={810}
          className={css(styles.video)}
        />
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  header: {
    position: "relative",
    // marginBottom: 56,
  },
  videoContainer: {
    width: "100%",
    paddingTop: "56.25%",
    height: 0,
    position: "relative",
    cursor: "pointer"
  },
  video: {
    width: "100%",
    height: "100%",
    marginTop: -80,
    position: "absolute",
    top: 0,
    left: 0,
    "@media only screen and (max-width: 767px)": {
      marginTop: 0
    }
  },
  audioIcon: {
    color: "#fff",
    fontSize: 30,
    position: "absolute",
    right: 15,
    top: 70,
    zIndex: 1,
    filter: `drop-shadow(0px 0px 5px ${Colors.darkNavy()})`,
    cursor: "pointer",
    ":hover": {
      color: Colors.uiTeal()
    }
  }
});

export default Header;
