import { Colors } from "./colors";
import DarkBlueRect from "../assets/Dark_Blue_Rect.png";

export const BASE_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : process.env.NODE_ENV === "production"
  ? "https://backend.meebitsdao.com"
  : "http://localhost:5001";

// MEEBITS MAINNET CONTRACT
export const MEEBITS_CONTRACT = "0x7Bd29408f11D2bFC23c34f18275bBf23bB716Bc7";

export const AWS_MEEKICKS_BASE_URL =
  "https://meekicks.s3.us-west-2.amazonaws.com";
export const AWS_PIXELMB_BASE_URL =
  "https://meebitsdao-pixel-meebits.s3.us-west-2.amazonaws.com";

export const MEEBITSDAO_BANNER_IMAGE_URL =
  "https://blockblock.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F7fb9b9b2-278b-4b79-9ca2-caf244627a9c%2F1_5PGVeK69h89PG9bGCXk-9Q.png?table=block&id=944c95dc-bf1c-4467-84e3-16af74dbf92b&spaceId=f2949f86-9133-47f1-b675-11a0fdd7b72e&width=1710&userId=&cache=v2";

export const LS_MINTING_TRANSACTION_KEY = (walletAddress) => {
  return `meebitsDAO_user_minting_transaction.${walletAddress}`;
};
export const LS_DELEGATION_TRANSACTION_KEY = (walletAddress) => {
  return `meebitsDAO_user_delegation_transaction.${walletAddress}`;
};

export const MONTHLY_MINTABLE_TOKEN_DESCRIPTION =
  "Tokens are received for being a general member of the MeebitsDAO. You are able to claim up to (1) mVOX token each month. Unclaimed tokens do not rollover into subsequent months.";

export const POLYGON_SCAN_API_ENDPOINT = (txHash) => {
  return `https://api-testnet.polygonscan.com/api?module=transaction&action=gettxreceiptstatus&txhash=${txHash}&apikey=`;
};

export const MATIC_MAINNET_PARAMS = {
  chainId: "0x89",
  chainName: "Matic Mainnet",
  rpcUrls: ["https://rpc-mainnet.maticvigil.com"],
  nativeCurrency: {
    name: "MATIC Token",
    symbol: "MATIC",
    decimals: 18,
  },
  blockExplorerUrls: ["https://explorer.matic.network"],
};

export const MATIC_TESTNET_PARAMS = {
  chainId: "0x13881",
  chainName: "Mumbai",
  rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
  nativeCurrency: {
    name: "MATIC Token",
    symbol: "MATIC",
    decimals: 18,
  },
  blockExplorerUrls: ["https://mumbai.polygonscan.com"],
};

export const MATIC_NETWORK_PARAMS =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? MATIC_MAINNET_PARAMS
    : MATIC_TESTNET_PARAMS;

export const MODAL_STYLES = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: `url(${DarkBlueRect})`,
    border: `1px solid ${Colors.darkNavy()}`,
    color: Colors.white(),
    boxShadow: `0px 0px 5px ${Colors.black(0.3)}`,
    // padding: "20px 30px"
  },
  overlay: {
    background: "rgba(0,0,0, .7)",
    zIndex: 100000000000,
  },
};
