import { css, StyleSheet } from "aphrodite";

import ContentBlock from "../ContentBlock";
import MeebitContainer from "../MeebitContainer";
import Meebit from "../../assets/Meebit_16844.webp";
import cyan from "../../assets/cyan.webp";
import sandbox from "../../assets/sandbox.webp";
import TitleHeader from "../TitleHeader";
import ActionButton from "../ActionButton";

function Sandbox() {
  return (
    <div className={css(styles.section)}>
      <TitleHeader title={"The Sandbox"} style={styles.titleHeader} />
      <ContentBlock
        content={
          <div className={css(styles.contentBlockContainer)}>
            <img src={sandbox} className={css(styles.sandboxImg)} />
            <div className={css(styles.button)}>
              <ActionButton
                text="Visit"
                style={styles.actionButton}
                href="https://www.sandbox.game/en/map/?currentX=-1921&currentY=2273&zoom=4.5&liteMap=false&x=-114&y=198"
              />
            </div>
          </div>
        }
        blockStyle={styles.blockStyle}
      />
      <MeebitContainer meebit={Meebit} background={cyan} left />
    </div>
  );
}
const styles = StyleSheet.create({
  section: {
    display: "flex",
    flexDirection: "column",
    marginTop: 96,
    alignItems: "flex-end",
    position: "relative",
  },
  blockStyle: {
    marginRight: 287,
    padding: 22,
    height: "unset",
  },
  contentBlockContainer: {},
  sandboxImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  button: {
    marginTop: 16,
  },
  actionButton: {
    margin: "0 auto",
    fontSize: 24,
  },
  titleHeader: {
    width: 755,
  },
});

export default Sandbox;
