import { css, StyleSheet } from "aphrodite";

import TitleHeader from "../TitleHeader";
import ContentBlock from "../ContentBlock";
import MeebitContainer from "../MeebitContainer";
import Meebit from "../../assets/Meebit_3.webp";
import cyan from "../../assets/cyan.webp";

const ABOUT_TEXT_ONE =
  "MeebitsDAO was born out of a single ambition: to create a vehicle for funding innovative projects that will develop the ecosystem around Meebits.";
const ABOUT_TEXT_TWO =
  "With the launch of the General Membership program, we are taking the next step in seeing this vision through and we can’t wait for you to join us.";
const ABOUT_TEXT_THREE = "Mint your General Membership token below.";

function About() {
  return (
    <div className={css(styles.section)} id="about-section">
      <div className={css(styles.aboutSection)}>
        <TitleHeader title={"about"} alignRight={true} />
        <ContentBlock
          content={
            <div className={css(styles.aboutContentContainer)}>
              <p>{ABOUT_TEXT_ONE}</p>
              <p>{ABOUT_TEXT_TWO}</p>
              <p>{ABOUT_TEXT_THREE}</p>
            </div>
          }
          blockStyle={styles.blockStyle}
        />
      </div>
      <div className={css(styles.meebContainer)}>
        <MeebitContainer meebit={Meebit} background={cyan} />
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  section: {
    display: "flex",
    position: "relative",
  },
  aboutSection: {
    marginBottom: 152,
    zIndex: 2,

    "@media only screen and (max-width: 767px)": {
      zIndex: 4,
    },

    "@media only screen and (max-width: 1023px)": {
      zIndex: 4,
    },
  },
  blockStyle: {
    marginLeft: 286,
  },
  aboutContentContainer: {
    paddingTop: 88,

    "@media only screen and (max-width: 1023px)": {
      paddingTop: 0,
    },
  },
  meebContainer: {
    // position: "relative",
  },
});

export default About;
