import { css, StyleSheet } from "aphrodite";

const TooltipContent = ({ img, title, text }) => {
  return (
    <div className={css(styles.tooltip)}>
      <div className={css(styles.topSection)}>
        {img && (
          <div className={css(styles.photo)}>
            <img src={img} className={css(styles.img)} />
          </div>
        )}
        <div className={css(styles.info)}>
          {title && (
            <div className={css(styles.title)}>
              {title}
            </div>
          )}
        </div>
      </div>
     {<div className={css(styles.text)}>{text}</div>}
    </div>
  );
};

const styles = StyleSheet.create({
  topSection: {
    display: "flex",
    alignItems: "center",
  },
  img: {
    width: 40,
    height: 40,
    objectFit: "cover",
  },
  photo: {
    marginRight: 16,
  },
  title: {
    textAlign: "left",
    fontWeight: 800,
    fontSize: 14,
    fontStyle: "italic",
    maxWidth: 150,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tooltip: {
    maxWidth: 326,
    padding: 16,
  },
  text: {
    marginTop: 16,
    textAlign: "left",
  },
});

export default TooltipContent;