import { css, StyleSheet } from "aphrodite";

function MeebitContainer({
  meebit,
  background,
  left,
  style,
  meebitStyle,
  backgroundStyle,
}) {
  return (
    <div
      className={css(
        styles.meebitContainer,
        left && styles.containerLeft,
        style
      )}
    >
      <img
        src={meebit}
        className={css(styles.meebit, left && styles.meebitsLeft, meebitStyle)}
      />
      <img
        src={background}
        className={css(styles.background, left && styles.left, backgroundStyle)}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  meebitContainer: {
    position: "absolute",
    left: "70%",
  },
  containerLeft: {
    left: "-15%",
  },
  meebit: {
    position: "absolute",
    zIndex: 2,

    "@media only screen and (max-width: 767px)": {
      display: "none",
    },
  },
  meebitsLeft: {
    left: "30%",
    "@media only screen and (max-width: 767px)": {
      // width: 200,
      left: -80,
      top: 80,
      height: 500,
    },

    "@media only screen and (max-width: 1023px)": {
      // width: 200,
      left: -80,
      top: 80,
      // height: 500,
    },
  },
  left: {
    transform: "matrix(-0.89, 0.45, 0.45, 0.89, 0, 0)",

    "@media only screen and (max-width: 767px)": {
      // width: 200,
      transform: "translateX(-800px) matrix(-0.89, 0.45, 0.45, 0.89, 0, 0)",
    },

    "@media only screen and (max-width: 1023px)": {
      // width: 200,
      transform: "translateX(-600px) matrix(-0.89, 0.45, 0.45, 0.89, 0, 0)",
    },
  },
  background: {
    transform: "rotate(27deg)",
    height: 954,
    position: "relative",
    top: -100,
  },
});

export default MeebitContainer;
