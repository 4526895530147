import {
  isEligibleMonthlyMint,
  checkDelegatedTokenCount,
} from "../utils/governanceTokenInteract";
import { getCurrentDelegate } from "../utils/delegationInteract";
import { checkGeneralMembershipTokenCount } from "../utils/interact";
import { mbbtCheckGeneralMembershipTokenCount } from "../utils/mbbtGeneralMembershipInteract";
import { BASE_URL, MEEBITS_CONTRACT } from "./constants";

export async function getEligibleMonthlyMint(walletAddress) {
  return await isEligibleMonthlyMint(walletAddress);
}

export async function getDelegatedTokenCount(walletAddress) {
  return await checkDelegatedTokenCount(walletAddress);
}

export async function getMembershipTokenCount(walletAddress) {
  return await checkGeneralMembershipTokenCount(walletAddress);
}

export async function getMBBTMembershipTokenCount(walletAddress) {
  return await mbbtCheckGeneralMembershipTokenCount(walletAddress);
}

export async function getUserCurrentDelegate(walletAddress) {
  try {
    const address = await getCurrentDelegate(walletAddress);
    const response = await fetch(`${BASE_URL}/delegates?search=${address}`);
    const json = await response.json();
    const delegate = json.results[0];
    return delegate;
  } catch (error) {
    return null;
  }
}

export async function getFounder(ethAddress) {
  const response = await fetch(`${BASE_URL}/delegates?search=${ethAddress}`);
  const json = await response.json();
  const founder = json.results[0];
  return founder;
}

