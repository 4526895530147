import Modal from 'react-modal';
import { css, StyleSheet } from "aphrodite";
import { useEffect, useState, Fragment } from "react";
import { Colors } from "../../utils/colors.js";
import DarkBlueRect from "../../assets/Dark_Blue_Rect.png";
import HyperLink from "../HyperLink";
import { toast } from 'react-toastify';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: `url(${DarkBlueRect})`,
    border: `1px solid ${Colors.darkNavy()}`,
    color: Colors.white(),
    boxShadow: `0px 0px 5px ${Colors.black(0.3)}`,
    // padding: "20px 30px"
  },
  overlay: {
    background: "rgba(0,0,0, .7)",
    zIndex: 100000000000,
  },
};

const TRANSACTION_CHECK_INTERVAL = 1000;

const ClaimSummaryModal = ({ txSummary, isOpen, closeModal, onSuccess }) => {
  const [txStatus, setTxStatus] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setTxStatus(null);
    } else {
      if (!txSummary) return;
      const { txHash } = txSummary;
      getTransactionReceipt(txHash);
    }
  }, [isOpen]);

  /**
   * Checks ETHERSCAN for minting transaction status (heartbeat interval)
   * @param {String} txHash
   */
  async function getTransactionReceipt(txHash) {
    try {
      const receipt = await window.web3.eth.getTransactionReceipt(txHash);

      if (receipt) {
        if (receipt.status) {
          // on success
          onSuccess && onSuccess();
        }
        setTxStatus(receipt.status);
      } else {
        setTimeout(() => {
          getTransactionReceipt(txHash);
        }, TRANSACTION_CHECK_INTERVAL);
      }
    } catch (err) {
      toast.error("Hm something went wrong!")
    }
  }

  const renderIcon = () => {

    if (txStatus === null) {
      return (
        <i
          className={css(styles.icon, styles.loadingIcon) + " fad fa-spinner-third fa-spin"}
        />
      )
    } else if (!txStatus) {
      return (
        <i 
          className={css(styles.icon, styles.errorIcon) + " fas fa-exclamation-triangle"}
        />
      )
    } else {
      return (
        <i 
          className={css(styles.icon, styles.successIcon) + " fas fa-check-circle"} 
        />
      )
    }
  };

  function renderTitle() {
    if (txStatus === null) {
      return "Minting";
    }

    return txStatus ? "Congrats" : "Error";
  } 

  function renderMessage() {
    if (txStatus === null) {
      return "Your transaction queued successfully!";
    }

    if (txStatus) {
      return "Your transaction was successful!"
    } else {
      return "Something went wrong..."
    }
  }

  return (
      <Modal
        isOpen={isOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <div className={css(styles.closeButton)} onClick={closeModal}>
          <i className={css(styles.closeIcon) + " far fa-times"} />
        </div>
        <div className={css(styles.content)}>
          <h2 className={css(styles.title)}>
            {renderTitle()}
          </h2>
          <p style={{ fontSize: 20, fontWeight: "bold" }}>
            {renderMessage()}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {txSummary.txLink && (
                <p style={{ fontSize: 18, fontStyle: "italic", margin: "5px 0" }}>
                  <HyperLink href={txSummary.txLink}>
                    {"Review your mint transaction here."}
                  </HyperLink>
                </p>
              )}
          </div>
          <p style={{ margin: 0 }}>
            {renderIcon()}
          </p>
        </div>
      </Modal>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer"
  },
  closeIcon: {
    fontSize: 24,
    color: "#FAFAFA"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px 20px 0 20px",
    minWidth: 400,
    "@media only screen and (max-width: 415px)": {
      width: "100%",
      height: "100vh"
    }
  },
  title: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontSize: 24,
    textAlign: "center",
    fontWeight: "bold",
    margin: 0
  },
  subtext: {
    fontSize: 14,
    fontWeight: 300,
    textAlign: "justify",
    textAlignLast: "center",
    paddingBottom: 15,
  },
  button: {
    fontSize: 16,
    margin: "0 auto",
    marginTop: 32,
    width: "fit-content",
    maxWidth: 306,
  },
  icon: {
    fontSize: 50,
    borderRadius: 100,
    margin: "20px 0 10px 0",
  },
  loadingIcon: {
    color: "#fff"
  },
  successIcon: {
    color: "#00F790",
  },
  errorIcon: {
    color: Colors.uiRed()
  }
});


export default ClaimSummaryModal;