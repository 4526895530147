import { css, StyleSheet } from "aphrodite";
import Loader from "react-loader-spinner";

const SpinLoader = ({ isLoading, height = 80, width = 80}) => {
  return (
    // <div className={css(styles.loaderContainer)}>
      <Loader
        type="Oval" 
        color="#00BFFF" 
        height={height} 
        width={width}
        visible={isLoading}
        // timeout={3000} //3 secs
      />
  );
};

const styles = StyleSheet.create({
  loaderContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 2
  }
})

export default SpinLoader;