import { css, StyleSheet } from "aphrodite";
import ActionButton from "../ActionButton";

export default function FounderUpdateSuccess() {
  return (
    <>
      <h2 className={css(styles.title)}>Thank You</h2>
      <p>Your profile has updated successfully.</p>
      <p>
        <i
          style={{
            color: "#00F790",
            fontSize: 50,
            borderRadius: 100,
            margin: "10px 0",
          }}
          className="fas fa-check-circle"
        />
      </p>
      <ActionButton text={"Back to Main Page"} style={styles.button} to={"/"} />
    </>
  );
}

const styles = StyleSheet.create({
  title: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontSize: 24,
    marginTop: -16,
  },
  button: {
    fontSize: 16,
    margin: "0 auto",
    marginTop: 32,
    width: "fit-content",
    maxWidth: 306,
  },
});
