import { css, StyleSheet } from "aphrodite";
import { useEffect, useRef, useState } from "react";
import ReactPlaceholder from "react-placeholder/lib";

// Components
import ActionButton from "../ActionButton";
import Delegate from "./Delegate";
import { DelegatePlaceholder } from "./DelegatePlaceholder";

// Utils
import { Colors } from "../../utils/colors";
import { BASE_URL } from "../../utils/constants";

export default function ChooseDelegate({
  setPage,
  setDelegate,
  userCurrentDelegate,
}) {
  const [delegates, setDelegates] = useState([]);
  const [delegateChosen, setDelegateChosen] = useState(null);
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const firstLoad = useRef();
  const searchTimeout = useRef();
  const noSearchResults = delegates.length === 0 && !isSearching;

  useEffect(() => {
    if (!userCurrentDelegate) return;
    setDelegateChosen(userCurrentDelegate);
  }, [userCurrentDelegate]);

  useEffect(() => {
    const getFounders = async () => {
      setIsSearching(true);
      const response = await fetch(`${BASE_URL}/founders${search ? `?search=${search}` : ""}`);
      const json = await response.json();
      setIsSearching(false);
      const delegates = json.results;
      setDelegates(delegates);
    };

    if (!firstLoad.current) {
      getFounders();
      firstLoad.current = true;
    } else {
      clearTimeout(searchTimeout.current);
      searchTimeout.current = setTimeout(() => {
        getFounders();
      }, 300);
    }
  }, [search]);

  const isButtonDisabled = () => {
    if (userCurrentDelegate && userCurrentDelegate.id && delegateChosen) {
      return userCurrentDelegate.id === delegateChosen.id;
    }
    return !delegateChosen;
  };

  return (
    <>
      <div>
        <div className={css(styles.title)}>Choose a Delegate</div>
        <div className={css(styles.subtext)}>
          Select a community member to represent you. You can change this at any
          time.
        </div>
        <div className={css(styles.searchBoxLabel)}>Enter name</div>
        <div className={css(styles.inputContainer)}>
          <input
            placeholder="SEARCH"
            className={css(styles.searchBox)}
            value={search}
            onChange={(e) => {
              setIsSearching(true);
              setSearch(e.target.value);
            }}
          />
          {search && (
            <i 
              className={css(styles.clearButton) + " fas fa-times-circle"} 
              onClick={() => setSearch("")}
            />
          )}
        </div>
        
      </div>
      <div className={css(styles.delegates, noSearchResults && styles.flexContainer) + " show-loading-animation"}>
        <ReactPlaceholder
          showLoadingAnimation
          type="rect"
          customPlaceholder={<DelegatePlaceholder />}
          ready={!isSearching}
        >
          {delegates.map((delegate, i) => {
            return (
              <div
                onClick={(e) => {
                  setDelegateChosen(delegate);
                  setDelegate(delegate);
                }}
                key={`delegate-${delegate.id}`}
              >
                <Delegate
                  delegate={delegate}
                  active={delegateChosen?.id === delegate.id}
                  className={[styles.delegateWrapper]}
                />
              </div>
            );
          })}
          {noSearchResults && (
            <div className={css(styles.emptyStateContainer)}>
              <i className={css(styles.searchIcon) + " far fa-search"} />
              No delegates matched your search.
            </div>
          )}
        </ReactPlaceholder>
      </div>
      <div className={css(styles.actions)}>
        <ActionButton
          text="Back"
          to="/claim"
          style={[styles.back, styles.button]}
        />
        <ActionButton
          text="Next"
          onClick={(e) => {
            setPage && setPage("success");
          }}
          disabled={isButtonDisabled()}
          style={[styles.button]}
        />
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  actions: {
    position: "absolute",
    bottom: -100,
    display: "flex",
    left: 0,
    width: "100%",
    justifyContent: "space-between",
  },
  button: {
    height: 70,
    width: 180,
  },
  back: {
    background: "none",
    border: "1px solid #0099F9",
  },
  searchBoxLabel: {
    textAlign: "right",
    fontSize: 12,
    fontWeigth: 100,
    fontStyle: "italic",
    color: Colors.uiTeal(),
    textTransform: "uppercase",
  },
  searchBox: {
    width: "100%",
    background: "#00386D",
    padding: 16,
    border: `1px solid ${Colors.darkNavy()}`,
    marginTop: 16,
    boxSizing: "border-box",
    fontStyle: "italic",
    fontWeight: 600,
    color: "#fff",
    outline: "none",
    textTransform: "uppercase",

    "::placeholder": {
      color: "#fff",
      opacity: 0.8,
    },
  },
  title: {
    margin: "0 auto",
    fontSize: 28,
    fontWeight: 800,
    fontStyle: "italic",
    textTransform: "uppercase",
    textAlign: "justify",
    textAlignLast: "center",
    "@media only screen and (max-width: 767px)": {
      margin: "unset",
      textAlign: "center",
    },
  },
  subtext: {
    margin: "15px auto",
    fontSize: 14,
    fontWeight: 300,
    textAlign: "justify",
    textAlignLast: "center",
    "@media only screen and (max-width: 767px)": {
      margin: "unset",
      textAlign: "center",
      padding: "15px 0",
    },
  },
  claimButton: {
    margin: "28px auto 20px auto",
    width: 320,
    fontSize: 18,
  },
  delegates: {
    display: "grid",
    gridGap: 16,
    padding: "16px 5px 16px",
    boxSizing: "border-box",
    gridTemplateColumns: "1fr 1fr 1fr",
    overflow: "auto",
    "@media only screen and (max-width: 767px)": {
      gridTemplateColumns: "1fr 1fr",
    },
    "@media only screen and (max-width: 630px)": {
      gridTemplateColumns: "1fr",
    },
  },
  delegateWrapper: {
    border: `1px solid ${Colors.darkNavy()}`,
  },
  lastElement: {
    marginRight: 0,
  },
  // empty state
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  emptyStateContainer: {
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  },
  searchIcon: {
    marginRight: 10
  },

    // input
  inputContainer: {
    position: "relative"
  },
  clearButton: {
    position: "absolute",
    right: 10,
    bottom: 15,
    color: Colors.white(),
    cursor: "pointer",
    ":hover": {
      color: Colors.uiTeal(),
    }
  }
});
