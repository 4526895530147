import { css, StyleSheet } from "aphrodite";
import { useEffect, useState, Fragment } from "react";

// Components
import ActionButton from "../ActionButton";
import HyperLink from "../HyperLink";

// Utils
import {
  LS_MINTING_TRANSACTION_KEY,
  LS_DELEGATION_TRANSACTION_KEY,
} from "../../utils/constants";
import { getPolygonTransactionStatus } from "../../utils/interact";
import { addToMetaMask } from "../../utils/governanceTokenInteract";

const TRANSACTION_CHECK_INTERVAL = 1000;

const TransactionSuccess = ({
  mintingSummary,
  delegationSummary,
  walletAddress,
}) => {
  const [txMintingStatus, setTxMintingStatus] = useState(null);
  const [txDelegationStatus, setTxDelegationStatus] = useState(null);

  useEffect(() => {
    if (!window) return;

    // set localstorage with the most recent transactions for users to review later
    if (mintingSummary.success) {
      localStorage.setItem(
        LS_MINTING_TRANSACTION_KEY(walletAddress),
        mintingSummary.txLink
      );
      getMintingTransactionReceipt(mintingSummary.txHash);
    }

    if (delegationSummary.success) {
      localStorage.setItem(
        LS_DELEGATION_TRANSACTION_KEY(walletAddress),
        delegationSummary.txLink
      );
      getDelegationTransactionReceipt(delegationSummary.txHash);
    }
  }, []);

  /**
   * Checks ETHERSCAN for minting transaction status (heartbeat interval)
   * @param {String} txHash
   */
  async function getMintingTransactionReceipt(txHash) {
    const receipt = await window.web3.eth.getTransactionReceipt(txHash);

    if (receipt) {
      setTxMintingStatus(receipt.status);
    } else {
      setTimeout(() => {
        getMintingTransactionReceipt();
      }, TRANSACTION_CHECK_INTERVAL);
    }
  }

  /**
   * Checks POLYGONSCAN for delegation transaction status (heartbeat interval)
   * @param {String} txHash
   */
  async function getDelegationTransactionReceipt(txHash) {
    const receipt = await getPolygonTransactionStatus(txHash);
    if (receipt) {
      setTxDelegationStatus(!!receipt.status);
    } else {
      setTimeout(() => {
        getDelegationTransactionReceipt();
      }, TRANSACTION_CHECK_INTERVAL);
    }
  }

  const renderTransactionString = (condition, message) => {
    return (
      <Fragment>
        {condition ? null : condition === false ? (
          <i
            style={{ color: "red", marginRight: 8 }}
            className="fas fa-exclamation-triangle"
          />
        ) : (
          <i
            style={{ color: "#fff", marginRight: 8 }}
            className="fad fa-spinner-third fa-spin"
          />
        )}
        {message}
      </Fragment>
    );
  };

  return (
    <>
      <h2 className={css(styles.title)}>Thank You</h2>
      <p style={{ fontSize: 20, fontWeight: "bold" }}>
        Your transaction queued successfully!
      </p>
      <p
        className={css(styles.subtext)}
        style={{
          fontSize: 12,
          maxWidth: 420,
          lineHeight: 1.3,
          margin: "0 auto",
          paddingBottom: 0,
        }}
      >
        {`Transaction times can vary depending on network traffic, 
        so changes will not always be reflected immediately.`}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            margin: "0 auto",
          }}
        >
          {mintingSummary.txLink && (
            <li style={{ fontSize: 14, fontStyle: "italic", margin: "5px 0" }}>
              <HyperLink href={mintingSummary.txLink}>
                {renderTransactionString(
                  txMintingStatus,
                  "Review your mint transaction here."
                )}
              </HyperLink>
            </li>
          )}
          {delegationSummary.txLink && (
            <li style={{ fontSize: 14, fontStyle: "italic", margin: 0 }}>
              <HyperLink href={delegationSummary.txLink}>
                {renderTransactionString(
                  txDelegationStatus,
                  "Review your delegation transaction here."
                )}
              </HyperLink>
            </li>
          )}
        </ul>
      </div>
      <p style={{ margin: 0 }}>
        <i className={css(styles.successIcon) + " fas fa-check-circle"} />
      </p>

      <ActionButton
        text={"Add mVOX to Metamask"}
        style={styles.button}
        onClick={() => {
          addToMetaMask();
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  title: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontSize: 24,
    marginTop: -16,
    "@media only screen and (max-width: 767px)": {
      marginTop: 25,
    },
  },
  subtext: {
    fontSize: 14,
    fontWeight: 300,
    textAlign: "justify",
    textAlignLast: "center",
    paddingBottom: 15,
  },
  button: {
    fontSize: 16,
    margin: "0 auto",
    marginTop: 32,
    width: "fit-content",
    maxWidth: 306,
  },
  successIcon: {
    color: "#00F790",
    fontSize: 50,
    borderRadius: 100,
    margin: "20px 0 10px 0",
  },
});

export default TransactionSuccess;
