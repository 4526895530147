import { css, StyleSheet } from "aphrodite";
import { useEffect, useState } from "react";

import LightBlueRect from "../assets/Light_Blue_Rect.png";
import { Colors } from "../utils/colors";
import { BASE_URL } from "../utils/constants";

const NavbarUserCard = ({ address }) => {
  const [name, setName] = useState("");
  const [img, setImg] = useState("");

  useEffect(() => {
    // fetch user data
    getUser();
  }, [address]);

  async function getUser() {
    const response = await fetch(`${BASE_URL}/founders?search=${address}`);
    const json = await response.json();
    const delegate = json.results[0];
    if (delegate) {
      setName(delegate.properties.name.rich_text[0].plain_text);
      setImg(delegate.properties.picture.files[0]?.file?.url);
    }
  }

  return (
    <div className={css(styles.userSummary)}>
      <div className={css(styles.avatar)}></div>
      <p className={css(styles.wallet)}>{address}</p>
    </div>
  );
};

const styles = StyleSheet.create({
  //temp placement. todo: move to own component
  userSummary: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background: `url(${LightBlueRect})`,
    height: 70,
    width: 200,
    margin: "0px -15px 0px 10px",
    cursor: "pointer",
  },
  avatar: {
    height: 40,
    width: 40,
    minWidth: 40,
    border: `1px solid ${Colors.darkNavy()}`,
    background: Colors.darkNavy(),
    margin: 5,
  },
  userMetadata: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  userName: {
    color: "#fff",
    fontSize: 14,
    fontStyle: "italic",
  },
  wallet: {
    color: "#fff",
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

export default NavbarUserCard;
