// NPM
import { css, StyleSheet } from "aphrodite";
import { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";

// COMPONENTS
import HyperLink from "../HyperLink.js";
import MeekicksPlaceholder from "../Meekicks/MeekicksPlaceholder";

// UTILS
import { Colors } from "../../utils/colors.js";
import { checkClaimStatusById, mintMeekicks } from "../../utils/meekicksInteract";
import { AWS_MEEKICKS_BASE_URL } from "../../utils/constants.js";
import { truncateAddress } from "../../utils/helpers";
import { switchNetworkMeekicks } from "../../utils/interact";
import OpenSeaLogo from "../../assets/opensea-logo.svg";
import DarkBlueRect from "../../assets/Dark_Blue_Rect.png";


const PixelMBCard = ({ meebit, walletAddress }) => {
  const { tokenId, image} = meebit;
  const [didImageLoad, setDidImageLoad] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  // todo: update with aws link
  const downloadHref = useMemo(() => `https://meebitsdao-pixel-meebits.s3.us-west-2.amazonaws.com/zip/${tokenId}.zip`, [meebit]);

  async function downloadPixelMB(e) {
    if (!window || !downloadHref) return;
    window.open(downloadHref, '_blank');
  }

  return (
    <div 
      className={css(styles.card)} 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* DESKTOP CLAIM BUTTON ON HOVER */}
      {isHovered && (
        <div className={css(styles.overlay)}>
          <div className={css(styles.claimButton)} onClick={downloadPixelMB}>
            {"Download"}
          </div>
        </div>
      )}
      {/* MOBILE CLAIM BUTTON (NO HOVER EFFECT) */}
      <div className={css(styles.overlayMobile)}>
        <div className={css(styles.claimButton)} onClick={downloadPixelMB}>
          {"Download"}
        </div>
      </div>
      <div 
        // className={"insetContainer"} 
        style={{ position: "relative" }}
      >
        <div className={css(styles.placeholderImage, didImageLoad && styles.hidden)}>
          <MeekicksPlaceholder hide={didImageLoad} src={DarkBlueRect} />
        </div>
        <img
          className={css(styles.meebitImage)} 
          src={image}
          alt={`Meebit #${tokenId}`}
          onLoad={() => setDidImageLoad(true)}
        />
        {/* DOWNLOAD BUTTON */}
        <a
          className={css(styles.downloadButton)}
          href={downloadHref}
          download
          target={"_blank"}
        >
          <i className={css(styles.downloadIcon) + " fas fa-download"} />
        </a>
      </div>
      <div className={css(styles.content)}>
        <h3 className={css(styles.title)}>
          {`Meebit #${tokenId}`}
        </h3>
      </div>
    </div>
  )
};

const styles = StyleSheet.create({
  card: {
    position: "relative",
    width: "100%",
    maxWidth: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    background: Colors.lightNavy(),
    border: `3px solid ${Colors.white()}`,
    boxShadow: `0px 0px 5px ${Colors.black()}`,
    margin: "0 auto",
    cursor: "pointer",
    transition: "all ease-out 0.1s",
    ":hover": {
      borderColor: Colors.uiTeal(),
      background: Colors.uiTeal(),

    }
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background:  "linear-gradient(180deg, rgba(16, 15, 16, 0.9) 0%, rgba(16, 15, 16, 0.6) 60%, rgba(16, 15, 16, 0) 80%)",
    zIndex: 1,
    pointerEvents: "none",
    "@media only screen and (max-width: 415px)": {
      display: "none"
    }
  },
  overlayMobile: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    background:  "linear-gradient(180deg, rgba(16, 15, 16, 0.2) 0%, rgba(16, 15, 16, 0.6) 60%, rgba(16, 15, 16, 0) 80%)",
    zIndex: 1,
    display: "none",
    "@media only screen and (max-width: 415px)": {
      display: "flex"
    }
  },
  tag: {
    position: "absolute",
    right: 0,
    top: 0,
    background: Colors.uiTeal(),
    padding: "5px 8px",
    border: `1px solid ${Colors.darkNavy()}`,
    textTransform: "uppercase",
    fontStyle: "italic",
    fontSize: 12,
    zIndex: 1
  },
  claimButton: {
    background: Colors.uiTeal(),
    padding: "10px 20px",
    border: `1px solid ${Colors.darkNavy()}`,
    textTransform: "uppercase",
    fontStyle: "italic",
    fontSize: 20,
    filter: `drop-shadow(0px 0px 5px ${Colors.black()})`,
    pointerEvents: "all",
    ":hover": {
      background: Colors.uiTeal(0.9),
      borderColor: Colors.white()
    }

  },
  placeholderImage: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    backgroundSize: "cover",
    minHeight: 220,
    overflow: "hidden"
    // height: "calc(100% - 60px)",
    // paddingBottom: "100%"
  },
  hidden: {
    display: "none"
  },
  meebitImage: {
    width: "100%",
    objectFit: "cover",
    // maxHeight: 300,
    background: "#67869b"
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    paddingBottom: 18
  },
  title: {
    textDecoration: "uppercase",
    color: Colors.white(),
    fontStyle: "italic",
    margin: 0,
    fontSize: 16,
    textTransform: "uppercase"
  },
  downloadButton: {
    position: "absolute",
    bottom: 10,
    right: 10,
    height: 35,
    width: 35,
    cursor: "pointer",
    background: Colors.lightNavy(),
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "underline",
    boxShadow: `0px 0px 10px ${Colors.black()})`,
    border: `1px solid ${Colors.darkNavy()}`,
    ":hover": {
      background: Colors.lightNavy(0.9),
      borderColor: Colors.white()
    }
    // zIndex: 1
  },
  downloadIcon: {
    color: Colors.white()
  }
});

export default PixelMBCard;