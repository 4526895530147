import { css, StyleSheet } from "aphrodite";
import { useEffect } from "react";
import { Colors } from "../../utils/colors"

const CascadingImage = ({
  src,
  alt = "meekick-placeholder",
  size = "100%",
  hide = false
}) => {

  function _formatBackgroundColor() {
    const styles = { backgroundColor: Colors.white() };
    return styles;
  };

  const backgroundStyle = _formatBackgroundColor();

  const layerStyles = {
    backgroundImage: `url(${src})`,
    height: size,
    minHeight: size,
    maxHeight: size,
    width: size,
    minWidth: size,
    maxWidth: size,
    ...backgroundStyle
  };

  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  

  function _renderCascadingLayers() {
    const layers = new Array(6).fill(1);

    return layers.map((_, i) => (
      <div 
        key={`${alt}-cascade-${i}`}
        className={css(styles.clip, styles[`clip${i}`])}
        style={{ ...layerStyles }}
      />
    ))
  };

  const cascade = _renderCascadingLayers();

  return (
    <div className={css(styles.container, hide && styles.hidden)} >
      {cascade}
    </div>
  )
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    overflow: "hidden",
    top: 0,
    left: 0,
    height: "100%",
    minHeight: "100%",
    maxHeight: "100%",
    paddingBottom: "100%",
    width: "100%",
    minWidth: "100%",
    maxWidth: "100%",
  },
  hidden: {
    display: "none"
  },
  clip: {
    position: "absolute",
    height: "100%",
    minHeight: "100%",
    maxHeight: "100%",
    paddingBottom: "100%",
    width: "100%",
    minWidth: "100%",
    maxWidth: "100%",
    noRepeat: "center",
    transformOrigin: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  clip0: { 
    animation: "clip1 4s linear infinite", 
  },
  clip1: {
      transform: "scale(1.1,1.1)",
      animation: "clipa 9s linear infinite",  
  },
  clip2: {
      transform: "scale(1.12,1.12)", 
      animation: "clip2 5s linear infinite",  
  },
  clip3: {
      transform: "scale(1.14,1.14)",
      animation: "clipb 4s linear infinite",  
  },
  clip4: {
      transform: "scale(1.15,1.15)",
      animation: "clip3 7s linear infinite",  
  },
  clip5: {
      transform: "scale(1.13,1.13)",
      animation: "clip4 6s linear infinite",  
  }
});

export default CascadingImage;