import { TextBlock, RectShape } from "react-placeholder/lib/placeholders";
import { css, StyleSheet } from "aphrodite";
import "react-placeholder/lib/reactPlaceholder.css";

export const DelegatePlaceholder = () => (
  <>
    <RectShape className={css(styles.block)} />
    <RectShape className={css(styles.block)} />
    <RectShape className={css(styles.block, styles.noMarginRight)} />
    <RectShape className={css(styles.block)} />
    <RectShape className={css(styles.block)} />
    <RectShape className={css(styles.block, styles.noMarginRight)} />
    <RectShape className={css(styles.block)} />
    <RectShape className={css(styles.block)} />
    <RectShape className={css(styles.block, styles.noMarginRight)} />
  </>
);

const styles = StyleSheet.create({
  block: {
    width: "unset",
    marginTop: 16,
    marginRight: 16,
    height: 80,
    background: "rgba(0, 56, 109, .7)",
  },
  noMarginRight: {
    marginRight: 0,
  },
});
