// NPM
import { css, StyleSheet } from "aphrodite";
import { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";

// COMPONENTS
import HyperLink from "../HyperLink.js";
import MeekicksPlaceholder from "./MeekicksPlaceholder";

// UTILS
import { Colors } from "../../utils/colors.js";
import { checkClaimStatusById, mintMeekicks } from "../../utils/meekicksInteract";
import { AWS_MEEKICKS_BASE_URL } from "../../utils/constants.js";
import { truncateAddress } from "../../utils/helpers";
import { switchNetworkMeekicks } from "../../utils/interact";
import OpenSeaLogo from "../../assets/opensea-logo.svg";
import DarkBlueRect from "../../assets/Dark_Blue_Rect.png";


const MeeKicksCard = ({ meebit, walletAddress, onMintEnd, userClaimedMeekicks }) => {
  const { tokenId, image} = meebit;

  const [didImageLoad, setDidImageLoad] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isMinting, setIsMinting] = useState(false);
  const { library, chainId } = useWeb3React();

  const [meekickMetadata, setMeekickMetadata] = useState({});
  const openseaUrl = useMemo(() => `https://opensea.io/assets/ethereum/0x371e60cd08445Afd78098a74984fF2C9902197B4/${tokenId}`, [meebit]);

  useEffect(() => {
    fetchMetadata();
    checkIfClaimed();
  }, []);

  useEffect(() => {
    if (!isClaimed) {
      checkIfClaimed();
    }
  }, [userClaimedMeekicks])

  //todo: move this to constants

  async function fetchMetadata() {
    // gets meekick metadata
    fetch(`${AWS_MEEKICKS_BASE_URL}/metadata/${tokenId}`)
      .then(res => res.json())
      .then(metadata => {
        setMeekickMetadata(metadata);
      })
      .catch(err => null)
  }

  async function checkIfClaimed() {
    try {
      const owner = await checkClaimStatusById(tokenId);
      if (owner) {
        setIsClaimed(owner.toLowerCase());
      }
    } catch (err) {
      setIsClaimed(false);
    }
  }

  async function claimKick(e) {
    e && e.stopPropagation();
    try {
      setIsMinting(true);
      const provider = library.provider;
      await switchNetworkMeekicks(provider);
      const res = await mintMeekicks(walletAddress, [tokenId], provider, chainId);
      onMintEnd && onMintEnd(res, () => setIsClaimed(true));
      setIsMinting(false);
    } catch (err) {
      toast.error("Something went wrong! Please try again");
      setIsMinting(false);
    }
  }


  const isOwner = walletAddress ? walletAddress.toLowerCase() === isClaimed : false;

  return (
    <div 
      className={css(styles.card)} 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* DESKTOP CLAIM BUTTON ON HOVER */}
      {(isHovered || isMinting) && !isClaimed && (
        <div className={css(styles.overlay)}>
          <div className={css(styles.claimButton)} onClick={claimKick}>
            {`Claim${isMinting ? "ing..." : ""}`}
          </div>
        </div>
      )}
      {/* MOBILE CLAIM BUTTON (NO HOVER EFFECT) */}
      {!isClaimed && (
        <div className={css(styles.overlayMobile)}>
          <div className={css(styles.claimButton)} onClick={claimKick}>
            {`Claim${isMinting ? "ing..." : ""}`}
          </div>
        </div>
      )}
      {/* CLAIMED TAG */}
      {isClaimed && (
        <div className={css(styles.tag)}>
          {isOwner ? "Claimed" : truncateAddress(isClaimed)}
        </div>
      )}
      <div style={{ position: "relative" }}>
        <div className={css(styles.placeholderImage, didImageLoad && styles.hidden)}>
          <MeekicksPlaceholder hide={didImageLoad} src={DarkBlueRect} />
        </div>
        <img
          className={css(styles.meekickImage)} 
          src={image}
          alt={`Meekicks #${tokenId}`}
          onLoad={() => setDidImageLoad(true)}
        />
        {/* OPENSEA HYPERLINK */}
        {isClaimed && (
          <HyperLink href={openseaUrl}>
            <img
              src={OpenSeaLogo}
              alt={"OpenSea Logo"}
              className={css(styles.openseaLogo)}
              draggable={false}
            />
          </HyperLink>
        )}
        {/* DOWNLOAD BUTTON */}
        {(isOwner && meekickMetadata) && (
          <a
            className={css(styles.downloadButton)}
            href={meekickMetadata?.file_link}
            download
            target={"_blank"}
          >
            <i className={css(styles.downloadIcon) + " fas fa-download"} />

          </a>
        )}
      </div>
      <div className={css(styles.content)}>
        <h3 className={css(styles.title)}>
          {`Meekicks #${tokenId}`}
        </h3>
      </div>
    </div>
  )
};

const styles = StyleSheet.create({
  card: {
    position: "relative",
    width: "100%",
    maxWidth: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    background: Colors.lightNavy(),
    border: `3px solid ${Colors.white()}`,
    boxShadow: `0px 0px 5px ${Colors.black()}`,
    margin: "0 auto",
    cursor: "pointer",
    transition: "all ease-out 0.1s",
    ":hover": {
      borderColor: Colors.uiTeal(),
      background: Colors.uiTeal(),

    }
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background:  "linear-gradient(180deg, rgba(16, 15, 16, 0.9) 0%, rgba(16, 15, 16, 0.6) 60%, rgba(16, 15, 16, 0) 80%)",
    zIndex: 1,
    "@media only screen and (max-width: 415px)": {
      display: "none"
    }
  },
  overlayMobile: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    background:  "linear-gradient(180deg, rgba(16, 15, 16, 0.2) 0%, rgba(16, 15, 16, 0.6) 60%, rgba(16, 15, 16, 0) 80%)",
    zIndex: 1,
    display: "none",
    "@media only screen and (max-width: 415px)": {
      display: "flex"
    }
  },
  tag: {
    position: "absolute",
    right: 0,
    top: 0,
    background: Colors.uiTeal(),
    padding: "5px 8px",
    border: `1px solid ${Colors.darkNavy()}`,
    textTransform: "uppercase",
    fontStyle: "italic",
    fontSize: 12,
    zIndex: 1
  },
  claimButton: {
    background: Colors.uiTeal(),
    padding: "10px 20px",
    border: `1px solid ${Colors.darkNavy()}`,
    textTransform: "uppercase",
    fontStyle: "italic",
    fontSize: 20,
    filter: `drop-shadow(0px 0px 5px ${Colors.black()})`,
    ":hover": {
      background: Colors.uiTeal(0.9),
      borderColor: Colors.white()
    }

  },
  placeholderImage: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    backgroundSize: "cover",
    minHeight: 220,
    overflow: "hidden"
    // height: "calc(100% - 60px)",
    // paddingBottom: "100%"
  },
  hidden: {
    display: "none"
  },
  meekickImage: {
    width: "100%",
    objectFit: "cover",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    paddingBottom: 18
  },
  title: {
    textDecoration: "uppercase",
    color: Colors.white(),
    fontStyle: "italic",
    margin: 0,
    fontSize: 16,
    textTransform: "uppercase"
  },
  openseaLogo: {
    position: "absolute",
    bottom: 10,
    right: 10,
    height: 35,
    width: 35,
    cursor: "pointer",
    boxShadow: `0px 0px 10px ${Colors.black()})`,
    ":hover": {
      opacity: 0.9,
    }
  },
  downloadButton: {
    position: "absolute",
    bottom: 9,
    right: 52,
    height: 35,
    width: 35,
    cursor: "pointer",
    background: Colors.lightNavy(),
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "underline",
    boxShadow: `0px 0px 10px ${Colors.black()})`,
    ":hover": {
      opacity: 0.8,

    }
    // zIndex: 1
  },
  downloadIcon: {
    color: Colors.white()
  }
});

export default MeeKicksCard;