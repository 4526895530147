import { css, StyleSheet } from "aphrodite";
import { useEffect, useState, Fragment } from "react";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";

// Components
import ActionButton from "../components/ActionButton";
import MeekicksHeader from "../components/Meekicks/MeekicksHeader";
import MeekicksGrid from "../components/Meekicks/MeekicksGrid";
import ClaimSummaryModal from "../components/Meekicks/ClaimSummaryModal";
import SpinLoader from "../components/SpinLoader";
import WalletConnectModal from "../components/Web3/WalletConnectModal";

// Assets & Utils
import DarkBlueRect from "../assets/Dark_Blue_Rect.png";
import MetaMaskLogo from "../assets/metamask-fox.svg";
import MBKicksBG from "../assets/bg/mbkicks.png";
import MBKicksSample from "../assets/meekicks/hero-image.gif";
import MBKicksIcon from "../assets/shoe.png";
import MBKicksLogo from "../assets/meekicks/logo-transparent.png";
import ToolTipIcon from "../assets/info-icon.png";
import { Colors } from "../utils/colors.js";
import {
  switchNetworkMeekicks,
} from "../utils/interact";
import {
  checkClaimStatusById,
  mintMeekicks,
  getUserMeekicksCount,
  getClaimableAndOwnedMeekicks,
} from "../utils/meekicksInteract";
import { truncateAddress } from "../utils/helpers";

const GIF_CYCLE_TIME = 3000;

const MeebitsKicksPage = ({
  walletAddress,
  walletConnected,
  userEns,
  setUserEns,
  setWallet,
}) => {
  //WEB3
  const { library, chainId } = useWeb3React();

  //HERO IMAGE
  const [cycle, setCycle] = useState(0);
  const [shadowColors, setShadowColors] = useState([
    Colors.white(),
    Colors.white(),
  ]);
  const [barStyles, setBarStyles] = useState(
    `linear-gradient(to right, ${
      Colors.pink
    }, ${Colors.white()},  ${Colors.uiTeal()})`
  );
  const [showDescription, setShowDescription] = useState(true);

  // MEEKICKS
  const [isMintingMeekicks, setIsMintingMeekicks] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [pageKey, setPageKey] = useState(null);
  const [userMeebits, setUserMeebits] = useState([]);
  const [userClaimedMeekicks, setUserClaimedMeekicks] = useState(0);

  // CLAIM CHECKER
  const [inputValue, setInputValue] = useState(0);
  const [isChecking, setIsChecking] = useState(false);
  const [claimMessage, setClaimMessage] = useState(null);

  // MINT / CLAIM SUMMARY
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [txSummary, setTxSummary] = useState({});

  // WALLET CONNECT MODAL
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);

  const isEligibleForClaim = userMeebits.length && walletAddress;

  useEffect(() => {
    const shadowColorInterval = setInterval(() => {
      setCycle((n) => n + 1);
      setShadowColors(determineColor(cycle));
      setBarStyles(determineBarColors(cycle));
    }, GIF_CYCLE_TIME);

    return () => {
      clearInterval(shadowColorInterval);
    };
  }, []);

  useEffect(() => {
    if (!walletAddress) return;
    checkForUserMeebits(walletAddress);
  }, [walletAddress, walletConnected]);

  async function checkForUserMeebits(walletAddress) {
    setIsFetching(true);
    const userMeebits = await getClaimableAndOwnedMeekicks(walletAddress);
    const userOwnedMeekicks = await getUserMeekicksCount(walletAddress);
    setUserMeebits(userMeebits);
    setUserClaimedMeekicks(userOwnedMeekicks);
    setIsFetching(false);
  }

  async function claimAllKicks() {
    const meebitIds = userMeebits.map((meebit) => meebit.tokenId);
    try {
      setIsMintingMeekicks(true);
      const provider = library.provider
      await switchNetworkMeekicks(provider);
      const response = await mintMeekicks(walletAddress, meebitIds, provider, chainId);
      setIsMintingMeekicks(false);
      handleResponse(response);
    } catch (error) {
      setIsMintingMeekicks(false);
      handleResponse({ success: false, status: error });
    }
  }

  function handleResponse(res, onSuccess) {
    const { success, status, quantity } = res;

    if (success) {
      showClaimSummaryModal(res);
      // onSuccess && onSuccess();
    } else {
      if (typeof status == "object") {;
        return toast.error(status?.message ? status?.message : "Something went wrong. Please try again later!");
      }
      toast.error(status);
    }
  }

  function determineColor(cycle) {
    switch (cycle % 6) {
      case 0:
        return [Colors.white(), Colors.white()];
      case 1:
        return [Colors.uiTeal(), Colors.white()];
      case 2:
        return [Colors.uiTeal(), Colors.uiTeal()];
      case 3:
        return [Colors.pink, Colors.uiTeal()];
      case 4:
        return [Colors.pink, Colors.pink];
      case 5:
        return [Colors.white(), Colors.pink];
      default:
        return [Colors.white(), Colors.white()];
    }
  }

  function determineBarColors(cycle) {
    switch (cycle % 3) {
      case 0:
        return `linear-gradient(to right, ${
          Colors.pink
        }, ${Colors.white()},  ${Colors.uiTeal()})`;
      case 1:
        return `linear-gradient(to right, ${Colors.uiTeal()}, ${
          Colors.pink
        }, ${Colors.white()})`;
      case 2:
        return `linear-gradient(to right, ${Colors.white()}, ${Colors.uiTeal()}, ${
          Colors.pink
        })`;
      default:
        return `linear-gradient(to right, ${
          Colors.pink
        }, ${Colors.white()},  ${Colors.uiTeal()})`;
    }
  }


  function renderMessage() {
    let user = "";
    if (userEns) {
      user = userEns;
    } else if (walletAddress) {
      user = truncateAddress(walletAddress);
    }

    return (
      <>
        <div className={css(styles.metadata)}>
          <span className={css(styles.label)}>{"Connected:"}</span>
          <span className={css(styles.value, styles.userWallet)}>{user}</span>
        </div>
        <div className={css(styles.metadata)}>
          <span className={css(styles.label)}>{"Meekicks Claimed:"}</span>
          <span
            className={css(styles.value, styles.userWallet)}
          >{`${userClaimedMeekicks}`}</span>
          {/* <img 
            src={ToolTipIcon} 
            className={css(styles.toolTipIcon)} 
            data-tip={"meekicks-tooltip"} 
            data-for={`meekicks-tooltip`}
          /> */}
        </div>
      </>
    );
  }

  function showClaimSummaryModal(res) {
    setTxSummary(res);
    setShowSummaryModal(true);
  }

  async function handleClick(e) {
    e && e.stopPropagation();
    if (!walletAddress) {
      setIsConnectModalOpen(true);
    } else if (isEligibleForClaim) {
      return claimAllKicks();
    }
  }

  function handleInput(e) {
    const value = e.target.value;
    setClaimMessage("");
    setInputValue(value);
  }

  async function checkClaim() {
    if (inputValue < 0 || inputValue >= 20000 || !inputValue) {
      return setClaimMessage("Invalid Meebit ID");
    }
    let message;
    setIsChecking(true);
    const owner = await checkClaimStatusById(inputValue);
    if (owner) {
      message = `MEEKICKS #${inputValue} is already claimed`;
    } else {
      message = `MEEKICKS #${inputValue} is available to claim`;
    }
    setClaimMessage(message);
    setIsChecking(false);
  }

  function renderClaimMessage() {
    if (isChecking) {
      return <SpinLoader isLoading={true} height={30} width={30} />;
    }
    return claimMessage;
  }

  async function handleSuccess() {
    // check for updates
    const userMeekicksCount = await getUserMeekicksCount(walletAddress);
    setUserClaimedMeekicks(userMeekicksCount);
  }

  return (
    <Fragment>
      {/* <MeekicksHeader /> */}
      <ClaimSummaryModal
        isOpen={showSummaryModal}
        closeModal={() => setShowSummaryModal(false)}
        txSummary={txSummary}
        onSuccess={handleSuccess}
      />
      <WalletConnectModal isOpen={isConnectModalOpen} onClose={() => setIsConnectModalOpen(false)} />

      <div className={css(styles.root)}>
        <div
          className={css(
            styles.heroContainer,
            showDescription && styles.openHeroContainer,
            isEligibleForClaim && styles.padding
          )}
        >
          {!isEligibleForClaim && (
            <div className={css(styles.heroImageContainer)}>
              <img
                src={MBKicksSample}
                className={css(styles.heroImage)}
                draggable={false}
                style={{
                  filter: `drop-shadow(0 10px 15px ${shadowColors[0]}) drop-shadow(0 10px 15px ${shadowColors[1]})`,
                }}
              />
            </div>
          )}
          <img
            className={css(styles.logo, isEligibleForClaim && styles.topRight)}
            src={MBKicksLogo}
            alt={"Meekicks Logo"}
            draggable={false}
          />
          <div
            className={css(
              styles.heroContent,
              isEligibleForClaim && styles.fullWidth
            )}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className={css(
                  styles.header,
                  isEligibleForClaim && styles.paddingLeft
                )}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: -20,
                    }}
                  >
                    <div>
                      <div className={css(styles.subtitle)}>
                        {!isEligibleForClaim ? "Meet the" : "Claim Your"}
                      </div>
                      {"MeeKicks"}
                    </div>
                    {isEligibleForClaim ? (
                      <img
                        src={MBKicksSample}
                        className={css(styles.heroImage2)}
                        draggable={false}
                        style={{
                          filter: `drop-shadow(0 2px 5px ${shadowColors[0]}) drop-shadow(0 2px 5px ${shadowColors[1]})`,
                        }}
                      />
                    ) : null}
                  </div>
                  {/* {walletAddress && (
                      <div onClick={() => setShowDescription(!showDescription)} style={{ cursor: "pointer"}}>
                        <i 
                          className={css(styles.chevron, showDescription && styles.chevronDown) + ` fas fa-chevron-down`} 
                        />
                      </div>
                    )} */}
                </div>
                <div
                  className={css(
                    styles.body,
                    (walletAddress || !showDescription) && styles.hidden
                  )}
                >
                  {`Meebits got the sweetest kicks. There are over 80 different pairs of Meebits shoes, accounting for type, color, and size. Connect your wallet that holds a Meebit to claim a pair of its voxel shoes.`}
                </div>
                <div className={css(styles.row)}>
                  <div
                    className={css(
                      styles.column,
                      !showDescription && styles.hidden
                    )}
                  >
                    {walletAddress ? (
                      renderMessage()
                    ) : (
                      <Fragment>
                        <div className={css(styles.metadata)}>
                          <span className={css(styles.label)}>
                            {"Max Supply:"}
                          </span>
                          <span className={css(styles.value)}>{"20,000"}</span>
                        </div>
                        <div className={css(styles.metadata)}>
                          <span className={css(styles.label)}>{"Price:"}</span>
                          <span className={css(styles.value)}>
                            {"Free. One for every Meebit"}
                          </span>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  {isEligibleForClaim ? (
                    <ActionButton
                      text={
                        !walletAddress ? "Connect Wallet" : "Claim All Kicks"
                      }
                      style={
                        !walletAddress
                          ? styles.connectButton
                          : !isEligibleForClaim
                          ? styles.disabledButton
                          : styles.connectButton
                      }
                      onClick={handleClick}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className={css(styles.divider)} />
            <MeekicksGrid
              userClaimedMeekicks={userClaimedMeekicks}
              userMeebits={userMeebits}
              isLoading={isFetching}
              walletAddress={walletAddress}
              onMintEnd={handleResponse}
            />
            {isEligibleForClaim ? (
              <div className={css(styles.bottomDivider)} />
            ) : null}
            <div
              className={css(
                styles.footer,
                isEligibleForClaim && styles.paddingLeft
              )}
            >
              <Link className={css(styles.tos)} to="/meekicks/tos">
                {"Terms of Service"}
              </Link>
              {!isEligibleForClaim && (
                <ActionButton
                  text={
                    !walletAddress ? "Connect Wallet" : "Claim All Your Kicks"
                  }
                  style={
                    !walletAddress
                      ? styles.connectButton
                      : !isEligibleForClaim
                      ? styles.disabledButton
                      : styles.connectButton
                  }
                  onClick={handleClick}
                />
              )}
            </div>
          </div>
        </div>

        <div className={css(styles.kicksChecker)}>
          <div className={css(styles.inputLabel)}>
            {"MeeKicks Claim Checker"}
          </div>
          <div className={css(styles.inputContainer)}>
            <input
              className={css(styles.input)}
              placeholder="MEEBIT ID #"
              type={"number"}
              id="meekicks_claim_checker"
              onChange={handleInput}
              value={inputValue}
              min={0}
              max={19999}
            />
            <ActionButton
              style={styles.checkButton}
              onClick={checkClaim}
              text={
                <>
                  <span>{"check"}</span>
                  <img
                    src={MBKicksIcon}
                    style={{
                      color: "#FFF",
                      height: 40,
                      borderRadius: 100,
                      transform: "rotate(-0.1turn)",
                    }}
                    className="far fa-check-circle"
                  />
                </>
              }
            />
          </div>
          <div className={css(styles.claimMessage)}>{renderClaimMessage()}</div>
        </div>
      </div>
    </Fragment>
  );
};

const styles = StyleSheet.create({
  root: {
    position: "relative",
    width: "100vw",
    backgroundImage: `url(${MBKicksBG})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxSizing: "border-box",
    padding: 50,
    paddingTop: 30,
    minHeight: "100vh",
    "@media only screen and (max-width: 767px)": {
      padding: "50px 0px",
    },
  },
  heroContainer: {
    position: "relative",
    width: "100%",
    maxWidth: 1200,
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${Colors.darkNavy()}`,
    borderRadius: 5,
    padding: "60px 40px 60px 20px",
    boxSizing: "border-box",
    boxShadow: `0px 0px 50px ${Colors.uiTeal(0.5)}`,
    background: `url(${DarkBlueRect})`,
    "@media only screen and (max-width: 1000px)": {
      padding: "60px 40px",
      flexDirection: "column",
    },
  },
  padding: {
    paddingBottom: 30,
  },
  openHeroContainer: {
    alignItems: "flex-start",
  },
  kicksChecker: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 150,
    width: "100%",
    padding: "30px 40px",
    maxWidth: 1200,
    margin: "30px auto 0 auto",
    background: `url(${DarkBlueRect})`,
    border: `1px solid ${Colors.darkNavy()}`,
    boxShadow: `0px 0px 50px ${Colors.uiTeal(0.3)}`,
    fontSize: 18,
    borderRadius: 5,
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      height: "unset",
      boxSizing: "border-box",
    },
  },
  logo: {
    position: "absolute",
    bottom: 30,
    left: 30,
    height: 50,
    "@media only screen and (max-width: 1000px)": {
      height: 40,
      top: 10,
    },
    "@media only screen and (max-width: 767px)": {
      height: 30,
    },
  },
  topRight: {
    top: 10,
    right: 10,
    left: "unset",
    height: 40,
  },
  header: {
    fontFamily: "'Montserrat'",
    fontSize: 60,
    textTransform: "uppercase",
    fontStyle: "italic",
    filter: `drop-shadow(0px 0px 5px ${Colors.darkNavy()})`,
    "@media only screen and (max-width: 767px)": {
      fontSize: 40,
    },
  },
  footer: {
    paddingTop: 30,
  },
  paddingLeft: {
    paddingLeft: 10,
    // paddingRight: 30,
    "@media only screen and (max-width: 1000px)": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  subtitle: {
    fontSize: 32,
    "@media only screen and (max-width: 767px)": {
      fontSize: 20,
    },
  },
  body: {
    lineHeight: 1.6,
    margin: "20px 0",
    fontSize: 18,
    textShadow: `0px 0px 5px ${Colors.darkNavy(0.7)}`,
    transition: "opacity 0.1s linear",
    fontStyle: "normal",
    textTransform: "none",
    background: Colors.lightNavy(),
    padding: "15px 20px",
  },
  hidden: {
    height: 0,
    visibility: "hidden",
    opacity: 0,
    padding: 0,
    margin: 0,
  },
  chevron: {
    fontSize: 25,
    color: Colors.white(),
    transition: "all 0.2s linear",
    transform: "rotate(-180deg)",
  },
  chevronDown: {
    transform: "rotate(0deg)",
  },
  heroImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
    // paddingRight: 30,
    // height: 550,
    boxSizing: "border-box",
    // boxShadow: `0px 0px 10px ${Colors.darkNavy()}`,
    position: "relative",
    // backdropFilter: "blur(10px)",
    "@media only screen and (max-width: 1000px)": {
      paddingRight: 0,
      width: "100%",
    },
  },
  heroImage: {
    width: "100%",
    maxWidth: 400,
    transition: "all ease-in-out 0.4s",
    "@media only screen and (max-width: 1000px)": {
      maxWidth: 250,
    },
  },
  heroImage2: {
    maxWidth: 120,
    padding: "0 30px",
    transition: "all ease-in-out 0.4s",
    "@media only screen and (max-width: 415px)": {
      maxWidth: 80,
      display: "none",
    },
  },
  heroContent: {
    width: "60%",
    boxSizing: "border-box",
    "@media only screen and (max-width: 1000px)": {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
  userWallet: {
    fontStyle: "italic",
    textTransform: "uppercase",
  },
  toolTipIcon: {
    height: 25,
    width: 25,
    marginLeft: 8,
  },
  bg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  metadata: {
    display: "flex",
    alignItems: "center",
    lineHeight: 2,
    fontSize: 20,
    cursor: "default",
    filter: `drop-shadow(0px 0px 5px ${Colors.darkNavy()})`,
    "@media only screen and (max-width: 767px)": {
      fontSize: 16,
      display: "inline-block",
      alignItems: "unset",
    },
  },
  label: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontStyle: "italic",
  },
  value: {
    marginLeft: 10,
    color: Colors.uiTeal(),
    fontStyle: "italic",
  },
  metamaskLogo: {
    height: 24,
  },
  connectButton: {
    marginTop: 30,
    width: "unset",
    border: "none",
    boxShadow: `0px 0px 10px ${Colors.darkNavy()}`,
    outline: "none",
    heighlight: "none",
  },
  tos: {
    paddingTop: 20,
    color: Colors.white(),
    textTransform: "uppercase",
    fontStyle: "italic",
    textDecoration: "unset",
    ":hover": {
      textDecoration: "underline",
      color: Colors.uiTeal(),
    },
    fontSize: 20,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      fontSize: 16,
    },
  },
  disabledButton: {
    opacity: 0.6,
    marginTop: 30,
    width: "unset",
    // border: `1px solid ${Colors.uiTeal()}`,
    border: "none",
    boxShadow: `0px 0px 10px ${Colors.darkNavy()}`,
    outline: "none",
    heighlight: "none",
    cursor: "not-allowed",
    ":hover": {
      opacity: 0.6,
      pointerEvents: "none",
    },
  },

  walletMessage: {
    paddingTop: 20,
    lineHeight: 1.6,
    whiteSpace: "pre-line",
    fontSize: 18,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    fontStyle: "italic",
    textTransform: "uppercase",
  },
  claimMessage: {
    paddingTop: 20,
    lineHeight: 1.6,
    whiteSpace: "pre-line",
    fontSize: 18,
  },
  input: {
    border: 2,
    textDecoration: "none",
    boxSizing: "border-box",
    width: "15em",
    // fontWeight: 700,
    lineHeight: "29px",
    padding: 16,
    fontSize: 18,
    fontFamily: "'Montserrat'",
    // font-weight: 400;
    lineHeight: 1.5,
    height: 59,
    // borderRadius: 8,
    // padding: "12px 32px 14px",
    border: `3px solid ${Colors.uiTeal()}`,
    boxShadow: `0px 0px 10px ${Colors.darkNavy()}`,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  inputLabel: {
    fontSize: 20,
    marginBottom: 20,
    fontStyle: "italic",
    textTransform: "uppercase",
  },
  inputContainer: {
    display: "flex",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
      width: "100%",
    },
  },
  checkButton: {
    marginLeft: 30,
    lineHeight: 1.5,
    height: 59,
    boxShadow: `0px 0px 5px ${Colors.darkNavy()}`,
    "@media only screen and (max-width: 767px)": {
      marginLeft: 0,
      marginTop: 20,
      width: "100%",
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  column: {
    // paddingBottom: 20,
    transition: "opacity 0.1s linear",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    alignItems: "flex-start",
    // borderTop: `2px solid ${Colors.white()}`,
  },
  divider: {
    width: "100%",
    height: 3,
    transition: "all ease-in-out 0.2s",
    background: `linear-gradient(to right, ${Colors.uiTeal()}, ${Colors.white()}, ${
      Colors.pink
    }, ${Colors.uiTeal()})`,
    // backgroundSize: "200% 200%",
    // animation: "gradient 2s ease infinite",

    // background: barStyles,
    filter: `drop-shadow(0px 0px 5px ${Colors.white()})`,
    marginTop: 20,
  },
  bottomDivider: {
    width: "100%",
    height: 3,
    transition: "all ease-in-out 0.2s",
    background: `linear-gradient(to right, ${Colors.uiTeal()}, ${Colors.white()}, ${
      Colors.pink
    }, ${Colors.uiTeal()})`,
    // backgroundSize: "200% 200%",
    // animation: "gradient 2s ease infinite",

    // background: barStyles,
    filter: `drop-shadow(0px 0px 5px ${Colors.white()})`,
  },
});

export default MeebitsKicksPage;
