import { css, StyleSheet } from "aphrodite";
import { Link } from "react-router-dom";

import LightBlueRect from "../assets/Light_Blue_Rect.png";
import ChevronLeft from "../assets/chevron-left.png";


export default function BackButton({ to, onClick, style }) {
  return (
    <>
      {to ? (
        <Link to={to} className={css(styles.backButton, style)}>
          <img src={ChevronLeft} className={css(styles.chevronLeftIcon)} />
        </Link>
      ) :  (
        <button
          className={css(
            styles.backButton,
            style,
          )}
          onClick={onClick}
        >
          <img src={ChevronLeft} className={css(styles.chevronLeftIcon)} />
        </button>
      )}
    </>
  );
}
const styles = StyleSheet.create({
  backButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    background: `url(${LightBlueRect})`,
    border: 0,
    borderRadius: 100,
    cursor: "pointer",
    textDecoration: "none",
    boxSizing: "border-box",
    ":hover": {
      opacity: 0.9
    }
  },
  chevronLeftIcon: {
    height: 23,
    paddingRight: 3
  },
  disabled: {
    cursor: "not-allowed",
    opacity: 0.5,
  },
  disabledLink: {
    pointerEvents: 'none',
  },
});
