import { useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";

import {
  getCurrentWalletConnected,
  connectWallet,
} from "../../utils/interact.js";
import {
  mbbtMintNFTWhitelist,
  mbbtIsEligibleWhitelist,
} from "../../utils/mbbtGeneralMembershipInteract";
import ActionButton from "../ActionButton";
import LightGreenRect from "../../assets/Light_Green_Rect.png";
import { DriveEta } from "@mui/icons-material";

const MBBTMembershipMinter = (props) => {
  let storedTxLink = window.localStorage.getItem("txLink");
  let storedTxHash = window.localStorage.getItem("txHash");

  if (storedTxLink === "undefined") {
    storedTxLink = "";
  }

  if (storedTxHash === "undefined") {
    storedTxHash = "";
  }

  const {
    setMBBTMembershipMinted,
    walletConnected,
    setWalletConnected,
    nav,
    hasMBBTMembershipToken,
    selectedAddress,
    walletAddress,
    setWallet,
    networkId,
    setNetworkId,
    networkErrorMessage,
    setNetworkErrorMessage,
    disabled,
    setDisabled,
  } = props;

  const [txStatus, setTxStatus] = useState(null);
  const [txHash, setTxHash] = useState(storedTxHash);
  const [txLink, setTxLink] = useState(storedTxLink);
  const [mbbtWhitelisted, setMBBTWhitelisted] = useState(false);

  useEffect(() => {
    const getTransactionReceipt = async () => {
      if (txHash) {
        const receipt = await window.web3.eth.getTransactionReceipt(txHash);

        if (receipt) {
          setTxStatus(receipt.status);
        } else {
          setTimeout(() => {
            getTransactionReceipt();
          }, 1000);
        }
      }
    };
    getTransactionReceipt();
  }, [txHash]);

  useEffect(() => {
    const checkWhiteList = async () => {
      try {
        const walletResponse = await connectWallet();
        setWallet && setWallet(walletResponse.address);
        if (walletResponse.address) {
          setWalletConnected && setWalletConnected(true);
          try {
            const eligibleResponse = await mbbtIsEligibleWhitelist(
              walletResponse.address
            );
            const isWhitelisted = eligibleResponse[1];
            setMBBTWhitelisted(isWhitelisted);
          } catch (error) {
            setMBBTWhitelisted(false);
            console.log(error);
          }
        } else {
          setWalletConnected && setWalletConnected(false);
          setMBBTWhitelisted(false);
        }
      } catch (error) {
        setWalletConnected && setWalletConnected(false);
        setMBBTWhitelisted(false);
      }
    };

    checkWhiteList();
  }, [walletConnected, window.ethereum?.selectedAddress]);

  const onMBBTMembershipMintPressed = async () => {
    const walletResponse = await connectWallet();
    setWallet && setWallet(walletResponse.address);
    if (walletResponse.address) {
      setWalletConnected && setWalletConnected(true);
      try {
        const eligibleResponse = await mbbtIsEligibleWhitelist(
          walletResponse.address
        );
        const isWhitelisted = eligibleResponse[1];
        if (isWhitelisted) {
          const { txLink, txHash } = await mbbtMintNFTWhitelist(
            walletResponse.address
          );
          setTxLink(txLink);
          setTxHash(txHash);

          window.localStorage.setItem("txHash", txHash);
          window.localStorage.setItem("txLink", txLink);

          if (txLink) {
            setMBBTMembershipMinted(true);
          }
        }
      } catch (error) {
        setMBBTWhitelisted(false);
        console.log(error);
      }
    }
  };

  return (
    <>
      {!hasMBBTMembershipToken ? (
        <>
          <ActionButton
            text={"Mint"}
            style={styles.mintButton}
            onClick={onMBBTMembershipMintPressed}
            disabled={!mbbtWhitelisted}
          />
          {networkErrorMessage && (
            <p className={css(styles.error)}> {networkErrorMessage} </p>
          )}
        </>
      ) : txLink ? (
        <div className={css(styles.mint, nav && styles.nav)}>
          <div className={css(styles.desc)}>
            {txStatus
              ? "Thanks for joining the MeebitsDAO as a General Member!"
              : txStatus === false
              ? "Oh no! Your transaction failed. Please try minting again."
              : "Please wait for your transaction to complete to confirm your status as a MeebitsDAO General Member. Thanks for joining us!"}
          </div>
          <div className={css(styles.innerJoinTitle)}>View Transaction</div>
          <div className={css(styles.inner)}>
            {txStatus ? null : txStatus === false ? (
              <i
                style={{ color: "red", marginRight: 8 }}
                className="fas fa-exclamation-triangle"
              ></i>
            ) : (
              <i
                style={{ color: "#fff", marginRight: 8 }}
                className="fad fa-spinner-third fa-spin"
              ></i>
            )}
            <a
              href={txLink}
              target="_blank"
              rel="noreferrer"
              className={css(styles.tx)}
            >
              {txLink}
            </a>
          </div>
        </div>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  mintButton: {
    fontSize: 24,
    height: "3em",
  },
  txStatusButton: {
    fontSize: 18,
    "text-align": "center",
    height: "4em",
    background: `url(${LightGreenRect})`,
  },
  tx: {
    color: "#fff",
    textOverflow: "ellipsis",
    width: "100%",
    overflow: "hidden",
  },
  error: {
    textAlign: "center",
    fontSize: 16,
    marginTop: 8,
  },
  mint: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
  },
  nav: {
    position: "absolute",
  },
  innerJoinTitle: {
    fontSize: 20,
    fontStyle: "italic",
    fontWeight: 800,
    marginTop: 8,
    border: 0,
    color: "rgb(255, 255, 255)",
    textTransform: "uppercase",
    marginBottom: 8,
  },
  inner: {
    display: "flex",
    alignItems: "center",
  },
  desc: {
    marginBottom: 15,
  },
});

export default MBBTMembershipMinter;
