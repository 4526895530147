import { StyleSheet, css } from "aphrodite";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";

// Components
import ActionButton from "../ActionButton";
import Wallet from "../Minting/Wallet";
import SpinLoader from "../SpinLoader";

// Utils
import { getNotionValueByKey } from "../../utils/helpers";
import { getFounder } from "../../utils/api";
import { Colors } from "../../utils/colors";
import "./checkbox.css";
import { BASE_URL, MEEBITSDAO_BANNER_IMAGE_URL } from "../../utils/constants";
import FounderUpdateSuccess from "./FounderUpdateSuccess";

const Input = (props) => {
  return (
    <input {...props} className={css(styles.input, props.inputClassName)} />
  );
};

const TextArea = (props) => {
  return <textarea {...props} className={css(styles.input)} />;
};

const InputContainer = (props) => {
  return (
    <div
      className={
        css(styles.container, styles.inputContainer) + ` ${props.className}`
      }
    >
      <label className={css(styles.inputLabel)}>
        {props.label}
        {props.textarea ? <TextArea {...props} /> : <Input {...props} />}
        {props.type === "checkbox" && <span class="checkmark"></span>}
      </label>
    </div>
  );
};

export default function Founders(props) {
  const {
    walletAddress,
    setWalletStatus,
    walletConnected,
    setWalletConnected,
    setWallet,
  } = props;

  const [page, setPage] = useState("");
  const [hasFounderProfile, setHasFounderProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notionURL, setNotionURL] = useState("");
  const [founder, setFounder] = useState(null);
  const [founderPhotoFile, setFounderPhotoFile] = useState(null); // for uploading to s3
  const [founderPhoto, setFounderPhoto] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    checkIsFounder();
  }, [walletAddress]);

  async function checkIsFounder() {
    if (!walletAddress || !walletConnected) return setIsLoading(false);

    try {
      const founder = await getFounder(walletAddress);
      setIsLoading(false);
      if (founder) {
        setFounder(founder);
        setFounderPhoto(getNotionValueByKey("picture", founder));
        setNotionURL(
          founder.url.replace("www.notion.so", "blockblock.notion.site")
        );
        setHasFounderProfile(true);
      }
    } catch (err) {
      // handle error
      setIsLoading(false);
    }
  }

  const submitForm = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    const data = {
      optin: document.getElementById("optin").checked,
      name: document.getElementById("name").value,
      eth_address: document.getElementById("eth_address").value,
      interest: document.getElementById("interest").value,
      twitter: document.getElementById("twitter").value,
      id: founder.id,
      file: founderPhotoFile,
    };

    for (const name in data) {
      formData.append(name, data[name]);
    }

    const config = {
      method: "POST",
      body: formData,
    };

    setSubmitLoading(true);
    await fetch(`${BASE_URL}/founder/update`, config);
    setSubmitLoading(false);
    setPage("success");
  };

  const renderPage = () => {
    if (isLoading) {
      return (
        <>
          <div className={css(styles.title)}>Authenticating</div>
          <div className={css(styles.loader)}>
            <SpinLoader isLoading={true} />
          </div>
        </>
      );
    } else if (page == "") {
      return (
        <>
          <div className={css(styles.title)}>Opt In to Delegation</div>
          <div className={css(styles.subtext)}>
            As a MeebitsDAO Founder, you are a voting member in the DAO. General
            Members look towards the founders to set the direction the DAO
            heads. In order to be considered as a delegate, you{" "}
            <strong className={css(styles.strong)}>must</strong> opt in by
            filling out the form below.
          </div>
          <div className={css(styles.tokensContainer)}>
            <div className={css(styles.tokensSubtitle)}>Gain Voting Power</div>
            <div className={css(styles.tokensCount)}>
              +1 Vote Power per MVOX
            </div>
          </div>
          <div className={css(styles.buttonContainer)}>
            {!walletConnected ? (
              <Wallet
                {...props}
                walletFlow="claim"
                styles={styles.claimButton}
              />
            ) : (
              <ActionButton
                text={
                  isLoading ? (
                    <SpinLoader isLoading={true} height={30} />
                  ) : (
                    "Start Delegation Process"
                  )
                }
                href={
                  hasFounderProfile
                    ? null
                    : "https://blockblock.notion.site/MeebitsDAO-Founder-Form-944c95dcbf1c446784e316af74dbf92b"
                }
                onClick={hasFounderProfile ? () => setPage("form") : null}
                state={{ isClaimingTokenOnly: false }} //decoupling from setting delegate\
                style={styles.claimButton}
              />
            )}
          </div>
        </>
      );
    } else if (page == "success") {
      return <FounderUpdateSuccess />;
    } else if (page == "form") {
      return (
        <form onSubmit={submitForm}>
          {/* <img className={css(styles.formImage)} src={MEEBITSDAO_BANNER_IMAGE_URL} /> */}
          <h1 className={css(styles.formTitle)}>Founder Delegation Info</h1>
          <InputContainer
            label={"Opt In"}
            name={"opt-in"}
            type="checkbox"
            id={"optin"}
            inputClassName={styles.checkboxContainer}
            className={"container"}
            defaultValue={
              getNotionValueByKey("Opt In Delegate", founder) || true
            }
          />
          <InputContainer
            label={"Name (public)"}
            id={"name"}
            defaultValue={getNotionValueByKey("name", founder)}
            inputClassName={styles.normalCase}
          />
          <InputContainer
            label={"Ethereum Address"}
            id={"eth_address"}
            defaultValue={getNotionValueByKey("eth_address", founder)}
          />
          <InputContainer
            label={"Bio (public)"}
            textarea
            id={"interest"}
            defaultValue={getNotionValueByKey("interest", founder)}
            inputClassName={styles.normalCase}
          />
          <InputContainer
            label={"Twitter Handle"}
            id={"twitter"}
            defaultValue={getNotionValueByKey("twitter", founder)}
          />
          <div className={css(styles.photoInputLabelContainer)}>
            <label className={css(styles.inputLabel)}>Profile Photo</label>
          </div>
          <Dropzone
            onDrop={(acceptedFiles) => {
              const file = acceptedFiles[0];
              const preview = URL.createObjectURL(file);
              setFounderPhotoFile(file);
              setFounderPhoto(preview);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} className={css(styles.dropzone)}>
                  <input {...getInputProps()} />
                  {founderPhoto ? (
                    <>
                      <img
                        className={css(styles.photo)}
                        src={founderPhoto}
                        alt="founder"
                      />
                      <p style={{ fontSize: 14 }}>
                        Click or drag 'n' drop to change your photo
                      </p>
                    </>
                  ) : (
                    <p style={{ fontSize: 14 }}>
                      Click or drag 'n' drop to add a photo
                    </p>
                  )}
                </div>
              </section>
            )}
          </Dropzone>

          <ActionButton
            text={
              submitLoading ? (
                <SpinLoader isLoading={true} height={30} />
              ) : (
                "Submit"
              )
            }
            onClick={null}
            style={styles.formSubmitButton}
            type={"submit"}
          />
        </form>
      );
    } else {
      return null;
    }
  };

  const renderNonFounderScreen = () => {
    return (
      <>
        <div className={css(styles.title)}>For Founders Only</div>
        <div className={css(styles.subtext, styles.padding)}>
          {`If you are a MeebitsDAO Founder, or a voting member in the DAO,
            please ${
              walletConnected ? "switch" : "connect"
            } to the account with your registered address.`}
        </div>
        <div className={css(styles.buttonContainer)}>
          {walletConnected ? (
            <ActionButton
              text={"Back to Main Page"}
              style={styles.claimButton}
              to={"/"}
            />
          ) : (
            <Wallet {...props} walletFlow="claim" styles={styles.claimButton} />
          )}
        </div>
      </>
    );
  };

  return <div className={css(styles.container)}>{renderPage()}</div>;
}

const styles = StyleSheet.create({
  dropzone: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 32,
    backgroundColor: Colors.lightNavy(),
    cursor: "pointer",
    margin: "15px auto",
    border: `0.5px dashed ${Colors.darkNavy(1)}`,
    ":active": {
      borderColor: Colors.uiTeal(),
    },
    ":focus": {
      borderColor: Colors.uiTeal(),
    },
  },
  container: {
    paddingBottom: 16,
  },
  inputContainer: {
    fontSize: 16,
    textAlign: "left",
    justifyContent: "flex-start",
  },
  title: {
    margin: "0 auto",
    fontSize: 28,
    fontWeight: 800,
    fontStyle: "italic",
    textTransform: "uppercase",
    whiteSpace: "pre-wrap",
  },
  strong: {
    fontWeight: 800,
    fontStyle: "italic",
  },
  subtext: {
    margin: "15px auto",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.4,
    padding: "0px 48px",
    letterSpacing: 0.5,
    maxWidth: 510,
    "@media only screen and (max-width: 767px)": {
      padding: 0,
    },
  },
  checkboxContainer: {
    width: "unset",
    backgroundColor: Colors.lightNavy(),
    border: `1px solid ${Colors.darkNavy()}`,
  },
  photo: {
    height: 100,
    objectFit: "contain",
  },
  tokensContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: 350,
    background: "rgba(0, 56, 109, 1)",
    boxSizing: "border-box",
    margin: "30px auto",
    padding: "0 15px",
    border: `1px solid ${Colors.darkNavy()}`,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  tokensSubtitle: {
    margin: "20px auto 18px auto",
    fontSize: 16,
    fontWeight: "lighter",
    fontStyle: "italic",
    textTransform: "uppercase",
  },
  tokensCount: {
    margin: "0 auto 20px auto",
    fontSize: 30,
    fontWeight: 500,
    fontStyle: "italic",
    textTransform: "uppercase",
  },
  claimButton: {
    width: "fit-content",
    height: 60,
    margin: "30px auto 0 auto",
    fontSize: 18,
  },
  formSubmitButton: {
    width: "100%",
    height: 60,
    margin: "30px auto 0 auto",
    fontSize: 18,
  },
  input: {
    width: "100%",
    borderRadius: 3,
    padding: 16,
    border: `1px solid ${Colors.darkNavy()}`,
    marginTop: 16,
    boxSizing: "border-box",
    fontSize: 12,
    fontFamily: "Montserrat",
    fontStyle: "italic",
    // fontWeight: 600,
    color: "#fff",
    backgroundColor: Colors.lightNavy(),
    outline: "none",
    textTransform: "uppercase",

    "::placeholder": {
      color: "#fff",
      opacity: 0.8,
    },
    ":active": {
      borderColor: Colors.uiTeal(),
    },
    ":focus": {
      borderColor: Colors.uiTeal(),
    },
  },
  normalCase: {
    textTransform: "unset",
  },
  loader: {
    margin: "20px 0",
  },
  padding: {
    padding: "20px 0",
  },
  // Form Styles todo: move to own file & component
  formTitle: {
    fontStyle: "italic",
    "@media only screen and (max-width: 767px)": {
      fontSize: 22,
      paddingBottom: 20,
    },
  },
  formImage: {
    width: "100%",
    objectFit: "cover",
    border: `1px solid ${Colors.darkNavy()}`,
  },
  inputLabel: {
    cursor: "pointer",
    fontSize: 16,
  },
  photoInputLabelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
});
