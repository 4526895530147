import { StyleSheet, css } from "aphrodite";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import ChooseDelegate from "./ChooseDelegate";
import ClaimSuccess from "./ClaimSuccess";
import SpinLoader from "../SpinLoader";

import { getUserCurrentDelegate } from "../../utils/api";

export default function ChooseDelegateContainer({ walletAddress }) {
  const location = useLocation();
  const navigate = useNavigate();

  // STATE
  const [page, setPage] = useState("delegate");
  const [delegate, setDelegate] = useState(null);

  // used to prevent accidental rerouting
  const [currentWalletAddress, setCurrentWalletAddress] = useState(null);

  // todo: receive from router props
  const [userCurrentDelegate, setUserCurrentDelegate] = useState(false);
  const [isFetchingCurrentDelegate, setIsFetchingCurrentDelegate] =
    useState(true);

  useEffect(() => {
    async function checkCurrentDelegate() {
      const { state } = location;
      setIsFetchingCurrentDelegate(true);
      const delegate = await getUserCurrentDelegate(walletAddress);
      setUserCurrentDelegate(delegate);

      if (state && state.isClaimingTokenOnly) {
        // delegate screen isn't necessary for users who only want to claim token
        setPage("success");
        setDelegate(delegate);
      }
      setIsFetchingCurrentDelegate(false);
    }

    // push user back to claim page to connect wallet (case: hard refresh)
    if (!walletAddress) return navigate("/claim");
    checkCurrentDelegate();
  }, []);

  useEffect(() => {
    if (!currentWalletAddress) {
      setCurrentWalletAddress(walletAddress);
    } else if (!walletAddress || walletAddress != currentWalletAddress) {
      // push user back to claim if wallet address is null (hard refersh) or changed
      navigate("/claim");
    }
  }, [walletAddress]);

  const renderScreenbyState = () => {
    if (isFetchingCurrentDelegate) {
      return (
        <Fragment>
          <div className={css(styles.title)}>Initializing</div>
          <SpinLoader isLoading={true} />
        </Fragment>
      );
    } else if (page === "delegate") {
      return (
        <ChooseDelegate
          setPage={setPage}
          setDelegate={setDelegate}
          userCurrentDelegate={userCurrentDelegate} // for existing delegates
        />
      );
    } else {
      return (
        <ClaimSuccess
          setPage={setPage}
          delegate={delegate}
          userCurrentDelegate={userCurrentDelegate}
          walletAddress={walletAddress}
        />
      );
    }
  };

  return <>{renderScreenbyState()}</>;
}

const styles = StyleSheet.create({
  title: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontSize: 24,
    paddingBottom: 20,
  },
});
