require("dotenv").config();
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const meebitsContractABI = require(
  "../contracts/Meebits.json"
);
const meekicksContractABI =
  require("../contracts/Meekicks.json");
const {
  AWS_MEEKICKS_BASE_URL,
  AWS_PIXELMB_BASE_URL
} = require("./constants");

const alchemyKey = process.env.REACT_APP_ALCHEMY_URL;
const meekicksContractAddress =  process.env.REACT_APP_MEEKICKS_CONTRACT_ADDRESS;
const meebitsContractAddress = process.env.REACT_APP_MEEBITS_CONTRACT_ADDRESS;

console.log("meebitsContractAddress", meebitsContractAddress)
console.log("meekicks contract",  meekicksContractAddress)

const web3 = createAlchemyWeb3(alchemyKey);

const meekicksContract = new web3.eth.Contract(
  meekicksContractABI,
  meekicksContractAddress
);

const meebitsContract = new web3.eth.Contract(
  meebitsContractABI,
  meebitsContractAddress
);

window.web3 = web3;
window.meekicksContract = meekicksContract;
window.meebitsContract = meebitsContract;



export const getUserMeekicksCount = async (address) => {
  try {
    const numMeekicksHeld = await meekicksContract.methods.balanceOf(address).call()
    return numMeekicksHeld;
  } catch (err) {
    return null;
  }
}

export const checkClaimStatusById = async (tokenId) => {
  try {
    const tokenOwner = await meekicksContract.methods.ownerOf(tokenId).call();
    return tokenOwner;
  } catch (err) {
    return null;
  }
}

const filterOutClaimedKicks = async (tokenIds) => {
  const arr = await Promise.all(tokenIds.map(async (tokenId) => {
    const isKicksClaimed = await checkClaimStatusById(tokenId);
    return isKicksClaimed ? null : tokenId;
  }));

  return arr.filter(tokenId => tokenId);
}

// MEEKICKS
export const mintMeekicks = async (address, tokenIds, provider, chainId) => {
  if (!address || !tokenIds || !tokenIds.length) {
    throw new Error("Address and tokenId cannot be empty")
  }

  if (!provider || !chainId) {
    throw new Error("Please connect a wallet to continue")
  }

  const unclaimedTokenIds = await filterOutClaimedKicks(tokenIds);
  const quantity = unclaimedTokenIds.length;
  const connectedNetworkId = chainId;
  const nonce = await web3.eth.getTransactionCount(address, "latest");

  if (quantity === 0 || unclaimedTokenIds.length === 0) {
    throw new Error("There are no Meekicks to claim!")
  }


  const transactionParameters = {
    to: meekicksContractAddress, // Required except during contract publications.
    from: address, // must match user's active address.
    nonce: nonce.toString(),
    data: meekicksContract.methods.mintKicks(unclaimedTokenIds, quantity).encodeABI(), //make call to NFT smart contract
  };

  try {
    const txHash = await provider.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });

    return {
      success: true,
      status: "View your transaction on Etherscan",
      txLink: `https://${
        connectedNetworkId === 1 ? "" : "rinkeby."
      }etherscan.io/tx/${txHash}`,
      txHash,
      quantity
    };
  } catch (error) {
    return {
      success: false,
      status: "Something went wrong: " + error.message,
      txLink: "",
      quantity
    }
    // return {};
  }
};


/**
 * 
 * @param {String} walletAddress 
 * @returns {Array} of owned and claimable meekicks
 *
 */
export async function getClaimableAndOwnedMeekicks(walletAddress) {
  try {
    const [userMeebits, userMeekicks] = await Promise.all([getUserMeebitNft(walletAddress), getUserMeekicksNft(walletAddress)]);
    const arr = [...userMeebits, ...userMeekicks];
    const dedupedArr = arr.filter((nft, index, self) =>
      index === self.findIndex((t) => t.tokenId === nft.tokenId)
    );
    return dedupedArr;

  } catch (err) {
    return [];
  }
}



// ALCHEMY API CALL
export async function getUserMeebitNft(walletAddress, type = "meekicks") {
  const contractAddress = meebitsContractAddress;

  try {
    const res = await fetch(`${alchemyKey}/getNFTs/?owner=${walletAddress}&contractAddresses[]=${contractAddress}`);
    const body = await res.json();
    return type === "meekicks" ? nftShim(body) : nftPixlMBShim(body);
  } catch (err) {
    return [];
  }
};

export async function getUserMeekicksNft(walletAddress) {
  const contractAddress = meekicksContractAddress;

  try {
    const res = await fetch(`${alchemyKey}/getNFTs/?owner=${walletAddress}&contractAddresses[]=${contractAddress}`);
    const body = await res.json();
    return nftShim(body);
  } catch (err) {
    return [];
  }
};

function nftShim(body) {
  return body.ownedNfts.map(nft => {
    nft.tokenId =  window.web3.utils.hexToNumber(nft.id.tokenId);
    nft.image = `${AWS_MEEKICKS_BASE_URL}/images/${nft.tokenId}.png`
    return nft;
  });
};


function nftPixlMBShim(body) {
  return body.ownedNfts.map(nft => {
    nft.tokenId =  window.web3.utils.hexToNumber(nft.id.tokenId);
    nft.image = `${AWS_PIXELMB_BASE_URL}/front-gif/${nft.tokenId}.gif`;
    return nft;
  });
}

