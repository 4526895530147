import { css, StyleSheet } from "aphrodite";
import React, { useState } from "react";
import LightBlueRect from "../../assets/Light_Blue_Rect.png";

function MailingList({
  className,
  formClassName,
  titleClassName,
  topMailFormGroupClassName,
}) {
  return (
    <div className={css(styles.section, className)}>
      <div className={css(titleClassName)}>Join the mailing list</div>
      <form
        class={css(styles.mailInputContainer, formClassName)}
        action="https://www.getrevue.co/profile/meebitsDAO/add_subscriber"
        method="post"
        id="revue-form"
        name="revue-form"
        target="_blank"
        rel="noreferrer"
      >
        <div class={css(styles.mailFormGroup, topMailFormGroupClassName)}>
          <input
            className={css(styles.mailingInput)}
            placeholder="Email Address"
            type="email"
            name="member[email]"
            id="member_email"
          ></input>
        </div>
        <div class={css(styles.mailFormGroup)}>
          <input
            className={css(styles.mailingSubmit)}
            type="submit"
            value="Subscribe"
            name="member[subscribe]"
            id="member_submit"
          ></input>
        </div>
      </form>
    </div>
  );
}

const styles = StyleSheet.create({
  section: {
    display: "flex",
    // marginTop: 96,
    margin: "0 auto auto auto",
    // width: "20em",
    width: "100%",
    height: "8em",
    fontSize: 32,
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px 48px",
    background: `url(${LightBlueRect})`,
    fontStyle: "italic",
    fontWeight: 800,
    lineHeight: "29px",
    border: 0,
    color: "#fff",
    textTransform: "uppercase",
    textDecoration: "none",
    boxSizing: "border-box",
  },
  mailInputContainer: {
    width: "100%",
    maxWidth: 400,
  },
  mailFormGroup: {
    width: "100%",
    marginTop: "12px",
  },
  mailingInput: {
    border: 0,
    textDecoration: "none",
    boxSizing: "border-box",
    width: "100%",
    height: "2em",
    fontSize: 20,
    textTransform: "uppercase",
    fontStyle: "italic",
    fontWeight: 800,
    lineHeight: "29px",
    padding: 16,
  },
  mailingSubmit: {
    border: 0,
    textDecoration: "none",
    boxSizing: "border-box",
    height: "1.5em",
    fontSize: 24,
    textTransform: "uppercase",
    fontStyle: "italic",
    fontWeight: 800,
    lineHeight: "29px",
    cursor: "pointer",
    background: "rgb(0, 56, 109)",
    color: "#fff",
    width: "100%",
    height: 52,
  },
});

export default MailingList;
