import { css, StyleSheet } from "aphrodite";
import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
} from "../../utils/interact.js";
import ActionButton from "../ActionButton";
import WalletConnectModal from "../Web3/WalletConnectModal";


const Wallet = (props) => {
  //State variables
  const {
    setWalletStatus,
    walletConnected,
    setWalletConnected,
    walletAddress,
    setWallet,
    walletFlow
  } = props;
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);


  const setConnectWallet = async () => {
    setIsConnectModalOpen(true);
  };

  let walletText;

  if (walletFlow == "claim") {
    walletText = "Connect Wallet";
  } else {
    walletText =
    walletAddress.length > 0 ? (
      String(walletAddress).substring(0, 6) +
      "..." +
      String(walletAddress).substring(38)
    ) : (
      <span>Mint</span>
    )
  }

  return (
    <div className="Wallet">
      <WalletConnectModal 
        isOpen={isConnectModalOpen}
        onClose={() => setIsConnectModalOpen(false)}
      />
      <ActionButton
        text={
          walletText
        }
        style={props.styles ? props.styles : styles.actionButton}
        onClick={setConnectWallet}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  actionButton: {
    fontSize: 18,
  },
});

export default Wallet;
