import { css, StyleSheet } from "aphrodite";
import DarkBlueRect from "../assets/Dark_Blue_Rect.png";

function TitleHeader({ title, alignRight, style }) {
  return (
    <div
      className={css(
        styles.titleHeaderContainer,
        alignRight && styles.alignRight,
        style
      )}
    >
      {title}
    </div>
  );
}
const styles = StyleSheet.create({
  titleHeaderContainer: {
    background: `url(${DarkBlueRect})`,
    width: 468,
    padding: "30px 40px 15px",
    fontStyle: "italic",
    fontWeight: 800,
    fontSize: 60,
    lineHeight: "73px",
    color: "#fff",
    textTransform: "uppercase",
    boxSizing: "border-box",
    marginBottom: 48,
    zIndex: 3,

    "@media only screen and (max-width: 767px)": {
      width: 250,
      fontSize: 40,
      padding: "20px 30px 7px",
    },
  },
  alignRight: {
    textAlign: "right",
  },
});

export default TitleHeader;
