import { css, StyleSheet } from "aphrodite";
import { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

// Components
import ActionButton from "../components/ActionButton";
import WalletTokenHeader from "../components/Claim/WalletTokenHeader";
import Delegate from "../components/Claim/Delegate";
import Wallet from "../components/Minting/Wallet.js";
import SpinLoader from "../components/SpinLoader";
import HyperLink from "../components/HyperLink";

// Assets & Utils
import DarkBlueRect from "../assets/Dark_Blue_Rect.png";
import MetaMaskLogo from "../assets/metamask-fox.svg";
import { Colors } from "../utils/colors.js";
import {
  getEligibleMonthlyMint,
  getDelegatedTokenCount,
  getUserCurrentDelegate,
  getMembershipTokenCount,
  getMBBTMembershipTokenCount
} from "../utils/api";
import {
  LS_MINTING_TRANSACTION_KEY,
  LS_DELEGATION_TRANSACTION_KEY,
} from "../utils/constants";
import { addToMetaMask } from "../utils/governanceTokenInteract";

export default function ClaimPage(props) {
  const {
    walletStatus,
    setWalletStatus,
    networkErrorMessage,
    walletConnected,
    setWalletConnected,
    walletAddress,
    setWallet,
  } = props;

  // STATE
  const [isFetching, setIsFetching] = useState(false);
  const [eligibleMonthlyMint, setEligibleMonthlyMint] = useState(false);
  const [currentDelegate, setCurrentDelegate] = useState(null);
  const [delegatedTokenCount, setDelegatedTokenCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);

  const [existingTransaction, setExisitingTransactions] = useState({
    minting: null,
    delegation: null,
  });

  useEffect(() => {
    const isWalletValid = walletConnected && walletAddress;
    if (!isWalletValid) return;

    async function getUserInitializationData() {
      setIsFetching(true);
      try {
        checkForExistingTransactionsLocalStorage();
        const [
          isEligibleForMonthlyMint,
          currentDelegatedTokenCount,
          currentDelegate,
          generalMembershipTokenCount,
          mbbtGeneralMembershipTokenCount
        ] = await Promise.all([
          getEligibleMonthlyMint(walletAddress),
          getDelegatedTokenCount(walletAddress),
          getUserCurrentDelegate(walletAddress),
          getMembershipTokenCount(walletAddress),
          getMBBTMembershipTokenCount(walletAddress),
        ]);
        setEligibleMonthlyMint(
          (generalMembershipTokenCount > 0 || mbbtGeneralMembershipTokenCount > 0) && isEligibleForMonthlyMint
        );
        setDelegatedTokenCount(parseInt(currentDelegatedTokenCount, 10));
        setCurrentDelegate(currentDelegate);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
        handleError(error, setErrorMessage);
        setIsFetching(false);
      }
    }

    async function checkForExistingTransactionsLocalStorage() {
      if (!window) return;
      const result = {};
      const mintingTransaction = localStorage.getItem(
        LS_MINTING_TRANSACTION_KEY(walletAddress)
      );
      const delegationTransaction = localStorage.getItem(
        LS_DELEGATION_TRANSACTION_KEY(walletAddress)
      );

      if (mintingTransaction) {
        result.minting = mintingTransaction;
      }

      if (delegationTransaction) {
        result.delegation = delegationTransaction;
      }

      setExisitingTransactions(result);
    }

    getUserInitializationData();
  }, [walletConnected, walletAddress]);

  // USER STATES
  const isWalletValid = walletConnected && walletAddress;
  const isFirstTimeClaiming =
    eligibleMonthlyMint && !currentDelegate && delegatedTokenCount === 0;
  // const isFirstTimeClaiming = eligibleMonthlyMint && delegatedTokenCount == 0;
  const isReturningUser = delegatedTokenCount > 0;
  const hasNoToken = !eligibleMonthlyMint && !currentDelegate;

  const claimContainer = determineScreenByUserState();

  function handleError(error, onError) {
    if (typeof error == "object") {
      const errorString = error.toString(); // converts Error object to string
      return setErrorMessage("Something went wrong. Please try again later!");
    }
    onError && onError(error);
  }

  function formatTokenDelegateHeader() {
    const count =
      delegatedTokenCount == 0 || !delegatedTokenCount
        ? "No"
        : delegatedTokenCount;
    const isPlural = !delegatedTokenCount == 1;

    return `${count} Token${isPlural ? "s" : ""} Delegated To`;
  }

  function determineScreenByUserState() {
    if (!isWalletValid) {
      return (
        <>
          <img src={MetaMaskLogo} className={css(styles.metaMaskImg)} />
          <div className={css(styles.title)}>
            {"Connect your metamask \n to start"}
          </div>
          <Wallet
            setWalletStatus={setWalletStatus}
            walletConnected={walletConnected}
            setWalletConnected={setWalletConnected}
            walletAddress={walletAddress}
            setWallet={setWallet}
            walletFlow="claim"
            styles={styles.claimButton}
          />
          {walletStatus.includes(
            "Request of type 'wallet_requestPermissions' already pending"
          ) ? (
            <div className={css(styles.warning)}>
              Metamask popup is already open, please connect via the popup, or
              close and reattempt.
            </div>
          ) : null}
          {walletStatus ===
          "You must install Metamask, a virtual Ethereum wallet, in your browser." ? (
            <div className={css(styles.warning)}>
              You need to install metamask to start.
            </div>
          ) : null}
        </>
      );
    } else if (networkErrorMessage) {
      return (
        <>
          <div className={css(styles.title)}>Network Error</div>
          <div className={css(styles.subtext)}>{networkErrorMessage}</div>
        </>
      );
    } else if (isFetching) {
      return (
        <>
          <div className={css(styles.title)}>Loading Wallet</div>
          <div className={css(styles.loader)}>
            <SpinLoader isLoading={true} />
          </div>
        </>
      );
    } else if (isFirstTimeClaiming) {
      return (
        <>
          <div className={css(styles.title)}>Claim your token</div>
          <div className={css(styles.subtext)}>
            You are eligible for an airdrop! View your tokens below to start the
            claim process:
          </div>
          <div className={css(styles.tokensContainer)}>
            <div className={css(styles.tokensSubtitle)}>You will receive</div>
            <div className={css(styles.tokensCount)}>1 MVOX</div>
          </div>
          <div className={css(styles.subtext)}>
            You have received these tokens for general membership of the
            MeebitsDAO. You are able to claim up to (1) mVOX token each month.
            Unclaimed tokens do not rollover into subsequent months.
          </div>
          <ActionButton
            text="Start Claim Process"
            to="delegates"
            state={{ isClaimingTokenOnly: false }} //decoupling from setting delegate\
            style={styles.claimButton}
          />
        </>
      );
    }
    // else if (hasNoToken) {
    //   return (
    //     <>
    //       <div className={css(styles.title)}>No Tokens</div>
    //       <div className={css(styles.subtext)}>
    //         Tokens are received for being a general member of the MeebitsDAO.
    //         You are able to claim up to (1) mVOX token each month. Unclaimed
    //         tokens do not rollover into subsequent months.
    //       </div>
    //       <ActionButton text="Go Back Home" to="/" style={styles.claimButton} />
    //     </>
    //   );
    // // } else if (isReturningUser) {
    // }
    else {
      return (
        <>
          <div className={css(styles.title)}>
            {eligibleMonthlyMint ? "Claim your token" : "No Tokens To Claim"}
          </div>
          <div className={css(styles.subtext)}>
            {eligibleMonthlyMint
              ? "You are eligible for an airdrop! View your tokens below to start the claim process"
              : null}
          </div>
          {eligibleMonthlyMint && (
            <div className={css(styles.tokensContainer)}>
              <div className={css(styles.tokensSubtitle)}>You will receive</div>
              <div className={css(styles.tokensCount)}>1 MVOX</div>
            </div>
          )}
          {currentDelegate ? (
            <Fragment>
              <div className={css(styles.tokensContainer)}>
                <div className={css(styles.tokensSubtitle)}>
                  {formatTokenDelegateHeader()}
                </div>
                <Delegate delegate={currentDelegate} hideTooltip={true} />
                <Link
                  to={"delegates"}
                  className={css(styles.editDelegateButton)}
                  state={{ isClaimingTokenOnly: false }} //decoupling from setting delegate\
                >
                  Edit
                </Link>
              </div>
              {existingTransaction.delegation && (
                <div className={css(styles.existingTransactions)}>
                  <HyperLink href={existingTransaction.delegation}>
                    Not seeing updates or changes?
                  </HyperLink>
                </div>
              )}
            </Fragment>
          ) : (
            <div className={css(styles.tokensContainer)}>
              <Link
                to={"delegates"}
                className={css(styles.selectDelegateButton)}
                state={{ isClaimingTokenOnly: false }} //decoupling from setting delegate\
              >
                Choose a Delegate
              </Link>
            </div>
          )}
          {!eligibleMonthlyMint && (
            <div className={css(styles.subtext)}>
              Tokens are received for being a general member of the MeebitsDAO.
              You are able to claim up to (1) mVOX token each month. Unclaimed
              tokens do not rollover into subsequent months.
            </div>
          )}
          {eligibleMonthlyMint ? (
            <ActionButton
              text="Claim Token"
              to="delegates"
              state={{ isClaimingTokenOnly: true }} //decoupling from setting delegate
              style={styles.claimButton}
            />
          ) : (
            <ActionButton
              text={"Add mVOX to Metamask"}
              style={[styles.claimButton, styles.addToMetamask]}
              onClick={() => {
                addToMetaMask();
              }}
            />
          )}
        </>
      );
    }
  }

  return (
    <>
      <WalletTokenHeader
        {...props}
        eligibleMonthlyMint={eligibleMonthlyMint}
        delegatedTokenCount={delegatedTokenCount}
      />
      <div className={css(styles.claimContainer)}>{claimContainer}</div>
    </>
  );
}

const styles = StyleSheet.create({
  claimContainer: {
    display: "flex",
    position: "relative",
    background: `url(${DarkBlueRect})`,
    border: `1px solid ${Colors.darkNavy()}`,
    width: 600,
    // minHeight: 500,
    padding: "30px 40px 15px",
    color: "#fff",
    textAlign: "justify",
    textAlignLast: "center",
    boxSizing: "border-box",
    lineHeight: 1.3,
    margin: "20px auto auto auto",
    zIndex: 3,
    flexDirection: "column",
    "@media only screen and (max-width: 767px)": {
      width: "90%",
      height: "unset",
      fontSize: 40,
      padding: "20px 30px 7px",
    },
  },
  metaMaskImg: {
    width: 140,
    margin: "0 auto",
  },
  tokenIcon: {
    width: 23,
    height: 23,
    marginLeft: 10,
  },
  title: {
    margin: "0 auto",
    fontSize: 28,
    fontWeight: 800,
    fontStyle: "italic",
    textTransform: "uppercase",
    whiteSpace: "pre-wrap",
  },
  subtext: {
    margin: "15px auto",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 300,
    padding: "0px 48px",
    "@media only screen and (max-width: 767px)": {
      padding: 0,
    },
  },
  tokensContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: 350,
    background: "rgba(0, 56, 109, 1)",
    boxSizing: "border-box",
    margin: "10px auto",
    padding: "0 15px",
    border: `1px solid ${Colors.darkNavy()}`,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  tokensSubtitle: {
    margin: "20px auto 18px auto",
    fontSize: 16,
    fontWeight: "lighter",
    fontStyle: "italic",
    textTransform: "uppercase",
  },
  tokensCount: {
    margin: "0 auto 20px auto",
    fontSize: 30,
    fontWeight: 500,
    fontStyle: "italic",
    textTransform: "uppercase",
  },
  claimButton: {
    margin: "28px auto 20px auto",
    width: 320,
    fontSize: 18,
  },
  addToMetamask: {
    width: "unset",
  },
  warning: {
    color: "red",
  },
  editDelegateButton: {
    margin: 0,
    padding: 0,
    fontSize: 15,
    height: 30,
    width: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0, 153, 250, 0.1)",
    fontStyle: "italic",
    textTransform: "uppercase",
    cursor: "pointer",
    position: "absolute",
    right: 10,
    bottom: "calc(100% / 6)",
    color: Colors.white(),
    textDecoration: "none",
    ":hover": {
      color: Colors.uiTeal(),
      textDecoration: "underline",
    },
  },
  selectDelegateButton: {
    margin: "25px 0",
    padding: 0,
    fontSize: 15,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontStyle: "italic",
    textTransform: "uppercase",
    cursor: "pointer",
    color: Colors.white(),
    textDecoration: "none",
    ":hover": {
      color: Colors.uiTeal(),
      textDecoration: "underline",
    },
  },
  row: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    marginTop: 20,
  },
  // for existing transactions found on local storage
  existingTransactions: {
    textAlign: "center",
    margin: "0px auto 5px auto",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: 340,
    fontSize: 12,
    fontWeight: 300,
    "@media only screen and (max-width: 767px)": {
      padding: 0,
    },
  },
});
