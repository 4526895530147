import { css, StyleSheet } from "aphrodite";

import TitleHeader from "../TitleHeader";

import Jim from "../../assets/jim_head.png";
import Mark from "../../assets/mark_head.png";
import Kai from "../../assets/kai_head.png";
import Bart from "../../assets/bart_head.png";
import Alley from "../../assets/alley_head.png";
import Werekitty from "../../assets/werekitty_head.png";
import Carly from "../../assets/carly.webp";
import Bugs from "../../assets/bugs.webp";
import Claude from "../../assets/claude.webp";
import AdvisorNameBackground from "../../assets/advisor_name_background.png";

const advisors = [
  {
    img: Kai,
    link: "https://twitter.com/kaigani",
    label: "Kai",
  },
  {
    img: Mark,
    link: "https://twitter.com/mmayo",
    label: "Mark",
  },
  {
    img: Jim,
    link: "https://twitter.com/j1mmyeth",
    label: "Jimmy",
  },
  {
    img: Werekitty,
    link: "https://twitter.com/Werekitty1",
    label: "Werekitty",
  },
  {
    img: Alley,
    link: "https://twitter.com/AlleyCatNFT",
    label: "Alley Cat",
  },
  {
    img: Bugs,
    link: "https://twitter.com/Cryptocracy11",
    label: "Bugs",
  },
  {
    img: Claude,
    link: "https://twitter.com/Clauds_eth",
    label: "Clauds",
  },
];

function Advisors() {
  return (
    <div className={css(styles.advisorsSection)} id="advisors-section">
      <TitleHeader title={"Advisors"} style={styles.titleHeader} alignRight />
      <div className={css(styles.advisorsContainer)}>
        {advisors.map((advisor, index) => {
          return (
            <a
              key={index}
              href={advisor.link}
              target="_blank"
              rel="noreferrer"
              className={css(styles.link)}
            >
              <div className={css(styles.advisorPicture)}>
                <img src={advisor.img} className={css(styles.img)} />
                <div className={css(styles.advisorName)}>{advisor.label}</div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  advisorsSection: {
    display: "flex",
    flexDirection: "column",
    marginTop: 96,
  },
  advisorsContainer: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: 53,
    paddingRight: 170,
  },
  img: {
    width: "100%",
  },
  advisorName: {
    background: `url(${AdvisorNameBackground})`,
    backgroundRepeat: "repeat-x",
    position: "absolute",
    bottom: 0,
    left: 0,
    textTransform: "uppercase",
    height: 42,
    padding: "6px 20px 7px 15px",
    boxSizing: "border-box",
    fontStyle: "italic",
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "29px",
  },
  advisorPicture: {
    position: "relative",
    height: 224,
    width: 224,
    marginRight: 36,
    marginBottom: 26,
    zIndex: 1,
  },
  noMarginRight: {
    marginRight: 0,
  },
  titleHeader: {
    width: 578,

    "@media only screen and (max-width: 767px)": {
      width: 300,
    },
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
    color: "#fff",
  },
});

export default Advisors;
