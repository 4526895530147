import { css, StyleSheet } from "aphrodite";
import LightBlueRect from "../assets/Light_Blue_Rect.png";

import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import SnackbarContent from "@mui/material/SnackbarContent";

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

function MailingNotification({ isOpen }) {
  const mailingMessage =
    "Congrats on minting! Join our mailing list today to keep up with all of our future news and updates!";

  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(isOpen);
  const [messageInfo, setMessageInfo] = useState(mailingMessage);
  const [transition, setTransition] = useState(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClick = (Transition, message) => () => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    setTransition(() => Transition);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <div>
      <Snackbar
        ContentProps={{
          sx: {
            background: `url(${LightBlueRect})`,
            fontFamily: "Montserrat",
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "middle",
        }}
        key={"snackbar"}
        open={open}
        // autoHideDuration={6000}
        onClose={handleClose}
        TransitionComponent={transition}
        TransitionProps={{ onExited: handleExited }}
        message={mailingMessage}
        action={
          <>
            <form
              action="https://www.getrevue.co/profile/meebitsDAO/add_subscriber"
              method="post"
              id="revue-form"
              name="revue-form"
              target="_blank"
              rel="noreferrer"
              className={css(styles.form)}
            >
              <div className={css(styles.inputLine)}>
                <input
                  className={css(styles.mailingInput)}
                  placeholder="Email Address"
                  type="email"
                  name="member[email]"
                  id="member_email"
                ></input>
                <Button
                  className={css(styles.mailingSubmit)}
                  type="submit"
                  value="Subscribe"
                  name="member[subscribe]"
                  id="member_submit"
                >
                  JOIN
                </Button>
              </div>
            </form>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              className={css(styles.close)}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </>
        }
      />
    </div>
  );
}

const styles = StyleSheet.create({
  form: {
    padding: 8,
    marginLeft: -16,
  },
  mailingInput: {
    border: 0,
    textDecoration: "none",
    boxSizing: "border-box",
    width: "20em",
    height: "2em",
    fontSize: "0.875rem",
    // fontWeight: 700,
    lineHeight: "29px",
    padding: 16,

    "@media only screen and (max-width: 767px)": {
      width: 250,
    },
  },
  mailingSubmit: {
    marginLeft: 4,
    border: 0,
    textDecoration: "none",
    boxSizing: "border-box",
    fontSize: "0.875rem",
    textTransform: "uppercase",
    fontStyle: "italic",
    fontWeight: 800,
    lineHeight: "29px",
    cursor: "pointer",
    background: "rgb(0, 56, 109)",
    color: "#fff",
    height: "2.3em",
    borderRadius: 0,
    "@media only screen and (max-width: 767px)": {
      // margin: "0 auto",
    },
  },
  close: {
    "@media only screen and (max-width: 767px)": {
      position: "absolute",
      top: 0,
      right: 0,
    },
  },
  inputLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default MailingNotification;
