import { css, StyleSheet } from "aphrodite";
import { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import SpinLoader from "../SpinLoader";

// Components
// import ActionButton from "../ActionButton";
// import Tooltip from "../Tooltip";
// import WalletTokenHeader from "../Claim/WalletTokenHeader";
// import Delegate from "../Claim/Delegate";
// import Wallet from "../Minting/Wallet.js";
// import SpinLoader from "../SpinLoader";
// import HyperLink from "../HyperLink";
import PixelMBCard from "./PixelMBCard";


import { Colors } from "../../utils/colors.js";


const PixelMBGrid = ({ 
  walletAddress,
  isLoading,
  userMeebits,
}) => {

  const [currPage, setPage] = useState(0);

  function loadMore() {
    //todo: paginate for big sets
  }

  function renderGridItems() {

    if (isLoading) {
      return (
        <div className={css(styles.loader)}>
          <SpinLoader isLoading={true} /> 
        </div>
      )
    } else if (userMeebits.length) {
      return (
        (userMeebits.map((meebit, i) => (
          <PixelMBCard 
            key={`pixel-mb-${i}`} 
            meebit={meebit}
            walletAddress={walletAddress}
          />
        )))
      )
    } else if (!userMeebits.length) {
      return (
        <span className={css(styles.emptyPlaceholder)}>
          {"No Pixel Meebits are currently available to download for this address. Connect your wallet that holds a Meebit."}
        </span>
      )
    }
  }


  if (!walletAddress) return  null;
  

  return (
    <div style={{ position: "relative" }}>
      {/* SHOW INSET SHADOW FOR SCROLL */}
      {userMeebits.length > 4 && (
        <>
          <div className="insetBottom" />
          <div className="insetTop" />
        </>
      )}
      <InfiniteScroll
        pageStart={currPage}
        loadMore={loadMore}
        hasMore={true || false}
        loader={null}
        useWindow={false}
      >
        <div className={css(styles.grid, (isLoading || !userMeebits.length) && styles.flex)}>
          {renderGridItems()}
        </div>
      </InfiniteScroll>
    </div>
  )
};


const styles = StyleSheet.create({
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "16px 0"
  },
  grid: {
    display: "grid",
    gridGap: 16,
    width: "100%",
    padding: "16px 5px 16px",
    boxSizing: "border-box",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    justifyContent: "center",
    boxSizing: "border-box",
    overflow: "auto",
    overflowX: "hidden",
    position: "relative",
    height: "100%",
    maxHeight: "60vh",
    "@media only screen and (max-width: 1000px)": {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    "@media only screen and (max-width: 767px)": {
      gridTemplateColumns: "1fr 1fr",
      maxHeight: "unset"
    },
    "@media only screen and (max-width: 630px)": {
      gridTemplateColumns: "1fr",
      maxHeight: "unset"
    },
    
  },
  loader: {
    paddingTop: 20,
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  emptyPlaceholder: {
    lineHeight: 1.6,
    margin: "20px auto",
    width: "100%",
    fontSize: 18,
    textShadow: `0px 0px 5px ${Colors.darkNavy(0.7)}`,
    transition: "opacity 0.1s linear",
    fontStyle: "normal",
    textTransform: "none",
    background: Colors.lightNavy(),
    padding: "15px 20px",

  }
});

export default PixelMBGrid;