import { ethers } from "ethers";

require("dotenv").config();
//Mumbai
const alchemyKey = process.env.REACT_APP_ALCHEMY_MUMBAI_URL;
const delegationContractABI =
  require("../contracts/MeebitsDAODelegation.json").abi;
const delegationContractAddress =
  process.env.REACT_APP_MEEBITS_DAO_DELEGATION_CONTRACT_ADDRESS;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
window.web3 = web3;

window.delegationContract = new web3.eth.Contract(
  delegationContractABI,
  delegationContractAddress
);

export const getCurrentDelegate = async (delegatorAddress) => {
  window.delegationContract = new web3.eth.Contract(
    delegationContractABI,
    delegationContractAddress
  );
  try {
    const currentDelegate = await window.delegationContract.methods
      .getCurrentDelegate()
      .call({ from: delegatorAddress });
    return currentDelegate;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const setDelegate = async (delegatorAddress, delegateeAddress, provider, chainId) => {
  const connectedNetworkId = chainId;
  window.delegationContract = new web3.eth.Contract(
    delegationContractABI,
    delegationContractAddress
  );
  const isCheckSumAddress =
    web3.utils.checkAddressChecksum(delegatorAddress) &&
    web3.utils.checkAddressChecksum(delegateeAddress);
  if (!isCheckSumAddress) {
    delegatorAddress = web3.utils.toChecksumAddress(delegatorAddress);
    delegateeAddress = web3.utils.toChecksumAddress(delegateeAddress);
  }
  let nonce = await web3.eth.getTransactionCount(delegatorAddress, "latest");

  // const feeData = await (
  //   await fetch("https://gasstation-mainnet.matic.network/v2")
  // ).json();

  // const maxFeePerGas = parseInt(feeData.standard.maxFee * 1e8).toString();
  // const maxPriorityFeePerGas = parseInt(
  //   feeData.standard.maxPriorityFee * 1e8
  // ).toString();

  //Set up your Ethereum transaction
  const transactionParameters = {
    to: delegationContractAddress, // Required except during contract publications.
    from: delegatorAddress, // must match user's active address.
    nonce: nonce.toString(),
    maxPriorityFeePerGas: null, // Recommended maxPriorityFeePerGas
    maxFeePerGas: null, // Recommended maxFeePerGas
    data: window.delegationContract.methods
      .setDelegate(delegateeAddress)
      .encodeABI(), //make call to NFT smart contract
  };

  //Sign the transaction via Metamask
  try {
    const txHash = await provider.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status: "View your transaction on Polygonscan",
      txLink: `https://${
        process.env.REACT_APP_ENVIRONMENT === "production" ? "" : "mumbai."
      }polygonscan.com/tx/${txHash}`,
      txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: error,
      txLink: "",
    };
  }
};

export const clearDelegate = async (delegatorAddress) => {
  const connectedNetworkId = await window.web3.eth.getChainId();
  window.delegationContract = new web3.eth.Contract(
    delegationContractABI,
    delegationContractAddress
  );
  const isCheckSumAddress = web3.utils.checkAddressChecksum(delegatorAddress);
  if (!isCheckSumAddress) {
    delegatorAddress = web3.utils.toChecksumAddress(delegatorAddress);
  }
  let nonce = await web3.eth.getTransactionCount(delegatorAddress, "latest");

  //Set up your Ethereum transaction
  const transactionParameters = {
    to: delegationContractAddress, // Required except during contract publications.
    from: delegatorAddress, // must match user's active address.
    nonce: nonce.toString(),
    data: window.delegationContract.methods.clearDelegate().encodeABI(), //make call to NFT smart contract
  };

  //Sign the transaction via Metamask
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status: "View your transaction on Polygonscan",
      txLink: `https://${
        process.env.REACT_APP_ENVIRONMENT === "production" ? "" : "mumbai."
      }polygonscan.com/tx/${txHash}`,
      txHash,
    };
  } catch (error) {
    return error;
  }
};
