
export const Colors = {
  black: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
  white: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
  darkNavy: (opacity = 1) => `rgba(1, 31, 64, ${opacity})`,
  uiRed: (opacity = 1) => `rgba(255, 93, 105, ${opacity})`,
  lightNavy: (opacity = 1) => `rgba(0, 56, 109, ${opacity})`,
  uiTeal: (opacity = 1) => `rgba(0, 153, 250, ${opacity})`,
  uiNeon: (opacity = 1) => `rgba(2, 248, 136, ${opacity})`,
  pink: "#f240f1"
}
