import { css, StyleSheet } from "aphrodite";
import ReactTooltip from "react-tooltip";
import DarkBlueRect from "../../assets/Dark_Blue_Rect.png";
import { Colors } from "../../utils/colors";
import { useEffect, useState } from "react";

// Components
import TooltipContent from "../Tooltip";
import TokenSummaryRow from "./TokenSummaryRow";


const UNCLAIMED_TOKEN_TOOLTIP = "Tokens are received for being a general member of the MeebitsDAO. You are able to claim up to (1) mVOX token each month. Unclaimed tokens do not rollover into subsequent months.";
const DELEGATED_TOKEN_TOOLTIP = "These are the monthly tokens that you've claimed and delegated."

export default function WalletTokenHeader(props) {
  const {
    walletConnected,
    walletAddress,
    eligibleMonthlyMint,
    delegatedTokenCount
  } = props;
  
  const [didCopy, setDidCopy] = useState(false);

  const isWalletValid = (walletConnected && walletAddress);

  const walletPrefix = isWalletValid ? walletAddress.slice(0, -4) : "";
  const walletSuffix = isWalletValid ? walletAddress.slice(-4) : "";

  const copyAndPastePrompt = () => {
    setDidCopy(true);
    navigator.clipboard.writeText(walletAddress);
    setTimeout(() => setDidCopy(false), 2500);
  };

  return (
    <div className={css(styles.headerContainer, !isWalletValid && styles.hidden )}>
        <div 
          className={css(styles.walletPrefix)}
          onClick={copyAndPastePrompt} 
          data-for={"wallet-address-c&p"}
          data-tip
          data-iscapture="true"
        >
          {walletPrefix}
        </div>
        <div
          className={css(styles.walletSuffix)}
          onClick={copyAndPastePrompt} 
          data-for={"wallet-address-c&p"}
          data-tip
          data-iscapture="true"
        >
          {walletSuffix}
        </div>
        <ReactTooltip
          id={"wallet-address-c&p"}
          getContent={() => didCopy ? "Copied" : "Copy to Clipboard"}
        />
      <div className={css(styles.tokenSummary)}>
        <span
          data-for={"unclaimed-tokens"}
          data-tip
          data-iscapture="true"
        >
          {/* eligibleMonthlyMint cannot exceed 1, therefore if eligible then count is 1 */}
          <TokenSummaryRow label={"Unclaimed Tokens"} count={eligibleMonthlyMint ? 1 : 0} />
          <ReactTooltip
            id={"unclaimed-tokens"}
            getContent={() => <TooltipContent title={"Mintable Tokens"} text={UNCLAIMED_TOKEN_TOOLTIP} />}
          />
        </span>
        <span
          data-for={"delegated-tokens"}
          data-tip
          data-iscapture="true"
        >
          <TokenSummaryRow label={"Delegated Tokens"} count={delegatedTokenCount} />
          <ReactTooltip
            id={"delegated-tokens"}
            getContent={() =>  <TooltipContent title={"Delegated Tokens"} text={DELEGATED_TOKEN_TOOLTIP} />}
          />
        </span>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    background: `url(${DarkBlueRect})`,
    width: 600,
    height: 120,
    padding: "0px 20px",
    color: "#fff",
    border: `1px solid ${Colors.darkNavy()}`,
    boxSizing: "border-box",
    margin: "100px auto auto auto",
    "@media only screen and (max-width: 767px)": {
      width: "90%",
    },
  },
  walletPrefix: {
    color: "#fff",
    fontSize: 20,
    width: "80%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "pointer"
  },
  walletSuffix: {
    paddingRight: 15,
    color: "#fff",
    fontSize: 20,
    cursor: "pointer"
  },
  tokenSummary: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: "20px 0px 20px 20px",
    borderLeft: "1px solid rgba(255, 255, 255, 0.1)"
  },
  copyIcon: {
    marginLeft: 10,
    color: Colors.uiTeal(),
    cursor: "pointer"
  },
  hidden: {
    // we want the dimensions here
    opacity: 0,
    height: 20
  },
})