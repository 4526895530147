import { css, StyleSheet } from "aphrodite";
import Header from "../components/Header";
import About from "../components/Sections/About";
import Join from "../components/Sections/Join";
import Advisors from "../components/Sections/Advisors";
import Sandbox from "../components/Sections/Sandbox";
import FindUs from "../components/Sections/FindUs";
import MailingNotification from "../components/MailingNotification";
import MailingList from "../components/Sections/MailingList";
import {
  getSigningHash
} from "../utils/governanceTokenInteract";

export default function HomePage(props) {
  //Wallet variables
  const {
    walletConnected,
    setWalletConnected,
    openMailingNotification,
    selectedAddress,
    walletAddress,
    setWallet,
    networkId,
    setNetworkId,
    networkErrorMessage,
    setNetworkErrorMessage,
    disabled,
    setDisabled
  } = props;

  return (
    <div>
      <Header />
      <div className={css(styles.inner)}>
        <div className={css(styles.innerInner)}>
          <About />
          <Join
            walletConnected={walletConnected}
            setWalletConnected={setWalletConnected}
            walletAddress={walletAddress}
            setWallet={setWallet}
            openMailingNotification={openMailingNotification}
            selectedAddress={selectedAddress}
            networkId={networkId}
            setNetworkId={setNetworkId}
            networkErrorMessage={networkErrorMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
            disabled={disabled}
            setDisabled={setDisabled}
          />
          <Advisors />
          <Sandbox />
          <FindUs />
        </div>
        {/* <div className={css(styles.width)}>
          <MailingList />
        </div> */}
      </div>
     </div>
   );
};

const styles = StyleSheet.create({
  app: {
    fontFamily: "Montserrat",
    background: "rgba(0, 56, 109, 1)",
    minHeight: "100vh",
    paddingBottom: 63,
    paddingTop: 70,
    color: "#fff",
    overflow: "hidden",
  },
  inner: {
    position: "relative",

    "@media only screen and (min-width: 1024px)": {
      background: "rgba(0, 56, 109, 1)",
      top: -50,
    },
  },
  innerInner: {
    maxWidth: 1720,
    margin: "0 auto",
    paddingTop: 24,
    "@media only screen and (min-width: 1024px)": {
      paddingTop: 50,
    },
  },
  width: {
    width: "50em",
    margin: "0 auto",
    marginTop: 88,

    "@media only screen and (max-width: 767px)": {
      width: "90%",
    },
  },
});
