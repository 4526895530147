import { css, StyleSheet } from "aphrodite";
import { useEffect, useState, Fragment } from "react";
import { useWeb3React } from "@web3-react/core";

// Components
import ActionButton from "../components/ActionButton";
import PixelMBGrid from "../components/PixelMB/PixelMBGrid";
import WalletConnectModal from "../components/Web3/WalletConnectModal";
import HyperLink from "../components/HyperLink";

// Assets & Utils
import DarkBlueRect from "../assets/Dark_Blue_Rect.png";
import MBKicksBG from "../assets/bg/mbkicks.png";
import { truncateAddress  } from "../utils/helpers";

import PixelHero from "../assets/pixel/pixel-hero-front.gif";
import PixelHeroLeft from "../assets/pixel/pixel-hero-left.gif";
import PixelHeroRight from "../assets/pixel/pixel-hero-right.gif";
import PixelHeroBack from "../assets/pixel/pixel-hero-back.gif";

import { Colors } from "../utils/colors.js";
import { getUserMeebitNft } from "../utils/meekicksInteract";

const GIF_CYCLE_TIME = 3500;

const MeebitsKicksPage = ({
  walletAddress,
  walletConnected,
  userEns,
  setUserEns,
  setWallet,
}) => {
  //WEB3
  const { library, chainId } = useWeb3React();

  //HERO IMAGE
  const [cycle, setCycle] = useState(0);
  const [shadowColors, setShadowColors] = useState([
    Colors.black(0.3),
    Colors.black(0.3),
  ]);
  const [showDescription, setShowDescription] = useState(true);
  const [currGif, setCurrGif] = useState(PixelHero);
  const [currDirection, setCurrDirection] = useState("front");

  // MEEBITS
  const [isFetching, setIsFetching] = useState(false);
  const [pageKey, setPageKey] = useState(null);
  const [userMeebits, setUserMeebits] = useState([]);

  // WALLET CONNECT MODAL
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);

  const isEligibleForClaim = userMeebits.length && walletAddress;

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown)
  }, []);

  useEffect(() => {
    if (!walletAddress) return;
    checkForUserMeebits(walletAddress);
  }, [walletAddress, walletConnected]);

  useEffect(() => {
    const shadowColorInterval = setInterval(() => {
      setCycle((n) => {
        const _cycle = n + 1;
        setShadowColors(determineColor(_cycle));
        return _cycle;
      });

    }, GIF_CYCLE_TIME);



    return () => {
      clearInterval(shadowColorInterval);
    };
  }, []);

  async function checkForUserMeebits(walletAddress) {
    setIsFetching(true);
    const userMeebits = await getUserMeebitNft(walletAddress, "pixelMB");
    setUserMeebits(userMeebits);
    setIsFetching(false);
  }


  function determineColor(cycle) {
    switch (cycle % 6) {
      case 0:
        return [Colors.white(), Colors.white()];
      case 1:
        return [Colors.uiTeal(), Colors.white()];
      case 2:
        return [Colors.uiTeal(), Colors.uiTeal()];
      case 3:
        return [Colors.pink, Colors.uiTeal()];
      case 4:
        return [Colors.pink, Colors.pink];
      case 5:
        return [Colors.white(), Colors.pink];
      default:
        return [Colors.white(), Colors.white()];
    }
  }

  function setGif(direction)  {
    setCurrDirection(direction);
    switch (direction) {
      case "front":
        setCurrGif(PixelHero);
        break;
      case "right": 
        setCurrGif(PixelHeroRight);
        break;
      case "back":
        setCurrGif(PixelHeroBack);
        break;
      case "left":
        setCurrGif(PixelHeroLeft);
        break;
      default:
        setCurrGif(PixelHero);
        break;
    }
  };

  function onKeyDown(e) {
    var { keyCode } = e;
    switch (keyCode) {
      case 68: //d
      case 39: // right arrow
        setGif("right")
        break;
      case 83: //s
      case 40: // down arrow
        setGif("front")
        break;
      case 65: //a
      case 37: // left arrow
        setGif("left")
        break;
      case 87: //w
      case 38: //up arrow
        setGif("back")
        break;
    }

  }
  

  function renderMessage() {
    let user = "";
    if (userEns) {
      user = userEns;
    } else if (walletAddress) {
      user = truncateAddress(walletAddress);
    }

    return (
      <>
        <div className={css(styles.metadata)}>
          <span className={css(styles.label)}>{"Connected:"}</span>
          <span className={css(styles.value, styles.userWallet)}>{user}</span>
        </div>
        <div className={css(styles.metadata)}>
          <span className={css(styles.label)}>{"Pixel Meebits Available:"}</span>
          <span
            className={css(styles.value, styles.userWallet)}
          >{`${userMeebits.length}`}</span>
        </div>
      </>
    );
  }

  async function handleClick(e) {
    e && e.stopPropagation();
    if (!walletAddress) {
      setIsConnectModalOpen(true);
    } else if (isEligibleForClaim) {

      const downloadATag = document.createElement("a");
      downloadATag.style.display = 'none';
      document.body.appendChild( downloadATag );


      for (let i = 0; i < userMeebits.length; i++) {
        const userMeebit = userMeebits[i];
        const meebitId = userMeebit.tokenId;
        const href = `https://meebitsdao-pixel-meebits.s3.us-west-2.amazonaws.com/zip/${meebitId}.zip`;
        const name = `${meebitId}.zip`
        downloadATag.setAttribute( 'href', href );
        downloadATag.setAttribute( 'download', name );
        downloadATag.click();
        await timeout(400);
      };

      document.body.removeChild( downloadATag );
    }
  };

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

  return (
    <Fragment>
      <WalletConnectModal isOpen={isConnectModalOpen} onClose={() => setIsConnectModalOpen(false)} />
      <div className={css(styles.root)}>
        <div
          className={css(
            styles.heroContainer,
            showDescription && styles.openHeroContainer,
            isEligibleForClaim && styles.padding
          )}
        >
          {!isEligibleForClaim && (
            <div className={css(styles.heroImageContainer)}>
              <img
                src={currGif}
                className={css(styles.heroImage)}
                draggable={false}
              />
              <div className={css(styles.directionContainer)}>
                <i 
                  className={css(styles.arrowIcon, currDirection === "left" && styles.activeArrowIcon) + " fas fa-chevron-left"} 
                  onClick={() => setGif("left")} 
                />
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "0 10px",}}>
                  <i
                   className={css(styles.arrowIcon, currDirection === "back" && styles.activeArrowIcon) + " fas fa-chevron-up"} 
                   style={{ marginBottom: 12 }} 
                   onClick={() => setGif("back")} 
                  />
                  <i 
                    className={css(styles.arrowIcon, currDirection === "front" && styles.activeArrowIcon) + " fas fa-chevron-down"} 
                    style={{ marginTop: 12 }} 
                    onClick={() => setGif("front")} 
                  />
                </div>
                <i 
                  className={css(styles.arrowIcon, currDirection === "right" && styles.activeArrowIcon) + " fas fa-chevron-right"} 
                  onClick={() => setGif("right")} 
                />
              </div>
            </div>
          )}
          {/* <img
            className={css(styles.logo, isEligibleForClaim && styles.topRight)}
            src={PixelMBLogo}
            alt={"Meekicks Logo"}
            draggable={false}
          /> */}
          <div
            className={css(
              styles.heroContent,
              isEligibleForClaim && styles.fullWidth
            )}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className={css(
                  styles.header,
                  isEligibleForClaim && styles.paddingLeft
                )}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      marginTop: -20,
                    }}
                  >
                    <div>
                      <div className={css(styles.subtitle)}>
                        {!isEligibleForClaim ? "Download Your" : "Download Your"}
                      </div>
                      {"Pixel Meebits"}
                    </div>
                    {isEligibleForClaim ? (
                      <img
                        src={PixelHero}
                        className={css(styles.heroImage2)}
                        draggable={false}
                        style={{
                          filter: `drop-shadow(0 2px 5px ${shadowColors[0]}) drop-shadow(0 2px 5px ${shadowColors[1]})`,
                        }}
                      />
                    ) : null}
                  </div>
                </div>
                <div
                  className={css(
                    styles.body,
                    (walletAddress || !showDescription) && styles.hidden
                  )}
                >
                  {"Pixel Meebits are a free download for Meebits holders. The ZIP file contains your Meebit sprite sheet, PNGs, and walking GIFs in all four directions."}
                  <br/>
                  <br/>
                  {"Play your Pixel Meebit in "}
                  <HyperLink
                    href={"https://webb.game/"}
                  >
                    <span style={{ textTransform: "uppercase" }}>
                      {"Worldwide Webb."}
                    </span>
                  </ HyperLink>
                </div>
                <div className={css(styles.row)}>
                  <div
                    className={css(
                      styles.column,
                      !showDescription && styles.hidden
                    )}
                  >
                    {walletAddress ? (
                      renderMessage()
                    ) : (
                      <Fragment>
                        <div className={css(styles.metadata)}>
                          <span className={css(styles.label)}>
                            {"Price:"}
                          </span>
                          <span className={css(styles.value)}>
                            {"Free."}
                          </span>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  {isEligibleForClaim ? (
                    <ActionButton
                      text={
                        !walletAddress ? "Connect Wallet" : "Download All"
                      }
                      style={
                        !walletAddress
                          ? styles.connectButton
                          : !isEligibleForClaim
                          ? styles.disabledButton
                          : styles.connectButton
                      }
                      onClick={handleClick}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div 
              className={css(styles.divider)} 
            />
            <PixelMBGrid
              userMeebits={userMeebits}
              isLoading={isFetching}
              walletAddress={walletAddress}
            />
            {isEligibleForClaim ? (
              <div className={css(styles.bottomDivider)} />
            ) : null}
            <div
              className={css(
                styles.footer,
                isEligibleForClaim && styles.paddingLeft
              )}
            >
              {!isEligibleForClaim && (
                <ActionButton
                  text={
                    !walletAddress ? "Connect Wallet" : "Download All"
                  }
                  style={
                    !walletAddress
                      ? styles.connectButton
                      : !isEligibleForClaim
                      ? styles.disabledButton
                      : styles.connectButton
                  }
                  onClick={handleClick}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const styles = StyleSheet.create({
  root: {
    position: "relative",
    width: "100vw",
    backgroundImage: `url(${MBKicksBG})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxSizing: "border-box",
    padding: 50,
    paddingTop: 30,
    minHeight: "100vh",
    "@media only screen and (max-width: 767px)": {
      padding: "50px 0px",
    },
  },
  heroContainer: {
    position: "relative",
    width: "100%",
    maxWidth: 1200,
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${Colors.darkNavy()}`,
    borderRadius: 5,
    padding: "60px 40px 60px 20px",
    boxSizing: "border-box",
    boxShadow: `0px 0px 50px ${Colors.uiTeal(0.5)}`,
    background: `url(${DarkBlueRect})`,
    "@media only screen and (max-width: 1000px)": {
      padding: "10px 40px 60px 40px",
      flexDirection: "column",
    },
  },
  padding: {
    paddingBottom: 30,
  },
  openHeroContainer: {
    alignItems: "flex-start",
  },
  kicksChecker: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 150,
    width: "100%",
    padding: "30px 40px",
    maxWidth: 1200,
    margin: "30px auto 0 auto",
    background: `url(${DarkBlueRect})`,
    border: `1px solid ${Colors.darkNavy()}`,
    boxShadow: `0px 0px 50px ${Colors.uiTeal(0.3)}`,
    fontSize: 18,
    borderRadius: 5,
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      height: "unset",
      boxSizing: "border-box",
    },
  },
  logo: {
    position: "absolute",
    bottom: 30,
    left: 30,
    height: 50,
    "@media only screen and (max-width: 1000px)": {
      height: 40,
      top: 10,
    },
    "@media only screen and (max-width: 767px)": {
      height: 30,
    },
  },
  topRight: {
    top: 10,
    right: 10,
    left: "unset",
    height: 40,
  },
  header: {
    fontFamily: "'Montserrat'",
    fontSize: 60,
    textTransform: "uppercase",
    fontStyle: "italic",
    filter: `drop-shadow(0px 0px 5px ${Colors.darkNavy()})`,
    "@media only screen and (max-width: 767px)": {
      fontSize: 40,
    },
  },
  footer: {
    // paddingTop: 30,
  },
  paddingLeft: {
    paddingLeft: 10,
    // paddingRight: 30,
    "@media only screen and (max-width: 1000px)": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  subtitle: {
    fontSize: 32,
    "@media only screen and (max-width: 767px)": {
      fontSize: 20,
    },
  },
  body: {
    lineHeight: 1.6,
    margin: "20px 0",
    fontSize: 18,
    textShadow: `0px 0px 5px ${Colors.darkNavy(0.7)}`,
    transition: "opacity 0.1s linear",
    fontStyle: "normal",
    textTransform: "none",
    background: Colors.lightNavy(),
    padding: "15px 20px",
  },
  hidden: {
    height: 0,
    visibility: "hidden",
    opacity: 0,
    padding: 0,
    margin: 0,
  },
  chevron: {
    fontSize: 25,
    color: Colors.white(),
    transition: "all 0.2s linear",
    transform: "rotate(-180deg)",
  },
  chevronDown: {
    transform: "rotate(0deg)",
  },
  heroImageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    // paddingBottom: 30,
    boxSizing: "border-box",
    position: "relative",
    "@media only screen and (max-width: 1000px)": {
      paddingRight: 0,
      width: "100%",
      paddingBottom: 60,
      flexDirection: "row"
    },
  },
  heroImage: {
    width: "100%",
    maxWidth: 350,
    transition: "all ease-in-out 0.4s",
    filter: `drop-shadow(0 4px 6px ${Colors.black(0.3)}) drop-shadow(0 4px 6px ${Colors.black(0.3)})`,
    "@media only screen and (max-width: 1000px)": {
      maxWidth: 250,
    },
  },
  heroImage2: {
    maxWidth: 120,
    padding: 0,
    transition: "all ease-in-out 0.4s",
    "@media only screen and (max-width: 415px)": {
      maxWidth: 80,
      display: "none",
    },
  },
  heroContent: {
    width: "70%",
    boxSizing: "border-box",
    "@media only screen and (max-width: 1000px)": {
      width: "100%",
    },
  },
  directionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  arrowIcon: {
    fontSize: 30,
    color: Colors.uiTeal(),
    cursor: "pointer",
    filter: `drop-shadow(0 1px 1px ${Colors.black(0.3)}) drop-shadow(0 1px 1px ${Colors.black(0.3)})`,
    ":hover": {
      color: Colors.pink
    }
  },
  activeArrowIcon: {
    filter: `drop-shadow(0 1px 1px ${Colors.white(0.3)}) drop-shadow(0 1px 1px ${Colors.white(0.3)})`,
    color: Colors.pink
  },
  fullWidth: {
    width: "100%",
  },
  userWallet: {
    fontStyle: "italic",
    textTransform: "uppercase",
  },
  toolTipIcon: {
    height: 25,
    width: 25,
    marginLeft: 8,
  },
  bg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  metadata: {
    display: "flex",
    alignItems: "center",
    lineHeight: 2,
    fontSize: 20,
    cursor: "default",
    filter: `drop-shadow(0px 0px 5px ${Colors.darkNavy()})`,
    "@media only screen and (max-width: 767px)": {
      fontSize: 16,
      display: "inline-block",
      alignItems: "unset",
    },
  },
  label: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontStyle: "italic",
  },
  value: {
    marginLeft: 10,
    color: Colors.uiTeal(),
    fontStyle: "italic",
  },
  metamaskLogo: {
    height: 24,
  },
  connectButton: {
    marginTop: 30,
    width: "unset",
    border: "none",
    boxShadow: `0px 0px 10px ${Colors.darkNavy()}`,
    outline: "none",
    heighlight: "none",
  },
  tos: {
    paddingTop: 20,
    color: Colors.white(),
    textTransform: "uppercase",
    fontStyle: "italic",
    textDecoration: "unset",
    ":hover": {
      textDecoration: "underline",
      color: Colors.uiTeal(),
    },
    fontSize: 20,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      fontSize: 16,
    },
  },
  disabledButton: {
    opacity: 0.6,
    marginTop: 30,
    width: "unset",
    // border: `1px solid ${Colors.uiTeal()}`,
    border: "none",
    boxShadow: `0px 0px 10px ${Colors.darkNavy()}`,
    outline: "none",
    heighlight: "none",
    cursor: "not-allowed",
    ":hover": {
      opacity: 0.6,
      pointerEvents: "none",
    },
  },

  walletMessage: {
    paddingTop: 20,
    lineHeight: 1.6,
    whiteSpace: "pre-line",
    fontSize: 18,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    fontStyle: "italic",
    textTransform: "uppercase",
  },
  claimMessage: {
    paddingTop: 20,
    lineHeight: 1.6,
    whiteSpace: "pre-line",
    fontSize: 18,
  },
  input: {
    border: 2,
    textDecoration: "none",
    boxSizing: "border-box",
    width: "15em",
    // fontWeight: 700,
    lineHeight: "29px",
    padding: 16,
    fontSize: 18,
    fontFamily: "'Montserrat'",
    // font-weight: 400;
    lineHeight: 1.5,
    height: 59,
    // borderRadius: 8,
    // padding: "12px 32px 14px",
    border: `3px solid ${Colors.uiTeal()}`,
    boxShadow: `0px 0px 10px ${Colors.darkNavy()}`,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  inputLabel: {
    fontSize: 20,
    marginBottom: 20,
    fontStyle: "italic",
    textTransform: "uppercase",
  },
  inputContainer: {
    display: "flex",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
      width: "100%",
    },
  },
  checkButton: {
    marginLeft: 30,
    lineHeight: 1.5,
    height: 59,
    boxShadow: `0px 0px 5px ${Colors.darkNavy()}`,
    "@media only screen and (max-width: 767px)": {
      marginLeft: 0,
      marginTop: 20,
      width: "100%",
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  column: {
    // paddingBottom: 20,
    transition: "opacity 0.1s linear",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    alignItems: "flex-start",
    // borderTop: `2px solid ${Colors.white()}`,
  },
  divider: {
    width: "100%",
    height: 3,
    transition: "all ease-in-out 0.2s",
    background: `linear-gradient(to right, ${Colors.uiTeal()}, ${Colors.white()}, ${
      Colors.pink
    }, ${Colors.uiTeal()})`,
    marginTop: 20,
  },
  bottomDivider: {
    width: "100%",
    height: 3,
    transition: "all ease-in-out 0.2s",
    background: `linear-gradient(to right, ${Colors.uiTeal()}, ${Colors.white()}, ${
      Colors.pink
    }, ${Colors.uiTeal()})`,
    // backgroundSize: "200% 200%",
    // animation: "gradient 2s ease infinite",

    // background: barStyles,
    filter: `drop-shadow(0px 0px 5px ${Colors.white()})`,
  },
});

export default MeebitsKicksPage;
