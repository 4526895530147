import { useState, useEffect, Fragment } from "react";
import { css, StyleSheet } from "aphrodite";
import { useWeb3React } from "@web3-react/core"
import { Colors } from "../utils/colors";
import LightBlueRect from "../assets/Light_Blue_Rect.png";
import WalletConnectModal from "./Web3/WalletConnectModal";

const ConnectButton = ({ 
  walletAddress, 
  walletConnected, 
  userEns, 
  setUserEns, 
  setWallet, 
  onClick 
}) => { 
  const {
    active,
    deactivate,
    account
  } = useWeb3React();

  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (active) {
      // allow user to disconnect wallet
      return deactivate()
    }

    setIsConnectModalOpen(true);
  };

  const renderButtonLabel = () => {

    if (active && isHovered) {
      return "Disconnect"
    }
    return userEns ? userEns : account ? account : "Connect";
  }

  return (
    <Fragment>
      <div 
        className={css(styles.button)} 
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={css(styles.label)}>
          {renderButtonLabel()}
        </div>
      </div>
      <WalletConnectModal isOpen={isConnectModalOpen} onClose={() => setIsConnectModalOpen(false)} />
    </Fragment>
  )
};


const styles = StyleSheet.create({
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `url(${LightBlueRect})`,
    fontStyle: "italic",
    padding: "0px 10px",
    fontWeight: 800,
    fontSize: 24,
    fontSize: 15,
    height: 40,
    width: 140,
    border: `1px solid ${Colors.uiTeal()}`,
    boxShadow: `0px 0px 10px ${Colors.darkNavy()}`,

    lineHeight: "29px",
    border: 0,
    cursor: "pointer",
    color: "#fff",
    textTransform: "uppercase",
    textDecoration: "none",
    boxSizing: "border-box",

    "@media only screen and (max-width: 767px)": {
      width: "100%",
      height: "unset"
    },
    ":hover": {
      opacity: 0.9
    }
  },
  label: {
    textAlign: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
  
  }
});

export default ConnectButton;