import { css, StyleSheet } from "aphrodite";

import CyanBlockAlt from "../assets/cyan_alt.png";

function ContentBlock({ content, blockStyle }) {
  return (
    <div className={css(styles.contentBlock, blockStyle && blockStyle)}>
      {content}
    </div>
  );
}
const styles = StyleSheet.create({
  contentBlock: {
    background: `url(${CyanBlockAlt})`,
    width: 500,
    height: 500,
    padding: "0 45px",
    fontWeight: 600,
    fontSize: 18,
    zIndex: 3,
    // lineHeight: "17px",
    boxSizing: "border-box",
    color: "#fff",

    "@media only screen and (max-width: 1024px)": {
      marginLeft: 0,
      zIndex: 5,
      width: "90%",
      height: "unset",
      padding: 16,
      margin: "0 auto",
    },
  },
});

export default ContentBlock;
