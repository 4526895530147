import { css, StyleSheet } from "aphrodite";
import { Link } from "react-router-dom";

import LightBlueRect from "../assets/Light_Blue_Rect.png";

function ActionButton({ text, href, onClick, style, disabled, to, state = {}, type = "button" }) {
  return (
    <>
      {to ? (
        <Link 
          to={to} 
          className={css(styles.actionButton, style, disabled && [styles.disabled, styles.disabledLink])}
          state={state}
        >
          {text}
        </Link>
      ) : href ? (
        <a
          className={css(styles.actionButton, style)}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {text}
        </a>
      ) : (
        <button
          disabled={disabled}
          className={css(
            styles.actionButton,
            style,
            disabled && styles.disabled
          )}
          onClick={onClick}
          type={type}
        >
          {text}
        </button>
      )}
    </>
  );
}
const styles = StyleSheet.create({
  actionButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 162,
    height: 52,
    padding: "11px 48px",
    background: `url(${LightBlueRect})`,
    fontStyle: "italic",
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "29px",
    border: 0,
    cursor: "pointer",
    color: "#fff",
    textTransform: "uppercase",
    textDecoration: "none",
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      height: "unset"
    },
    ":hover": {
      opacity: 0.9
    }
  },
  disabled: {
    cursor: "not-allowed",
    opacity: 0.5,
    ":hover": {
      opacity: 0.5
    }
  },
  disabledLink: {
    pointerEvents: 'none',
  },
});

export default ActionButton;
